import { configureStore } from "@reduxjs/toolkit";
import globalStateReducer from "./GlobalStateSlice";
import annotationsReducer from "./SliceAnnotations";
import documentsReducer from "./SliceDocuments";
import foldersReducer from "./SliceFolders";
import usersSlice from "./SliceUsers";

export const store = configureStore({
  reducer: {
    globalState: globalStateReducer,
    docs: documentsReducer,
    folders: foldersReducer,
    notes: annotationsReducer,
    users: usersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
