import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultEditor } from "react-simple-wysiwyg";
import { getDocNotes, saveDocNotes } from "../../store/SliceDocuments";
import "./Notepad.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "15px",
    width: "91%"
  },
  notepad: {
    overflow: "auto",
  },
  save_button: {
    padding: "5px",
  },
  save_content: {
    // backgroundColor: "#fafafa",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    padding: "5px",
  },
}));

const Notepad = ({ docId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.docs.docs);
  const docFound = docs.filter((d) => d._id === docId);
  var thisDoc = null;
  if (docFound && docFound.length >= 1) {
    thisDoc = docFound[0];
  }
  const [saveDisabled, setSaveDisabled] = React.useState(true);
  const [html, setHtml] = React.useState(
    "<p>Capture your <strong>rich text notes</strong> here.</p><p>Your notes will also be available in the <u>document report</u> view, which is accessible from the document's tools panel.</p>"
  );

  React.useEffect(() => {
    dispatch(getDocNotes({ id: docId })).then((resp) => {
      if (resp.payload.data && resp.payload.data.doc.annotations.userNotes) {
        setHtml(resp.payload.data.doc.annotations.userNotes);
        setSaveDisabled(true);
      } else setHtml(html);
    });
  }, [thisDoc]);

  React.useEffect(() => {
    dispatch(getDocNotes({ id: docId })).then((resp) => {
      if (resp.payload.data && resp.payload.data.doc.annotations.userNotes) {
        setHtml(resp.payload.data.doc.annotations.userNotes);
        setSaveDisabled(true);
      } else setHtml(html);
    });
  }, []);

  const handleSaveNotes = () => {
    dispatch(saveDocNotes({ id: docId, userNotes: html }));
    setSaveDisabled(true);
  };

  const onChange = (e) => {
    setSaveDisabled(false);
    setHtml(e.target.value);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.notepad}>
        <DefaultEditor value={html} onChange={onChange} />
      </div>
      <div className={classes.save_content}>
        <Button
          className={classes.save_button}
          color="primary"
          disabled={saveDisabled}
          size="small"
          variant="contained"
          onClick={handleSaveNotes}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Notepad;
