import React from "react";
import { Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import DocumentContext from "../../Document/DocumentContext";
import DocumentView from "../DocumentView/DocumentView";

const TabPane = ({ docData }) => {
  const docs = useSelector((state) => state.docs.docs);
  const doc = docs.find((d) => docData.fileId === d._id);

  if (!doc) return <div><p>Unable to load the document.</p><p>Please close this tab and reopen from the Library.</p></div>;
  else
    return (
      <Tab.Pane eventKey={doc._id}>
        <div
          style={{
            height: "90vh",
          }}
        >
          <DocumentView id={doc._id} doc={doc}/>
        </div>
      </Tab.Pane>
    );
};

export default TabPane;
