import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  image_actions: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  quick_view_content_wrapper: {
    display: "flex",
    alignContent: "top",
    justifyContent: "space-between",
  },
  quick_view_content: {
    boxShadow: "3px 2px 10px #e0e0e0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
}));

const ImageZoomInView = ({
  data,
  cancelButtonText,
  callbackCancel,
  openDialog,
}) => {
  const classes = useStyles();
  const [imgZoomWidth, setImgZoomWidth] = useState(50);
  const handleImageZoomIn = () => {
    const newImageWidth = imgZoomWidth < 100 ? imgZoomWidth + 25 : 100;
    setImgZoomWidth(newImageWidth);
  };
  const handleImageZoomOut = () => {
    const newImageWidth = imgZoomWidth > 25 ? imgZoomWidth - 25 : 25;
    setImgZoomWidth(newImageWidth);
  };
  return (
    <div className={classes.wrapper}>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: "2em" }}>Image Annotation</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className={classes.image_actions}>
            <IconButton onClick={handleImageZoomOut} disabled={imgZoomWidth === 25 ? true : false}>
              <ZoomOutIcon />
            </IconButton>
            <IconButton onClick={handleImageZoomIn} disabled={imgZoomWidth === 100 ? true : false}>
              <ZoomInIcon />
            </IconButton>
          </div>
          <div className={classes.quick_view_content_wrapper}>
            <div className={classes.quick_view_content}>
              <img src={data} style={{ width: `${imgZoomWidth}%` }} />
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={callbackCancel} variant="outlined" autoFocus>
            {cancelButtonText || "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageZoomInView;
