import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makePost } from "../../api/api";
import InMemoryJWTManager from "../../api/JWTManager";

import "./LoginSignupForms.css";
import { useDispatch } from "react-redux";
import { sessionExpired } from "../../store/SliceUsers";
import PageHeader from "../../static_site/src/components/PageNavBar";
import REACT_APP_CONFIG from "../../AppConfig";

const LoginForm = ({ onLogin }) => {
  const [loginError, setLoginError] = useState("");
  const [progress, showProgress] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  const handleLoginAsGuest = () => {
    setLoginError("");
    showProgress(true);
    makePost("/user/login_as_guest", {})
      .then((response) => {
        showProgress(false);
        if (response.error) {
          setLoginError(response.error);
        } else {
          if (response.token) {
            dispatch(sessionExpired(false));
            setLoginError("");
            InMemoryJWTManager.setToken(response.token);
            InMemoryJWTManager.setUserId(response.userId);
            InMemoryJWTManager.setExpiry(response.expiry);
            InMemoryJWTManager.setUserName(response.userName);
            InMemoryJWTManager.setUserIsAdmin(response.isAdmin);
            InMemoryJWTManager.setUserIsGuest(response.isGuest);
            onLogin(response);
          } else setLoginError("Sorry, login failed (could not authenticate on the server)");
        }
      })
      .catch((error) => {
        showProgress(false);
        setLoginError("Error logging in.");
      });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };

      setLoginError("");
      showProgress(true);
      makePost("/user/login", data)
        .then((response) => {
          showProgress(false);
          if (response.error) {
            setLoginError(response.error);
          } else {
            if (response.token) {
              dispatch(sessionExpired(false));
              setLoginError("");
              InMemoryJWTManager.setToken(response.token);
              InMemoryJWTManager.setUserId(response.userId);
              InMemoryJWTManager.setExpiry(response.expiry);
              InMemoryJWTManager.setUserName(response.userName);
              InMemoryJWTManager.setUserIsAdmin(response.isAdmin || false);
              InMemoryJWTManager.setUserIsGuest(response.isGuest || false);
              onLogin(response);
            } else setLoginError("Sorry, login failed (could not authenticate on the server)");
          }
        })
        .catch((error) => {
          showProgress(false);
          setLoginError("Error logging in.");
        });
    },
  });

  return (
    <div className="LoginForm">
      <PageHeader title={"Knowledge Garden"} />
      <Grid>
        <Card className="login" style={{ marginTop: 20 }}>
          <CardContent>
            {/* <Grid xs={12} item>
              <Typography
                align="center"
                variant="h4"
                style={{ marginBottom: 15 }}
              >
                Knowledge Garden
              </Typography>
              <div className="login-form-subtitle">
                Gather insights as you seek new knowledge
              </div>
            </Grid> */}
            <div className="warning-computer-usage">
              Best viewed on a computer screen.
            </div>

            <Grid xs={12} item>
              <div className="login-signup-header">
                <div>
                  <Typography variant="h6">Login</Typography>
                </div>
                <div>
                  <Button
                    href="/signup"
                    variant="text"
                    color="primary"
                    style={{ paddingLeft: 0 }}
                  >
                    Create Account
                  </Button>
                </div>
              </div>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Typography align="left" style={{ margin: 5 }}>
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.values.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="left" style={{ margin: 5 }}>
                    Password
                  </Typography>

                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item xs={12} align="center" style={{ marginTop: 10 }}>
                  {progress ? <CircularProgress /> : ""}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 0 }}>
                  {loginError === "" ? (
                    ""
                  ) : (
                    <p className="login-signup-error">{loginError}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Login
                  </Button>
                </Grid>
                {REACT_APP_CONFIG.allow_guest ? (
                  <>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        Or
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        id="login-as-guest"
                        // type="submit"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={handleLoginAsGuest}
                      >
                        Continue as Guest
                      </Button>
                    </Grid>{" "}
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default LoginForm;
