import React, { Component } from "react";
import * as d3 from "d3";
// import d3Tip from "d3-tip";
import "./style/TinyBarChart.css";
require("./utils/assign");

const merge = function (one, two) {
  return Object.assign({}, one, two);
};

class TinyBarChart extends Component {
  static defaultProps = {
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
  };
  // tip = d3Tip()
  //   .attr("class", "d3-tip")
  //   .html(function(d) {
  //     const elData = d3.select(this).data();
  //     if (elData.length === 1) {
  //       return elData[0].cat_label + ": " + elData[0].val;
  //     }
  //     return "";
  //   });

  handleBarClick(element, id) {
    if (this.props.onBarClick) {
      this.props.onBarClick(element, id);
    }
  }

  chart(props, contextColors) {
    if (!props.data || props.data.length <= 0) return;
    const margin = props.margin;
    const width = props.width;
    const height = props.height;

    let svg = d3
      .select(this.svgRef)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    // svg.call(this.tip);

    svg = svg
      .select(".graph")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    svg.selectAll("rect").remove();
    svg.selectAll("text").remove();

    svg
      .selectAll(".bar")
      .data(props.data || [])
      .enter()
      .append("rect")
      .attr("key", (d, i) => i)
      // .attr("data-tip", (d) => "hello")
      // .on("click", this.handleBarClick)
      .attr("class", "bar")
      .attr("x", (d, i) => {
        return this.x(i);
      })
      .attr("y", (d) => {
        return this.y(d.val);
      })
      .attr("height", (d) => this.y(0) - this.y(d.val))
      .attr("width", this.x ? this.x.bandwidth() : 0)
      .attr("fill", (d, i) => {
        return contextColors ? contextColors[d.ctx] : d.fill;
        // return this.props.contextColors
        //   ? this.props.contextColors[d.ctx]
        //   : d.fill;
      });
    // .on("mouseout", this.tip.hide)
    // .on("mouseover", this.tip.show);

    if (props.showYVal) {
      svg
        .selectAll("text")
        .data(props.data || [])
        .enter()
        .append("text")
        .attr("class", "vis-small-barchart")
        .attr("x", (d, i) => this.x(i) + this.x.bandwidth() / 4.0)
        .attr("y", (d) => this.y(d.val) - 3)
        .text((d) => (d.val > 0 ? d.val : ""));
    }
  }

  defineScales(props) {
    if (!props.data || props.data.length <= 0) return;
    props.width = props.width - props.margin.left - props.margin.right;
    props.height = props.height - props.margin.top - props.margin.bottom;

    this.x = d3
      .scaleBand()
      .domain(d3.range(props.data.length))
      .range([props.margin.left, props.width - props.margin.right])
      .padding(0.1);

    const max =
      props.maxYVal > -1
        ? props.maxYVal
        : Math.max.apply(
            Math,
            props.data.map(function (o) {
              return o.val;
            })
          );

    this.y = d3
      .scaleLinear()
      .domain([0, max || 1])
      .nice()
      .range([props.height - props.margin.bottom, props.margin.top]);
  }

  defineAxes(props) {
    this.xAxis = d3.svg.axis().scale(this.x).orient("bottom");
    this.yAxis = d3.svg.axis().scale(this.y).orient("left");
  }

  componentDidMount() {
    const contextColors = Object.assign({}, this.props.contextColors);
    const props = merge(this.props);
    this.defineScales(props);
    this.chart(props, this.props.contextColors);
  }

  shouldComponentUpdate(nextProps) {
    const contextColors = Object.assign({}, nextProps.contextColors);
    const props = merge(nextProps);
    this.defineScales(props);
    this.chart(props, contextColors);
    return true;
  }

  render() {
    return (
      <div>
        <svg
          className={"tiny-barchart-svg"}
          ref={(ref) => (this.svgRef = ref)}
          style={{ width: this.props.width, height: this.props.height }}
        >
          <g className="graph"></g>
        </svg>
      </div>
    );
  }
}

export default TinyBarChart;
