import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DocumentAPI from "../api/DocumentAPI";
import AnnotationsAPI from "../api/AnnotationsAPI";

const initialState = {
  docs: [],
  docsOpened: [],
  docsAnnotations: [],
  docsLoading: [],
  docUpdated: new Date(),
  themeUpdated: new Date(),
};

export const deleteDoc = createAsyncThunk("document/remove", async (id) => {
  const res = await DocumentAPI.docsApiRemoveDocById(id);
  return res.data;
});

export const getDoc = createAsyncThunk("document/get", async ({ id }) => {
  const res = await DocumentAPI.docsApiGetDocById({ id });
  return res.data;
});

export const getAllDocs = createAsyncThunk("document/get_all", async () => {
  const res = await DocumentAPI.docsApiGetAllDocs();
  return res.data;
});

export const loadFile = createAsyncThunk("document/load", async ({ id }) => {
  const res = await DocumentAPI.docsApiLoadFileById({ id });
  return res.data;
});

export const getDocData = createAsyncThunk(
  "document/docdata",
  async ({ id }) => {
    const res = await DocumentAPI.docsApiGetDocData(id);
    return res.data;
  }
);

export const getDocumentsForSearchQuery = createAsyncThunk(
  "/document/get_for_search_query",
  async (data) => {
    const res = await DocumentAPI.docsApiGetDocumentsForSearchQuery(data);
    return res.data;
  }
);

export const parseDocText = createAsyncThunk(
  "document/text",
  async ({ id, pageData, forceParse }) => {
    const res = await DocumentAPI.docsApiParseText(id, pageData, forceParse);
    return res.data;
  }
);

export const resetDocTheme = createAsyncThunk(
  "document/removetheme",
  async ({ themeId }) => {
    return { themeId };
  }
);

export const updateDocContextTheme = createAsyncThunk(
  "document/updatetheme",
  async (data) => {
    const res = await DocumentAPI.docsApiUpdateContextTheme(data);
    return res.data;
  }
);

export const updateDocReferences = createAsyncThunk(
  "document/update_references",
  async (data) => {
    const res = await DocumentAPI.docsApiUpdateDocReferences(data);
    return res.data;
  }
);

export const getDocNotes = createAsyncThunk(
  "document/save_notes",
  async (data) => {
    const res = await DocumentAPI.docsApiGetDocNotes(data);
    return res.data;
  }
);

export const saveDocNotes = createAsyncThunk(
  "document/save_notes",
  async (data) => {
    const res = await DocumentAPI.docsApiSaveDocNotes(data);
    return res.data;
  }
);

export const updateDoc = createAsyncThunk(
  "document/refetch",
  async ({ id }) => {
    const res = await DocumentAPI.docsApiGetDocById({ id });
    return res.data;
  }
);

export const toggleReferenceBookmark = createAsyncThunk(
  "/annotation/toggle_reference_bookmark",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiToggleReferenceBookmark(
      data
    );
    return res.data;
  }
);

export const emptyReturn = createAsyncThunk(
  "document/empty_return",
  async ({ data }) => {
    const res = await Promise.resolve();
    return data;
  }
);

// @@ ADMIN
export const populateDocText = createAsyncThunk(
  "document/populate_doc_text",
  async ({ data }) => {
    const res = await DocumentAPI.docsApiPopulateDocText(data);
    return data;
  }
);

export const sliceDocuments = createSlice({
  name: "docs",
  initialState,
  reducers: {
    initDocuments: (state, action) => {
      state = initialState;
      return state;
    },
    addDocs: (state, action) => {
      action.payload.documents.forEach((doc) => {
        const found = state.docs.find((d) => d._id === doc._id);
        if (!found) state.docs.push(doc);
      });
      // state.docs = [...state.docs, ...action.payload];
      return state;
    },

    addDocLoading: (state, action) => {
      state.docsLoading.push(action.payload.docId);
      return state;
    },
    addPDFDoc: (state, action) => {
      if (action.payload.docId && action.payload.pdfObj) {
        const doc = state.docs.find((d) => d._id === action.payload.docId);
        if (doc) {
          doc.pdfDoc = action.payload.pdfObj;
        }
      }
      return state;
    },
    closeDoc: (state, action) => {
      const docId = action.payload.docId;
      if (docId) {
        state.docsOpened = state.docsOpened.filter((d) => d.fileId !== docId);
      }
      return state;
    },
    openDoc: (state, action) => {
      const docId = action.payload.docId;
      const folderId = action.payload.folderId;
      const doc = state.docs.find((d) => d._id === docId);
      var docToAdd = {};

      if (doc) {
        docToAdd.fileId = docId;
        docToAdd.name = doc.name;
        docToAdd.folderId = folderId;
        docToAdd.themeId = doc.annotations.contexts.themeId;
        doc.pdfDoc = action.payload.pdfObj;
        doc.flags.docParsed = action.payload.docData;
      }
      state.docsLoading = state.docsLoading.filter((d) => d !== docId);
      state.docsOpened.push(docToAdd);
      return state;
    },
    removeDoc: (state, action) => {
      state.docs = state.docs.filter((d) => d._id !== action.payload.docId);
    },
    removeDocLoading: (state, action) => {
      if (action.payload.docId) {
        state.docsLoading = state.docsLoading.filter(
          (d) => d !== action.payload.docId
        );
      }
      return state;
    },
    removeMultipleDoc: (state, action) => {
      // Expects the payload to contain a list of doc IDs
      state.docs = state.docs.filter((d) =>
        action.payload.findIndex(d._id) > -1 ? true : false
      );
    },
    updateAnnotationCount: (state, action) => {
      const docId = action.payload.docId;
      const updateType = action.payload.updateType;
      const contextId = action.payload.contextId;
      const doc = state.docs.find((d) => d._id === docId);
      if (doc) {
        if (updateType === "INCREMENT") {
          doc.annotations.total++;
        } else if (updateType === "DECREMENT") {
          doc.annotations.total--;
        }
      }

      return state;
    },
  },
  extraReducers: {
    [deleteDoc.fulfilled]: (state, action) => {
      if (action.payload.docId) {
        const docId = action.payload.docId;
        state.docsOpened = state.docsOpened.filter((d) => d.fileId !== docId);
        state.docs = state.docs.filter((d) => d._id !== docId);
      }
      return state;
    },
    [getAllDocs.fulfilled]: (state, action) => {
      state.docs = Object.assign([], action.payload.documents);
      return state;
    },
    [getDocumentsForSearchQuery.fulfilled]: (state, action) => {
      return state;
    },
    [getDocData.fulfilled]: (state, action) => {
      if (action.payload.data && action.payload.data.docData) {
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.data.id) {
            return { ...d, docData: { ...action.payload.data.docData } };
          } else return d;
        });
      }
      return state;
    },
    [parseDocText.fulfilled]: (state, action) => {
      if (action.payload.data) {
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.data.id) {
            return {
              ...d,
              metadata: {
                ...d.metadata,
                docData: { ...action.payload.data.docData },
              },
            };
          } else return d;
        });
      }
      return state;
    },
    [resetDocTheme.fulfilled]: (state, action) => {
      const themeId = action.payload.themeId;
      if (themeId) {
        state.docs = state.docs.filter((d) => {
          if (d.annotations.contexts.themeId === themeId) {
            return {
              ...d,
              annotations: {
                ...d.annotations,
                contexts: {
                  ...d.annotations.contexts,
                  themeId: "KG_ANNOTATION_THEME_DEFAULT",
                },
              },
            };
          } else return d;
        });

        state.docsOpened = state.docsOpened.filter((d) => {
          if (d.themeId === themeId) {
            return {
              ...d,
              themeId: "KG_ANNOTATION_THEME_DEFAULT",
            };
          } else return d;
        });
      }
    },
    [updateDocContextTheme.fulfilled]: (state, action) => {
      if (action.payload && action.payload.data) {
        const docId = action.payload.data.docId;
        const themeId = action.payload.data.themeId;

        state.docs = state.docs.map((d) => {
          if (d._id === docId) {
            return {
              ...d,
              annotations: {
                ...d.annotations,
                contexts: {
                  ...d.annotations.contexts,
                  themeId: themeId,
                },
              },
            };
          } else return d;
        });
        state.docsOpened = state.docsOpened.map((d) => {
          if (d.fileId === docId) {
            return {
              ...d,
              themeId: themeId,
            };
          } else return d;
        });
        state.themeUpdated = new Date();
      }
      return state;
    },
    [updateDocReferences.fulfilled]: (state, action) => {
      if (
        action.payload.data &&
        action.payload.data.docId &&
        action.payload.data.references
      ) {
        const docId = action.payload.data.docId;
        const references = action.payload.data.references;
        const doc = state.docs.filter((d) => d._id === docId);
        if (doc) doc[0].metadata.docData.bibliography = references;
      }
      return state;
    },
    [getDocNotes.fulfilled]: (state, action) => {
      if (action.payload.data && action.payload.data.docData) {
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.data.id) {
            return { ...d, docData: { ...action.payload.data.docData } };
          } else return d;
        });
      }
      return state;
    },
    [saveDocNotes.fulfilled]: (state, action) => {
      if (action.payload.data && action.payload.data.userNotes) {
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.data.id) {
            return {
              ...d,
              annotations: {
                ...d.annotations,
                userNotes: action.payload.data.userNotes,
              },
            };
          } else return d;
        });
      }
      return state;
    },
    [updateDoc.fulfilled]: (state, action) => {
      if (action.payload.document) {
        const docId = action.payload.document._id;
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.document._id) {
            return { ...d, tags: action.payload.document.tags };
          }
          return d;
        });
      }
    },
    [getDoc.fulfilled]: (state, action) => {
      if (action.payload.document) {
        const docId = action.payload.document._id;
        state.docs = state.docs.map((d) => {
          if (d._id === action.payload.document._id) {
            return { ...d, tags: action.payload.document.tags };
          }
          return d;
        });
      }
    },
    [toggleReferenceBookmark.fulfilled]: (state, action) => {
      if (action.payload.data) {
        if (action.payload.data.doc_id && action.payload.data.ref) {
          var docToUpdate = state.docs.find(
            (d) => d._id === action.payload.data.doc_id
          );

          if (docToUpdate) {
            const ref = action.payload.data.ref;
            for (
              var i = 0;
              i < docToUpdate.metadata.docData.bibliography.length;
              i++
            ) {
              if (
                ref.num &&
                docToUpdate.metadata.docData.bibliography[i].num &&
                ref.num === docToUpdate.metadata.docData.bibliography[i].num
              ) {
                docToUpdate.metadata.docData.bibliography[i].fav = ref.fav;
                break;
              } else if (
                !ref.num &&
                ref.text &&
                docToUpdate.metadata.docData.bibliography[i].text &&
                ref.text === docToUpdate.metadata.docData.bibliography[i].text
              ) {
                docToUpdate.metadata.docData.bibliography[i].fav = ref.fav;
                break;
              }
            }
          }
        }
      }
      return state;
    },
    [emptyReturn.fulfilled]: (state, action) => {
      const docId = action.meta.arg.docId;
      const folderId = action.meta.arg.folderId;
      const doc = state.docs.find((d) => d._id === docId);
      var docToAdd = {};

      if (doc) {
        docToAdd.fileId = docId;
        docToAdd.name = doc.name;
        docToAdd.folderId = folderId;
        docToAdd.themeId = doc.annotations.contexts.themeId;
        doc.pdfDoc = action.meta.arg.pdfObj;
        doc.flags.docParsed = action.meta.arg.docData;
      }
      state.docsLoading = state.docsLoading.filter((d) => d !== docId);
      state.docsOpened.push(docToAdd);
      return state;
    },
    [populateDocText.fulfilled]: (state, action) => {
      console.log("action.payload", action.payload)
      return state;
    },
  },
});

export const {
  initDocuments,
  addDocs,
  addDocLoading,
  addPDFDoc,
  addFolder,
  closeDoc,
  openDoc,
  removeDocLoading,
  updateAnnotationCount,
} = sliceDocuments.actions;
export default sliceDocuments.reducer;
