import { makeStyles } from "@material-ui/core";
import assets from "../../assets/assets";
const useStyles = makeStyles(() => ({
  wrapper: {
    // backgroundColor: "#fff",
    // columnGap: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "top",
    marginTop: "5px",
    marginBottom: "25px",
    padding: "25px",
    zIndex: "1",
  },
  videoPlayerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  videoPlayer: {
    width: "80%",
  },
}));

const HeroSection = ({ subTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.videoPlayerContainer}>
        <video controls="controls" className={classes.videoPlayer}>
          <source src={assets.kgOverviewVideo} type="video/mp4" />
        </video>
      </div>
      <div>{subTitle && subTitle !== "" ? subTitle : ""}</div>
    </div>
  );
};

export default HeroSection;
