import * as React from "react";
import { useTheme } from "@material-ui/core";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import IllustrationKG from "../../../assets/design/ui/kg-welcome-graphic-1.png";
import DocViewDetails from "../../../assets/design/ui/doc-view-details-v0.3.png";
import DocViewDocData from "../../../assets/design/ui/doc-view-data-1-v0.3.png";
import ContextAnnotationsOverview from "../../../assets/design/ui/kg-guide-v0.3-1.png";
import IllustrationTY from "../../../assets/design/ui/kg-thank-you-graphic-1.png";

const AutoPlaySwipeableViews = SwipeableViews;

const images = [
  {
    label: "",
    //   "Capture, visualize, and curate insights from your research documents.",
    //   "Knowledge Garden is a document reading app to help you capture, visualize, and curate insights as you read research documents.",
    imgPath: IllustrationKG,
  },
  {
    label: " ",
    //   "Create contextual annotations. Recall and visualize insights across documents.",
    //   "Create contextual annotations. Visualize your insights, recall information quickly, and compare insights across multiple documents.",
    imgPath: ContextAnnotationsOverview,
  },
  {
    label:
      "Example of an annotated document. The annotation contexts help with recalling insights.",
    //   "Create contextual annotations. Recall and visualize insights across documents.",
    //   "Create contextual annotations. Visualize your insights, recall information quickly, and compare insights across multiple documents.",
    imgPath: DocViewDetails,
  },
  {
    label: "Tools and annotation themes make literature review more effective.",
    imgPath: DocViewDocData,
  },
  {
    label:
      "Built by a researcher - for researchers, students, and knowledge seekers.",
    imgPath: IllustrationTY,
  },
];

const KGGuideStepper = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  width: "75%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: theme.palette.primary.dark, height: "30px" }}
        >
          {images[activeStep].label}
        </Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="normal"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default KGGuideStepper;
