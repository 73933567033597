import { makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getUserIssues } from "../../../store/SliceUsers";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    fontSize: "0.85em",
  },
  user: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    marginBottom: "8px",
  },
  issue_key: {
    borderRight: "3px solid white",
    fontWeight: "bold",
    padding: "10px",
    width: "auto",
  },
  issue_timestamp: {
    borderRight: "3px solid white",
    overflowWrap: "anywhere",
    padding: "10px",
    width: "250px",
  },
  user_docs: {
    borderRight: "3px solid white",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "250px",
  },
  issue_description: {
    borderRight: "3px solid white",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "300px",
  },
}));

const IssueReport = ({ issues }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const [issues, setIssues] = useState([]);

  // useEffect(() => {
  //   dispatch(getUserIssues()).then((data) => {
  //     setIssues(data.payload.issues);
  //   });
  // }, []);

  return (
    <div className={classes.wrapper}>
      <div>
        <Typography variant="h5">Issues</Typography>
        <div>
          {issues.map((issue, i) => (
            <div className={classes.user} key={i}>
              <span className={classes.issue_key}>{issue.issue.key}</span>
              <span className={classes.issue_key}>
                ID: {issue._id}
              </span>

              <span className={classes.issue_timestamp}>{issue.timestamp}</span>
              <span className={classes.issue_description}>
                {issue.issue.description}
              </span>
              <span className={classes.issue_description}>
                User: {issue._user}
              </span>
              <span className={classes.issue_description}>
                Name: {issue.user_name || " "}
              </span>
              <span className={classes.issue_description}>
                Email: {issue.user_email || " "}
              </span>
              <span className={classes.issue_description}>
                Doc: {issue._doc}
              </span>
              {/*  <div>{user.docs.length} Documents</div>
                <ul>
                  {user.docs.map((doc, di) => (
                    <li key={di}>{doc.name}</li>
                  ))}
                </ul>
              </span> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IssueReport;
