import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.display1,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    // Display the name of the current breakpoint
    "&::before": {
      [theme.breakpoints.down("sm")]: {
        content: `'Screen size = xs'`,
      },
      [theme.breakpoints.up("sm")]: {
        content: `'Screen size = sm'`,
      },
      [theme.breakpoints.up("md")]: {
        content: `'Screen size = md'`,
      },
      [theme.breakpoints.up("lg")]: {
        content: `'Screen size = lg'`,
      },
      [theme.breakpoints.up("xl")]: {
        content: `'Screen size = xl'`,
      },
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
  },
  db_panel_title: {
    borderRadius: "4px",
    // padding: "4px",
  },
  db_panel_content: {
    flex: "1 1 auto",
  },
  db_panel_subtitle: {
    color: "#999999",
    fontSize: ".9em",
    textAlign: "center",
  },
  db_panel_subsubtitle: {
    fontSize: "small",
    fontFamily: "Arial",
    color: "#888",
  },
}));
const DashboardPanelContent = ({ title, children, subtitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {/* <div className={classes.root} /> */}
      <div className={classes.db_panel_title}>
        <Typography style={{ fontSize: "1.5em" }} align="center">
          {title}
        </Typography>
      </div>
      <div className={classes.db_panel_subtitle}>{subtitle}</div>
      <div>
        <hr style={{ color: "#d0d0d0", height: "3px" }} />
      </div>
      <div className={classes.db_panel_content}>{children}</div>
    </div>
  );
};

export default DashboardPanelContent;
