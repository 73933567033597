import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { removeAnnotationByDocId } from "../../../store/SliceAnnotations";
import { closeDoc } from "../../../store/SliceDocuments";
import DashboardPanel from "../TabPanels/DashboardPanel";
import "./RBT.css";
import RBTDocTab from "./RBTDocTab";
import TabPane from "./TabPane";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  kg_main_content: {
    backgroundColor:
      theme.palette.type === "dark" ? "#444 !important" : "#fff !important",
    display: "flex",
    flexDirection: "column",
  },
  kg_tabs_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflowX: "auto",
  },
  kg_dashboard_tab_container: {
    margin: 0,
    marginTop: 5,
  },
  kg_doc_tabs_container: {
    backgroundColor:
      theme.palette.type === "dark" ? "#444 !important" : "#fafafa !important",
      marginTop: 5,
  },
  kg_doc_tabs: {
    cursor: "pointer",
  },
  kg_tabpanels_container: {
    height: "90vh",
  },
  dashboard_tab_item: {
    cursor: "pointer",
  },
  doc_tab_label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignContent: "center",
  },
  tab_nav_item: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {},
  },
}));

const RBTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [maxContextCountOverAllDocs, setMaxContextCount] = useState(-1);
  const [selectedTab, setSelectedTab] = useState();
  const [tabList, setTabList] = useState([]);

  const docStats = useSelector((state) => state.notes.docStats);
  const folders = useSelector((state) => state.folders.folders);
  const openedDocs = useSelector((state) => state.docs.docsOpened);
  const theme = useTheme();

  // Recalculate max context cound over all docs
  useEffect(() => {
    var tempMax = -1;
    docStats.forEach((d) => {
      d.stats.forEach((c) => {
        if (c.val > tempMax) tempMax = c.val;
      });
    });
    setMaxContextCount(tempMax);
  }, [docStats, openedDocs]);

  // Create a list of tabs for handling tab closure
  useEffect(() => {
    const tabs = [];
    openedDocs.forEach((doc, i) => {
      tabs.push({
        id: i + 1,
        label: doc.name,
        name: doc.name,
        fileId: doc.fileId,
      });
    });

    setTabList(tabs);
  }, [openedDocs]);

  const handleTabSelection = (activeKey) => {
    setSelectedTab(activeKey);
  };

  const handleCloseTab = useCallback(
    (event, docToClose, docs) => {
      event.stopPropagation();

      const indexOfDocToClose = tabList.findIndex(
        (doc) => doc.fileId === docToClose.fileId
      );

      const updatedTabs = tabList.filter((tab, index) => {
        return index !== indexOfDocToClose;
      });
      const previousTab =
        tabList[indexOfDocToClose - 1] || tabList[indexOfDocToClose + 1] || {};

      // Remove document from store
      dispatch(closeDoc({ docId: docToClose.fileId }));

      // Remove annotations for document from the store
      dispatch(removeAnnotationByDocId({ docId: docToClose.fileId }));

      setTabList(updatedTabs);

      if (updatedTabs.length === 0) {
        setSelectedTab("DASHBOARD");
      } else {
        setSelectedTab(previousTab.fileId);
      }
    },
    [tabList]
  );

  return (
    <div className={classes.wrapper}>
      <Tab.Container
        id="doctabs"
        defaultActiveKey="DASHBOARD"
        activeKey={selectedTab}
        unmountOnExit={false}
        className={classes.wrapper}
        onSelect={handleTabSelection}
      >
        <div className={classes.kg_main_content}>
          <div className={classes.kg_tabs_container}>
            <div className={classes.kg_dashboard_tab_container}>
              <Nav className="doc-tabs" variant="tabs">
                <Nav.Item id="DASHBOARD">
                  <Nav.Link
                    eventKey="DASHBOARD"
                    style={{
                      backgroundColor:
                        selectedTab === "DASHBOARD"
                          ? theme.palette.primary.main
                          : theme.palette.primary.light,
                      border: "1px solid transparent",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderTopLeftRadius: 12,
                      borderTopRightRadius: 12,
                      width: "100px",
                    }}
                  >
                    <Typography>Dashboard</Typography>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className={classes.kg_doc_tabs_container}>
              <div
                style={{ flex: "1 0 auto", display: "flex", overflowX: "auto" }}
              >
                <Nav
                  className="doc-tabs"
                  variant="tabs"
                  style={{
                    borderBottom: theme.kg_ui.doc_tabs.tab_group.borderBottom,
                  }}
                >
                  {openedDocs.length > 0
                    ? openedDocs.map((d, i) => (
                        <Nav.Item key={i}>
                          <Nav.Link
                            eventKey={d.fileId}
                            style={{
                              backgroundColor:
                                selectedTab === d.fileId
                                  ? theme.palette.secondary.light
                                  : theme.palette.secondary.lightest,
                              border: `1px solid ${theme.palette.secondary.main}`,
                              // borderBottom: `1px solid ${theme.palette.secondary.main}`,
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                            }}
                          >
                            <RBTDocTab
                              doc={d}
                              folder={
                                folders.filter((f) => f._id === d.folderId) ||
                                null
                              }
                              onClose={handleCloseTab}
                              maxContextCountOverAllDocs={
                                maxContextCountOverAllDocs
                              }
                              selected={selectedTab === d.fileId ? true : false}
                            />
                          </Nav.Link>
                        </Nav.Item>
                      ))
                    : ""}
                </Nav>
              </div>
            </div>
          </div>
          <div className={classes.kg_tabpanels_container}>
            <Tab.Content>
              <Tab.Pane eventKey="DASHBOARD">
                <DashboardPanel />
              </Tab.Pane>
              {openedDocs.map((d, i) => (
                <TabPane docData={d} key={i} />
              ))}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};

export default RBTabs;
