import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@material-ui/core";
import GuideContentFullPage from "./GuideContentFullPage";

const UserGuide = ({
  contentText,
  cancelButtonText,
  callbackCancel,
  callbackProceed,
  openDialog,
  title,
}) => {
  const handleCancel = (event, reason) => {
    // if (reason && reason == "backdropClick") return;
    if (callbackCancel) callbackCancel();
  };

  return (
    <div>
      <Dialog
        // fullWidth={true}
        // maxWidth={"lg"}
        fullScreen
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: "2em" }}>
            User Guide
          </Typography>
          <div>
            <IconButton onClick={() => callbackCancel()}>
              <CloseIcon style={{ fontSize: "2em" }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <GuideContentFullPage />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCancel} variant="outlined" autoFocus>
            {cancelButtonText || "Cancel"}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default UserGuide;
