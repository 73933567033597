import assets from "../assets/assets";
const SiteContent = {
  cta1Title: "Launch App",
  cta1SubTitle: "Explore as Guest or Sign up",
  cta2Title: "Build Your Garden of Knowledge",
  cta2SubTitle: "Launch App",
  heroSubTitle: "Go to webapp",
  title: "Knowledge Garden",
  footerTitle: "Copyright 2022 KnowledgeGarden.io. All rights reserved.",
  footerSubTitle: "",
  privacyPolicyURL: "", // "https://www.termsfeed.com/live/4902cd88-535f-4dc2-97c0-cffe0d7fde8e",
  termsAndConditionsURL: "",
  subTitle:
    "A document reader with tools for creating contextual annotations, discovering insights, and visualizing personal knowledge",
  data: [
    {
      titlePart1: "Deeper Understanding",
      titlePart2: "of your documents",
      titleAlignment: "left",
      textPrimary:
        "Knowledge Garden (KG) is an app to support deep reading and for curating insights and personal knowledge obtained while reading research articles and technical documents.",
      textSecondary: "",
      textBulletList: [
        "Explore contents of documents and document collections",
        "Improved recall of information through contextual annotations",
        "Tools to aid the literature review process.",
      ],
      textTertiary:
        "Knowledge Garden is for knowledge seekers - students, researchers, and professionals, who need to deeply read, understand, and analyze documents for their research or work.",
      imageAltText: "Figure",
      imagePath: [assets.kgDocViewGuide1],
      imagePosition: "right",
    },
    {
      titlePart1: "Capture and Illuminate",
      titlePart2: "your knowledge",
      titleAlignment: "left",
      textPrimary:
        "Curate your insights and personal knowledge using a novel contextual annotation approach. ",
      textSecondary: "",
      textBulletList: [
        "Add personalized contexts to annotated information",
        "Recall information better through contextual search",
        "Discover and connect insights within your personal knowledge base.",
      ],
      imageAltText: "Figure",
      imagePath: [assets.kgDashboardImage1],
      imagePosition: "left",
    },
    {
      titlePart1: "Research Companion",
      titlePart2: "for the long haul",
      titleAlignment: "left",
      textPrimary:
        "We spoke to a number of researchers about their literature review and note-taking practices. We are building Knowledge Garden to tackle some of the pain points they mentioned",
      // "Text analytics and data visualizations help with quick skimming and deep review of documents. Extracted text such as references, URLs, and keywords provide access to document data.",
      textSecondary: "",
      // "Other features include a built-in notepad, an exportable report of your notes and annotations, and analysis of collection of documents.",
      textBulletList: [
        "Challenges in consuming large amount of reading material",
        "Lack of tools for organizing notes and annotations, all in one place",
        "Cumbersome reference management",
        "Identifying gaps in literature review",
        "And more..",
      ],
      imageAltText: "Figure",
      imagePath: [
        assets.kgKeywordChart1,
        assets.kgTextExtract1,
        assets.kgReferences1,
      ],
      imagePosition: "bottom",
    },
  ],
};

export default SiteContent;
