import { makeStyles } from "@material-ui/core";
import NoteIcon from "@mui/icons-material/Article";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  updateAnnotation,
} from "../../../store/SliceAnnotations";
import { updateDoc } from "../../../store/SliceDocuments";
import ImageZoomInView from "../../Visualizations/ImageZoomInView";
import AnnotationActions from "./AnnotationActions";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fafafa",
    borderRadius: "4px",
    border: "1px solid #efefef",
    // fontSize: "14px",
    margin: "0px",
    marginBottom: "5px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "10px",
  },
  note_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "3px",
  },
  note_parent_doc_name: {
    color: theme.palette.primary.dark,
    fontSize: "small",
  },
  note_comment: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "5px",
    textAlign: "left",
  },
  note_image: {
    border: "1px solid #00000000",
    // boxShadow: "0px 2px 2px #00000033",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflow: "hidden",
    padding: "15px",
    "&:hover": {
      border: "1px solid #cccccc",
      transition: "border 0.3s",
    },
  },
  note_text: {
    border: "1px solid #00000000",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "5px",
    textAlign: "left",
    "&:hover": {
      border: "1px solid #cccccc",
      transition: "border 0.3s",
    },
  },
  note_text_area: {
    border: "1px solid #fff",
    width: "94%",
  },
  context_label: {
    fontSize: "small",
    fontWeight: 700,
  },
  context_icons: {
    color: "gray",
    display: "flex",
    justifyContent: "space-between",
  },
  context_theme_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  context_theme_name: {
    color: "#000",
    backgroundColor: "#fff",
    borderRadius: "50px",
    fontSize: "0.75em",
    paddingLeft: "8px",
    paddingRight: "8px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  delete_note_icon: {
    cursor: "pointer",
    "&:hover": {
      color: "#ff333388",
    },
  },
}));
const AnnotationCard = ({
  annotation,
  context,
  docId,
  deleteHighlight,
  themeColors,
  truncateText,
  viewOutsideDocument,
  visitHighlight,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updatedAnnotation = annotation;
  const [editMode, setEditMode] = useState(false);
  const [textPreview, setTextPreview] = useState(truncateText || false);
  const [openImageView, setOpenImageView] = useState(false);
  const [imageData, setImageData] = useState(null);
  const updatedNoteText = useRef();
  const updatedNoteComment = useRef();
  const isImageNote = annotation.content.image ? true : false;
  const DOC_NAME_MAX_CHARS = 50;

  useEffect(() => {
    updatedNoteComment.current = updatedAnnotation.comment.text;
    updatedNoteText.current = updatedAnnotation.content.text;
    if (isImageNote) {
      setImageData(annotation.content.image);
    }
  });

  const textLengthThreshold = 50;

  const handleDeleteNote = (event) => {
    //@@ Moved to PdfView.jsx
    // dispatch(
    //   removeAnnotations({ docId: docId, ids: [updatedAnnotation._id] })
    // ).then(() => {
    //   console.log("here NOW delete the annotation")
    //   deleteHighlight(updatedAnnotation._id);
    // });
    deleteHighlight(updatedAnnotation._id);
  };

  const handleEditNote = () => {
    setEditMode(!editMode);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveNote = () => {
    setEditMode(!editMode);
    dispatch(
      updateAnnotation({
        ...updatedAnnotation,
        comment: {
          ...updatedAnnotation.comment,
          text: updatedNoteComment.current,
        },
        content: {
          ...updatedAnnotation.content,
          text: updatedNoteText.current,
        },
      })
    ).then(() => {
      dispatch(getAllFavoriteAnnotations()).then(() =>
        dispatch(getAllTodoAnnotations())
      );
      dispatch(updateDoc({ id: annotation["_doc"] }));
    });
  };

  const handleCloseImageView = () => {
    setOpenImageView(false);
  };

  const handleOpenImageView = () => {
    if (!viewOutsideDocument) return;
    setOpenImageView(true);
  };

  const handleUpdateNoteComment = (event) => {
    updatedNoteComment.current = event.target.value;
  };

  const handleUpdateNoteText = (event) => {
    updatedNoteText.current = event.target.value;
  };

  const FitDocName = (docName) => {
    return docName.length > DOC_NAME_MAX_CHARS
      ? docName.substr(0, DOC_NAME_MAX_CHARS) + ".."
      : docName;
  };

  const ClickToExpand = ({ text }) => {
    return (
      <div>
        {text} ...
        <br />
        <span
          style={{
            color: "#666",
            fontWeight: "700",
            fontVariantCaps: "small-caps",
          }}
        >
          click to expand
        </span>
      </div>
    );
  };

  const ClickToCollapse = ({ text }) => {
    return (
      <div>
        {text}
        <br />
        <span
          style={{
            color: "#666",
            fontWeight: "700",
            fontVariantCaps: "small-caps",
          }}
        >
          click to collapse
        </span>
      </div>
    );
  };

  const EditableNote = ({ editMode, text }) => {
    return (
      <div style={{ display: "flex", justifyContent: "flext-start" }}>
        {!viewOutsideDocument ? (
          <span style={{ color: "gray", marginRight: "5px" }}>
            <NoteIcon />
          </span>
        ) : (
          ""
        )}
        {editMode ? (
          <textarea
            className={classes.note_text_area}
            defaultValue={text.length === 0 ? "Empty" : text}
            rows={5}
            onChange={handleUpdateNoteText}
          />
        ) : (
          text
        )}
      </div>
    );
  };

  const NotesCommentContent = ({ editMode, text }) => {
    if (!editMode && text === "") return <></>;
    return (
      <div
        style={{
          color: "gray",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "15px",
          paddingRight: "5px",
        }}
      >
        <span style={{ color: "gray", marginRight: "12px" }}>
          <ChatBubbleIcon />
        </span>
        {editMode ? (
          <textarea
            className={classes.note_text_area}
            defaultValue={text.length === 0 ? "Add your comment..." : text}
            rows={3}
            onChange={handleUpdateNoteComment}
          />
        ) : (
          text
        )}
      </div>
    );
  };

  const NotesTextContent = ({ preview, text }) => {
    return (
      <div>
        {editMode ? <EditableNote editMode={editMode} text={text} /> : text}
      </div>
    );
  };

  if (!themeColors) return <></>;
  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundColor:
          themeColors[annotation.context.contextId].colorLighter2,
        borderLeft: `5px solid ${
          themeColors[annotation.context.contextId].colorMain
        }`,
      }}
    >
      <div className={classes.note_header}>
        <div className={classes.context_theme_container}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            {viewOutsideDocument ? (
              <div className={classes.context_theme_name} alt="Theme name">
                {updatedAnnotation.context.themeName}
              </div>
            ) : (
              ""
            )}
            <div className={classes.context_label} alt="Context tag">
              {annotation.context.label}
            </div>
          </div>
          {viewOutsideDocument ? (
            <div className={classes.context_icons}>
              <AnnotationActions
                annotation={annotation}
                callbackDeleteCard={handleDeleteNote}
                disableActions={viewOutsideDocument}
                editMode={editMode}
                callbackEditAnnotation={handleEditNote}
                callbackCancelEdit={handleCancelEdit}
                callbackSaveAnnotation={handleSaveNote}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={classes.note_parent_doc_name}>
        {updatedAnnotation.docName
          ? `In: ${FitDocName(updatedAnnotation.docName)}`
          : ""}
      </div>
      {isImageNote ? (
        <div>
          <span
            className={classes.note_image}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <img
              src={imageData}
              alt=""
              style={{
                width: viewOutsideDocument ? "200px" : "100%",
                height: viewOutsideDocument ? "200px" : "100%",
                objectFit: viewOutsideDocument ? "cover" : "cover",
              }}
              onClick={() =>
                viewOutsideDocument
                  ? handleOpenImageView()
                  : visitHighlight(updatedAnnotation)
              }
            />
          </span>
          <NotesCommentContent
            editMode={editMode}
            text={updatedAnnotation.comment.text}
          />
          {!viewOutsideDocument ? (
            <div className={classes.context_icons}>
              <AnnotationActions
                annotation={annotation}
                callbackDeleteCard={handleDeleteNote}
                disableActions={viewOutsideDocument}
                editMode={editMode}
                callbackEditAnnotation={handleEditNote}
                callbackCancelEdit={handleCancelEdit}
                callbackSaveAnnotation={handleSaveNote}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          <div>
            {viewOutsideDocument ? (
              <div
                className={classes.note_text}
                onClick={() => setTextPreview(!textPreview)}
              >
                {textPreview ? (
                  <ClickToExpand
                    text={updatedAnnotation.content.text.substring(
                      0,
                      textLengthThreshold
                    )}
                  />
                ) : (
                  <ClickToCollapse text={updatedAnnotation.content.text} />
                )}
              </div>
            ) : (
              <div
                className={classes.note_text}
                onClick={() => visitHighlight(updatedAnnotation)}
              >
                <NotesTextContent
                  preview={textPreview}
                  text={updatedAnnotation.content.text}
                />
              </div>
            )}
            <NotesCommentContent
              editMode={editMode}
              text={updatedAnnotation.comment.text}
            />
          </div>
          {!viewOutsideDocument ? (
            <div className={classes.context_icons}>
              <AnnotationActions
                annotation={annotation}
                callbackDeleteCard={handleDeleteNote}
                disableActions={viewOutsideDocument}
                editMode={editMode}
                callbackEditAnnotation={handleEditNote}
                callbackCancelEdit={handleCancelEdit}
                callbackSaveAnnotation={handleSaveNote}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <ImageZoomInView
        data={imageData}
        openDialog={openImageView}
        callbackCancel={handleCloseImageView}
      />
    </div>
  );
};

export default AnnotationCard;
