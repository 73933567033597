import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const kgLogo = require("../../assets/logo/logo-kg-1.png");

const AlertDialog = ({
  contentText,
  cancelButtonText,
  callbackCancel,
  callbackProceed,
  openDialog,
  proceedButtonText,
  title,
}) => {
  // const [open, setOpen] = React.useState(openDialog);

  const handleCancel = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    if (callbackCancel) callbackCancel();
  };

  const handleProceed = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    if (callbackProceed) callbackProceed();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <img src={kgLogo} style={{ height: 45, width: 45, marginRight: "10px" }} alt="" />
          &nbsp;{title || "Alert!"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {contentText || "Proceed with caution, my friend!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {callbackCancel ? (
            <Button onClick={handleCancel} variant="outlined" autoFocus>
              {cancelButtonText || "Cancel"}
            </Button>
          ) : (
            ""
          )}
          {callbackProceed ? (
            <Button onClick={handleProceed} variant="contained" color="error">
              {proceedButtonText || "Proceed"}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
