import { makeStyles } from "@material-ui/core";
import React from "react";
import SectionHeader from "./SectionHeader";
import SectionImage from "./SectionImage";
import SectionImageGrid from "./SectionImageGrid";
import SectionText from "./SectionText";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "top",
    marginBottom: "50px",
    padding: "50px",
  },
  contentWrapper: {
    display: "grid",
    gap: "50px",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  },
  contentWrapperStacked: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
  },
}));
const SectionView = ({ body }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        {body.imagePosition === "right" ? (
          <div className={classes.contentWrapper}>
            <SectionText body={body} />
            <div>
              <SectionImageGrid images={body.imagePath} />
            </div>
          </div>
        ) : body.imagePosition === "left" ? (
          <div className={classes.contentWrapper}>
            <SectionImageGrid images={body.imagePath} />
            <SectionText body={body} />
          </div>
        ) : (
          <div>
            <div className={classes.contentWrapperStacked}>
              <SectionText body={body} />
              <SectionImageGrid images={body.imagePath} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SectionView;
