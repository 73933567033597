import { Badge, makeStyles, useTheme } from "@material-ui/core";
import AnnotationsIcon from "@mui/icons-material/ArticleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Notepad from "../../UIWidgets/Notepad";
import NotesPanel from "./NotesPanel";
import ReferencesPanel from "./ReferencesPanel";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "white",
    // border: "1px solid red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    overflow: "none",
    padding: "0",
    width: "43vw",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "5px",
  },
  annotation_doc_tabs: {
    backgroundColor: "#f9f9f9",
  },
}));
const AnnotationView = ({
  docId,
  deleteHighlight,
  themeColors,
  themeKey,
  visitHighlight,
}) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const [selectedTab, setSelectedTab] = useState("ANNOTATION");
  const [numReferences, setNumReferences] = useState(0);
  const handleTabSelection = (activeKey) => {
    setSelectedTab(activeKey);
  };

  return (
    <div className={classes.wrapper}>
      <Tab.Container
        id="annotation-panel-tabs"
        defaultActiveKey="ANNOTATION"
        activeKey={selectedTab}
        unmountOnExit={false}
        className={classes.wrapper}
        onSelect={handleTabSelection}
      >
        <Nav className={classes.annotation_doc_tabs} variant="tabs">
          <Nav.Item id="ANNOTATION">
            <Nav.Link eventKey="ANNOTATION">
              Annotations
              {/* <AnnotationsIcon /> */}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id="NOTEPAD">
            <Nav.Link eventKey="NOTEPAD">
              Notepad
              {/* <EditIcon /> */}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id="REFERENCES">
            <Nav.Link eventKey="REFERENCES">
              References
              <Badge
                badgeContent={numReferences}
                color="secondary"
                max={999}
                overlap="rectangular"
                style={{ marginLeft: 25, marginRight: 15 }}
              ></Badge>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="ANNOTATION">
            <NotesPanel
              docId={docId}
              deleteHighlight={deleteHighlight}
              themeColors={themeColors}
              themeKey={themeKey}
              visitHighlight={visitHighlight}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="NOTEPAD">
            <Notepad docId={docId} />
          </Tab.Pane>
          <Tab.Pane eventKey="REFERENCES">
            <ReferencesPanel
              docId={docId}
              callbackSetNumReferences={setNumReferences}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
export default AnnotationView;
