import { Box, Button, Grid, makeStyles, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import REACT_APP_CONFIG from "../../../AppConfig";
import InMemoryJWT from "../../../api/JWTManager";
import DocumentList from "../../Document/DocumentList";
import DocumentTermPillsVis from "../../Document/DocumentTermPillsVis";
import AnnotationsExplorer from "../Annotations/AnnotationsExplorer";
import FilterAnnotations from "../Annotations/FilterAnnotations";
import AppHelpGuidePrompt from "../AppNav/AppHelpGuidePrompt";
import DashboardContent from "../Dashboard/DashboardPanelContent";
import UserGuide from "../UserGuide/UserGuide";

const useStyles = makeStyles((theme) => ({
  db_panels: {
    backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#3d3d3d",
    boxShadow: "0px 2px 5px #dddddd",
    height: "89vh",
    padding: 15,
    margin: 10,
    flex: 1,
    minHeight: 0,
  },
}));
const DashboardPanel = ({ children }) => {
  const classes = useStyles();
  const folders = useSelector((state) => state.folders.folders);
  const [showAnnotationsExplorer, setShowAnnotationsExplorer] = useState(false);
  const [showAppGuide, setShowAppGuide] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);

  useEffect(() => {
    const isGuest = InMemoryJWT.getUserIsGuest();
    if (folders.length === 0) setShowAppGuide(true);
    else if (isGuest) setShowAppGuide(true);
  }, []);

  const handleOpenAnnotationsExplorer = () => {
    setShowAnnotationsExplorer(true);
  };

  const handleCloseAnnotationsExplorer = () => {
    setShowAnnotationsExplorer(false);
  };

  const handleCancelShowAppGuide = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setShowAppGuide(false);
  };

  const handleShowUserGuide = () => {
    setShowAppGuide(false);
    setShowUserGuide(true);
  };

  const handleCloseUserGuide = () => {
    setShowUserGuide(false);
  };

  return (
    <>
      <div
        role="tabpanel"
        id={`document-panel-TAB_DASHBOARD`}
        aria-labelledby={`document-tab-TAB_DASHBOARD`}
      >
        <Box>
          <Grid container elevation={2}>
            <Grid item xs={12} md={5} lg={4}>
              <Paper className={classes.db_panels} elevation={0}>
                <DashboardContent
                  title={"Library"}
                  subtitle={"Add documents and folders"}
                  children={<DocumentList />}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Paper className={classes.db_panels} elevation={0}>
                <DashboardContent
                  title={"Annotations & Notes"}
                  subtitle={"View your contextual annotations"}
                  children={
                    <>
                      <div
                        style={{
                          // alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyItems: "space-around",
                        }}
                      >
                        {REACT_APP_CONFIG.dev === true ? (
                          <>
                            <div>
                              <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleOpenAnnotationsExplorer}
                              >
                                Visual Explorer
                              </Button>
                            </div>
                            <div>&nbsp;</div>
                          </>
                        ) : (
                          ""
                        )}

                        <FilterAnnotations />
                      </div>
                    </>
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={6} md={3} lg={4}>
              <Paper className={classes.db_panels} elevation={0}>
                <DashboardContent
                  title={"Analytics"}
                  subtitle={"Explore your document collections"}
                  children={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <DocumentTermPillsVis />
                    </div>
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>

      <AnnotationsExplorer
        openDialog={showAnnotationsExplorer}
        callbackCancel={handleCloseAnnotationsExplorer}
      />
      <AppHelpGuidePrompt
        openDialog={showAppGuide}
        callbackCancel={handleCancelShowAppGuide}
        callbackShowUserGuide={handleShowUserGuide}
        cancelButtonText={"Go To Dashboard"}
      />
      <UserGuide
        openDialog={showUserGuide}
        callbackCancel={handleCloseUserGuide}
        cancelButtonText={"Close"}
        contentText={"Coming soon.."}
      />
    </>
  );
};

export default DashboardPanel;
