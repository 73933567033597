import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconStarUnset from "@mui/icons-material/BookmarkBorder";
import IconStar from "@mui/icons-material/BookmarkAdded";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fff",
    boxShadow: "0px 1px 5px #e9e9e9",
    borderRadius: "4px",
    margin: "5px",
    marginBottom: "10px",
    padding: "10px",
    width: "93%",
  },
  reference_container: {
    alignItems: "top",
    display: "flex",
    fontSize: "0.9em",
    justifyContent: "flex-start",
  },
  reference_fav_button: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
}));

const ReferencesCard = ({
  bibitem,
  docId,
  callbackSetFavRef,
  refId,
  updating,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.reference_container}>
        <div>
          {updating ? (
            <div style={{ marginRight: "20px" }}>
              <CircularProgress size={20} variant="indeterminate" />
            </div>
          ) : (
            <div>
              {bibitem.fav ? (
                <Tooltip
                  arrow
                  placement="top"
                  title={<Typography fontSize={16}>Remove bookmark</Typography>}
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton
                    className={classes.reference_fav_button}
                    onClick={() => callbackSetFavRef(bibitem, refId)}
                    size="small"
                  >
                    <IconStar />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>
                      Bookmark this reference
                      {/* <hr /> */}
                    </Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton
                    className={classes.reference_fav_button}
                    onClick={() => callbackSetFavRef(bibitem, refId)}
                    size="small"
                  >
                    <IconStarUnset />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div>{bibitem.text}</div>
      </div>
    </div>
  );
};

export default ReferencesCard;
