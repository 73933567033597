import {
    Dialog, DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import AnnotationsDashboard from "./AnnotationsDashboard";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  quick_view_content_wrapper: {
    display: "flex",
    alignContent: "top",
    justifyContent: "space-between",
  },
  quick_view_content: {
    width: "100%",
  },
  quickview_title: {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
  },
  dialog_actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const AnnotationsExplorer = ({
  cancelButtonText,
  callbackCancel,
  openDialog,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.quickview_title}>
            <Typography style={{ fontSize: "2em" }}>
              Explore Annotations
            </Typography>
            <div className={classes.dialog_actions}>
              <IconButton onClick={callbackCancel}>
                <CloseIcon style={{ fontSize: "2em" }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.quick_view_content_wrapper}>
            <div className={classes.quick_view_content}>
              <AnnotationsDashboard />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AnnotationsExplorer;
