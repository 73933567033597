import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makePost } from "../../api/api";

import "./LoginSignupForms.css";
import { useDispatch } from "react-redux";
import PageHeader from "../../static_site/src/components/PageNavBar";

const LoginForm = ({ onSignUpUser }) => {
  const [loginError, setLoginError] = useState("");
  const [progress, showProgress] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
      };

      showProgress(true);

      makePost("/user/signup", data)
        .then((response) => {
          showProgress(false);
          if (response.error) {
            setLoginError(response.error);
          } else {
            setLoginError("");
            onSignUpUser(response);
          }
        })
        .catch((error) => {
          showProgress(false);
          setLoginError("Error logging in.");
        });
    },
  });

  return (
    <div className="LoginForm">
      <PageHeader title={"Knowledge Garden"} />
      <Grid>
        <Card className="login" style={{ marginTop: 20 }}>
          <CardContent>
            {/* <Grid xs={12} item>
              <Typography
                align="center"
                variant="h4"
                style={{ marginBottom: 15 }}
              >
                Knowledge Garden
              </Typography>
              <div className="login-form-subtitle">
                Gather insights as you seek new knowledge
              </div>
            </Grid> */}

            <Grid xs={12} item>
              <div className="login-signup-header">
                <div>
                  <Typography variant="h6">Create Account</Typography>
                </div>
                <div>
                  <Button href="/login" variant="text" color="primary">
                    Login
                  </Button>
                </div>
              </div>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Typography align="left" style={{ margin: 5 }}>
                    Name
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    placeholder="Bravo"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.values.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Typography align="left" style={{ margin: 5 }}>
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="bravo@email.xyz"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.values.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="left" style={{ margin: 5 }}>
                    Password
                  </Typography>

                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="left" style={{ margin: 5 }}>
                    Confirm Password
                  </Typography>

                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Enter confirm password"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                </Grid>
                <Grid item xs={12} align="center" style={{ marginTop: 10 }}>
                  {progress ? <CircularProgress /> : ""}
                </Grid>

                <Grid item xs={12} style={{ marginTop: 0 }}>
                  {loginError === "" ? (
                    ""
                  ) : (
                    <p className="login-signup-error">{loginError}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Create Account
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default LoginForm;
