import {
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContextEntry from "./ContextEntry";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "10px",
    paddingRight: "20px",
  },
  theme_header_caption: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    fontSize: "normal",
    fontWeight: "600",
    marginBottom: "10px",
  },
  theme_context_caption: {
    color: "gray",
    display: "flex",
    alignItems: "center",
    // fontSize: "0.75em",
    fontWeight: "normal",
    marginBottom: "-5px",
    marginLeft: "15px",
    marginTop: "25px",
  },
  theme_name: {
    marginLeft: "15px",
  },
}));

const ContextEditor = ({
  themeKey,
  callbackDeleteTheme,
  callbackUpdateTheme,
}) => {
  const classes = useStyles();
  const userContexts = useSelector((state) => state.users.contexts);
  const contextTheme =
    themeKey === "KG_ANNOTATION_THEME_NEW"
      ? userContexts["KG_ANNOTATION_THEME_DEFAULT"]
      : userContexts[themeKey];

  const [customTheme, setCustomTheme] = useState(contextTheme);

  useEffect(() => {
    if (themeKey === "KG_ANNOTATION_THEME_NEW")
      setCustomTheme({
        ...userContexts["KG_ANNOTATION_THEME_DEFAULT"],
        name: "Default (copy)",
      });
    else {
      setCustomTheme(userContexts[themeKey]);
    }
  }, [themeKey]);
  useEffect(() => {
    callbackUpdateTheme({
      addNew: themeKey === "KG_ANNOTATION_THEME_NEW" ? true : false,
      theme: customTheme,
    });
  }, [customTheme]);

  const handleThemeNameChange = (event) => {
    const newName = event.target.value;
    setCustomTheme({ ...customTheme, name: newName });
  };

  const handleThemeContextNameChange = (data) => {
    setCustomTheme({
      ...customTheme,
      contexts: customTheme.contexts.map((ctx) => {
        if (ctx.contextId === data.contextId) {
          return { ...ctx, label: data.name };
        } else return ctx;
      }),
    });
  };

  const handleThemeContextColorChange = (data) => {
    setCustomTheme({
      ...customTheme,
      contexts: customTheme.contexts.map((ctx) => {
        if (ctx.contextId === data.contextId) {
          return {
            ...ctx,
            colorId: data.colorId,
          };
        } else return ctx;
      }),
    });
  };

  const handleClickDeleteThemeButton = () => {
    callbackDeleteTheme(themeKey);
  };

  if (!customTheme) return <></>;
  return (
    <div className={classes.wrapper}>
      <div>
        {themeKey === "KG_ANNOTATION_THEME_NEW" ? (
          <div className={classes.theme_header_caption}>Create new theme</div>
        ) : (
          <div className={classes.theme_header_caption}>Edit theme</div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={classes.theme_name} style={{ width: "350px" }}>
          <TextField
            value={customTheme.name}
            label="Theme Name"
            fullWidth
            onChange={handleThemeNameChange}
            inputProps={{ maxLength: 30 }}
            required
            // disabled={themeKey === "KG_ANNOTATION_THEME_DEFAULT" ? true : false}
          />
        </div>
        {themeKey === "KG_ANNOTATION_THEME_DEFAULT" ? (
          <div>
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={16}>Default theme cannot be deleted</Typography>}
              enterDelay={1000}
              leaveDelay={200}
              enterNextDelay={500}
            >
              <IconButton>
                <LockIcon style={{ color: "lightgray" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          ""
        )}
        {themeKey !== "KG_ANNOTATION_THEME_DEFAULT" &&
        themeKey !== "KG_ANNOTATION_THEME_NEW" ? (
          <div>
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={16}>Delete theme</Typography>}
              enterDelay={1000}
              leaveDelay={200}
              enterNextDelay={500}
            >
              <IconButton onClick={handleClickDeleteThemeButton}>
                <DeleteIcon style={{ color: "salmon" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classes.theme_context_caption}>Theme contexts</div>
      <div className={classes.context_entries}>
        {customTheme.contexts.map((ctx, i) => (
          <ContextEntry
            context={ctx}
            key={i}
            handleThemeContextNameChange={handleThemeContextNameChange}
            handleThemeContextColorChange={handleThemeContextColorChange}
          />
        ))}
      </div>
      {/* {themeKey !== "KG_ANNOTATION_THEME_DEFAULT" &&
      themeKey !== "KG_ANNOTATION_THEME_NEW" ? (
        <div>
          <div
            style={{
              backgroundColor: "#fdd",
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              marginLeft: "15px",
              padding: "5px",
            }}
          >
            ,
            <IconButton onClick={handleClickDeleteThemeButton}>
              <DeleteIcon style={{ color: "salmon" }} />
            </IconButton>
            Delete Theme
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default ContextEditor;
