import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { IconButton, Switch } from "@material-ui/core";
import AlertDialog from "../../UIWidgets/AlertDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeUser } from "../../../store/SliceUsers";
import { makeStyles } from "@material-ui/core";
import DataTable from "react-data-table-component";

const useStyles = makeStyles((theme) => ({
  grid_controls: {
    display: "flex",
    flexDirection: "flex-end",
    // padding: "5px",
    width: "100%",
  },
}));
const AdminUsersGridView = ({ users, guest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataColumns, setDataColumns] = React.useState([]);
  const [dataRows, setDataRows] = React.useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [userToDelete, setUsersToDelete] = React.useState([]);
  const [userDeletionEnabled, setUserDeletionEnabled] = React.useState(false);

  React.useEffect(() => {
    if (users.length > 0) {
      const sample = users[0];
      var keys = Object.keys(sample);
      var columns = [];
      keys.forEach((k) => {
        if (k === "id") {
        } else if (k === "created") {
          columns.push({
            field: "created",
            headerName: "Created",
            sortable: true,
            width: 200,
            name: "Date Created",
            selector: (row) => row.created,
            sortFunction: (rowA, rowB) => {
              const a = rowA.utc;
              const b = rowB.utc;

              if (a > b) {
                return 1;
              }

              if (b > a) {
                return -1;
              }

              return 0;
            },
          });
        } else if (k === "docs") {
          columns.push({
            field: "docs",
            headerName: "Num. Docs",
            sortable: true,
            type: "number",
            width: 200,
            name: "Num Docs",
            selector: (row) => row.docs,
          });
        } else {
          columns.push({
            field: k,
            headerName: k,
            sortable: true,
            width: 200,
            name: k,
            selector: (row) => row[k],
          });
        }
      });

      // Move the field "name" to the beginning of the array
      var itemIndex = columns.findIndex(
        (item) => item.hasOwnProperty("field") && item["field"] == "name"
      );
      if (itemIndex >= 0) {
        var itemObj = columns.splice(itemIndex, 1);

        if (itemObj.length === 1) {
          itemObj[0]["headerName"] = "Name";
          columns.unshift(itemObj[0]);
        }
      }

      // Add cell at the end for deleting the row
      columns.push({
        field: "actions",
        headerName: "Actions",
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => prepareToDeleteUser(params.row.id)}
              style={{ color: userDeletionEnabled ? "salmon" : "lightgray" }}
              disabled={!userDeletionEnabled}
            >
              {params.row.email === "sid@kg.com" ? "" : <DeleteIcon />}
            </IconButton>
          );
        },
        cell: (row) => (
          <IconButton
            onClick={() => prepareToDeleteUser(row.id)}
            style={{ color: userDeletionEnabled ? "salmon" : "lightgray" }}
            disabled={!userDeletionEnabled}
          >
            <DeleteIcon />
          </IconButton>
        ),
        button: true,
      });

      var rows = [];
      users.forEach((u) => {
        if (!guest && !u.is_guest) {
          rows.push({
            ...u,
            utc: Date.parse(u.created) || -1,
            docs: u.docs && u.docs.length > 0 ? u.docs.length : 0,
            logins:
              u.logins && u.logins.length > 0
                ? u.logins[u.logins.length - 1]
                : "",
          });
        } else if (guest && u.is_guest) {
          rows.push({
            ...u,
            docs: u.docs && u.docs.length > 0 ? u.docs.length : 0,
            logins:
              u.logins && u.logins.length > 0
                ? u.logins[u.logins.length - 1]
                : "",
          });
        }
      });

      setDataColumns(columns);
      guest && console.log(guest, rows);
      // Default Sort: based on number of documents
      rows.sort((a, b) => b.docs - a.docs);
      setDataRows(rows);
    }
  }, [users]);

  React.useEffect(() => {
    if (users.length <= 0) setShowProgress(true);
    else setShowProgress(false);
  }, [users]);
  const callbackCancel = () => {
    setShowDeleteDialog(false);
  };
  const callbackProceed = (data) => {
    setShowDeleteDialog(false);
    handleDeleteUser();
  };
  const prepareToDeleteUser = (userId) => {
    setUsersToDelete(userId);
    setShowDeleteDialog(true);
  };
  const handleDeleteUser = () => {
    dispatch(removeUser({ id: userToDelete })).then((resp) => {
      // console.log("User deleted: ", resp);
    });
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      {/* <div>
        <Switch
          {...label}
          onChange={() => setUserDeletionEnabled(!userDeletionEnabled)}
        />
        &nbsp;Enable user deletion
      </div> */}
      {dataRows.length > 0 ? (
        <Box sx={{ height: "80vh", width: "100%" }}>
          {/* <DataGrid
            rows={dataRows}
            columns={dataColumns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection={false}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          /> */}
          <DataTable columns={dataColumns} data={dataRows} />
        </Box>
      ) : (
        ""
      )}
      <AlertDialog
        callbackCancel={callbackCancel}
        callbackProceed={() => callbackProceed(userToDelete)}
        contentText={
          `The following user and their data will be deleted permanently. Proceed with caution! ` +
          `${userToDelete}`
        }
        openDialog={showDeleteDialog}
        proceedButtonText={"Remove User"}
        title={"Remove User"}
      />
    </>
  );
};

export default AdminUsersGridView;
