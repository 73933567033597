import React, { useEffect } from "react";
import { scaleLinear } from "d3";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px",
  },
  doc_name: {
    color: "#333333",
    fontSize: "small",
    fontWeight: "600",
  },
  term_pills_container: {
    // border: "1px solid salmon",
    display: "flex",
    alignItems: "top",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  term_pill: {
    backgroundColor: "#f3f3f3",
    color: "white",
    borderRadius: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    margin: "3px",
    padding: "2px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  term_label: {},
  term_count: {
    fontSize: "0.9em",
    marginLeft: "8px",
  },
}));

const DocTermVis = ({ data, docName, max, numTermsToShow, showFileName }) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const topNTerms = data.slice(
    0,
    numTermsToShow || globalTheme.kg_ui.doc_data.maxTagPillTerms
  );

  // topNTerms.sort((a, b) => {
  //   if (a.n > b.n) return -1;
  //   else if (a.n < b.n) return 1;
  //   else return 0;
  // });
  const maxCount = Math.max.apply(
    Math,
    topNTerms.map((el) => el.n)
  );
  const minCount = Math.min.apply(
    Math,
    topNTerms.map((el) => el.n)
  );

  const entryFontSizeFunc = scaleLinear()
    // .domain([minCount, maxCount])
    .domain([0, max])
    .range([0.8, 1.2]);

  const entryFontColorFunc = scaleLinear()
    .domain([minCount, maxCount])
    .range(["#998899", "#880066"]);
  const fileNameLength = 40;

  return (
    <div className={classes.wrapper}>
      {!showFileName ? (
        ""
      ) : (
        <div className={classes.doc_name} title={docName}>
          {docName.length > fileNameLength
            ? docName.substring(0, fileNameLength) + ".. .pdf"
            : docName}
        </div>
      )}
      <div className={classes.term_pills_container}>
        {topNTerms.map((el, i) => {
          return (
            <div
              key={i}
              className={classes.term_pill}
              style={{ color: `${entryFontColorFunc(el.n)}` }}
              title={`'${el.t}' occurs ${el.n} times`}
            >
              <span
                className="doc-term-vis-term-label"
                style={{ fontSize: `${entryFontSizeFunc(el.n)}rem` }}
              >
                {el.t}
              </span>
              <span
                className={classes.term_count}
                style={{ fontSize: "0.8em" }}
              >
                {el.n}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocTermVis;
