import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SortByCountIcon from "@mui/icons-material/Sort";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { useEffect, useState } from "react";
import HorizontalBarChart from "../Visualizations/HorizontalBarChart";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  keyword_vis_content_wrapper: {
    display: "flex",
    alignContent: "top",
    justifyContent: "space-between",
  },
  keyword_vis_title: {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
  },
  dialog_actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  keyword_vis_content: {
    padding: "20px",
  },
  keyword_vis_content_header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  keyword_vis_sort_options: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}));
const DocumentQuickView = ({
  doc,
  contentText,
  cancelButtonText,
  callbackCancel,
  callbackProceed,
  openDialog,
  title,
}) => {
  const classes = useStyles();
  const [docWordFreq, setDocWordFreq] = useState(
    doc.metadata.docData && doc.metadata.docData.word_freq
      ? [...doc.metadata.docData.word_freq]
      : []
  );
  const [sortBy, setSortBy] = useState({
    type: "KG_SORT_KEYWORDS_BY_COUNT",
    sortDirnAscending: false,
  });
  const [sortDirnAscending, setSortDirnAscending] = useState(false);
  const wordFreqMax = Math.max.apply(
    Math,
    docWordFreq.map(function (o) {
      return o.n;
    })
  );
  const wordFreqMin = Math.min.apply(
    Math,
    docWordFreq.map(function (o) {
      return o.n;
    })
  );

  useEffect(() => {
    const tempWordFreq = [...docWordFreq];
    if (sortBy.type === "KG_SORT_KEYWORDS_BY_COUNT") {
      tempWordFreq.sort((a, b) => {
        if (a.n > b.n) return sortBy.sortDirnAscending ? 1 : -1;
        else if (a.n < b.n) return sortBy.sortDirnAscending ? -1 : 1;
        return 0;
      });
    } else if (sortBy.type === "KG_SORT_KEYWORDS_BY_TERM") {
      tempWordFreq.sort((a, b) => {
        if (a.t > b.t) return sortBy.sortDirnAscending ? 1 : -1;
        else if (a.t < b.t) return sortBy.sortDirnAscending ? -1 : 1;
        return 0;
      });
    }
    setDocWordFreq(tempWordFreq);
  }, [sortBy.type, sortBy.sortDirnAscending]);

  const handleSortByCount = () => {
    setSortBy({
      type: "KG_SORT_KEYWORDS_BY_COUNT",
      sortDirnAscending: !sortBy.sortDirnAscending,
    });
  };

  const handleSortByTerm = () => {
    setSortBy({
      type: "KG_SORT_KEYWORDS_BY_TERM",
      sortDirnAscending: !sortBy.sortDirnAscending,
    });
  };

  const KeywordVisPlot = ({ docWordFreq }) => {
    const threshold = wordFreqMin;
    return (
      <div className="chartContainer">
        <HorizontalBarChart
          title={`${docWordFreq.length} terms with ${threshold} or more occurrences`}
          width={600}
          height={docWordFreq.length * 20}
          margin={{
            left: 5,
            top: 20,
            right: 50,
            bottom: 10,
            labelLength: 125,
          }}
          style={{
            fill: "#bbb",
          }}
          data={docWordFreq}
          maxVal={wordFreqMax}
          minVal={wordFreqMin}
        />
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.keyword_vis_title}>
            <Typography style={{ fontSize: "2em" }}>
              Terms & Frequencies: {doc.name.length > 32 ? doc.name.substr(0, 32) + ".." : doc.name}
            </Typography>
            <div className={classes.dialog_actions}>
              <IconButton onClick={callbackCancel}>
                <CloseIcon style={{ fontSize: "2em" }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.keyword_vis_content_wrapper}>
            <div className={classes.keyword_vis_content}>
              <div className={classes.keyword_vis_content_header}>
                <Typography variant="h6">
                  Document Terms and their Frequencies
                </Typography>
                <div className={classes.keyword_vis_sort_options}>
                  <div>Sort terms &nbsp;</div>
                  <div>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <Typography fontSize={16}>
                          Sort keywords by count
                        </Typography>
                      }
                      enterDelay={1000}
                      leaveDelay={200}
                      enterNextDelay={500}
                    >
                      <IconButton
                        className={classes.svg_icons}
                        onClick={handleSortByCount}
                      >
                        <SortByCountIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <Typography fontSize={16}>
                          Sort keywords alphabetically
                        </Typography>
                      }
                      enterDelay={1000}
                      leaveDelay={200}
                      enterNextDelay={500}
                    >
                      <IconButton
                        className={classes.svg_icons}
                        onClick={handleSortByTerm}
                      >
                        <SortByAlphaIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div>
                {docWordFreq && docWordFreq.length > 0 ? (
                  <KeywordVisPlot docWordFreq={docWordFreq} />
                ) : docWordFreq.length <= 0 ? (
                  "No words found with frequency more than or equal to 10."
                ) : (
                  "Document keyword frequency data not available."
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentQuickView;
