import React from "react";
import ReactDOM from "react-dom";
// import * as ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import App from "./App";
import "@fontsource/montserrat";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";


// window.pdfjs = require("pdfjs-dist/build/pdf");
// window.pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// const pdfjsWorker = require("pdfjs-dist/webpack");
// window.pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
