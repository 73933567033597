import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FolderAPI from "../api/FolderAPI";

const initialState = {
  folders: [],
  selectedFolders: [],
  isFileOrFolderOperationActive: false,
};

export const addDocsToNewDefaultFolder = createAsyncThunk(
  "folder/add-to-default-folder",
  async (data) => {
    var res = await FolderAPI.folderApiUploadFilesToNewDefaultFolder(data);
    return res.data;
  }
);

export const addDocsToAFolder = createAsyncThunk(
  "folder/add-to-default-folder",
  async (data) => {
    var res = await FolderAPI.folderApiUploadFilesToAFolder(data);
    return res.data;
  }
);

export const changeFolderName = createAsyncThunk(
  "folder/change-name",
  async (data) => {
    var res = await FolderAPI.folderApiChangeFolderName(data);
    return res.data;
  }
);

export const createFolderAndUploadFiles = createAsyncThunk(
  "folder/create",
  async (data) => {
    const res = await FolderAPI.folderApiUploadFilesToNewFolder(data);
    return res.data;
  }
);

export const getAllFolders = createAsyncThunk("folder/get_all", async () => {
  const res = await FolderAPI.folderApiGetAllFolders();
  return res.data;
});

export const removeFoldersByIds = createAsyncThunk(
  "folder/remove_by_ids",
  async (data) => {
    const res = await FolderAPI.folderApiRemoveFoldersByIds(data);
    return res.data;
  }
);
export const removeAllFolders = createAsyncThunk(
  "folder/remove_all",
  async () => {
    const res = await FolderAPI.folderApiRemoveAllFolders();
    return res.data;
  }
);

export const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    initFolders: (state, action) => {
      state = initialState;
      return state;
    },
    addFolder: (state, action) => {
      const folderExists = state.folders.find(
        (f) => f._id === action.payload._id
      );
      if (!folderExists) state.folders.push(action.payload);
    },
    removeFolder: (state, action) => {
      state.folders = state.folders.filter(
        (d) => d._id !== action.payload.folderId
      );
      return state;
    },
    setSelectedFolder: (state, action) => {
      const folderId = action.payload.folderId;
      state.selectedFolders.push(folderId);
      return state;
    },
    removeSelectedFolder: (state, action) => {
      const folderId = action.payload.folderId;
      state.selectedFolders = state.selectedFolders.filter(
        (f) => f !== folderId
      );
      return state;
    },
    resetSelectedFolders: (state, action) => {
      state.selectedFolders = [];
      return state;
    },
    setFileOrFolderOperationsInactive: (state, action) => {
      state.isFileOrFolderOperationActive = false;
    },
    setFileOrFolderOperationsActive: (state, action) => {
      state.isFileOrFolderOperationActive = true;
    },
  },
  extraReducers: {
    [addDocsToAFolder.fulfilled]: (state, action) => {
      return state;
    },
    [getAllFolders.fulfilled]: (state, action) => {
      action.payload.folders.forEach((f) => {
        const found = state.folders.find((d) => d._id === f._id);
        if (!found) state.folders.push(f);
      });
      // state.folders = [...state.folders, ...action.payload.folders];
    },
    [removeAllFolders.fulfilled]: (state, action) => {
      state.folders = [];
      return state;
    },
    [removeFoldersByIds.fulfilled]: (state, action) => {
      const foldersToDelete = action.payload.deletedFolderIds;
      foldersToDelete.forEach((fId) => {
        state.folders = state.folders.filter((f) => f._id !== fId);
      });
      return state;
    },
    [changeFolderName.fulfilled]: (state, action) => {
      // appEvents.debugLog("FolderSlice", "folder name change " + action.payload);
      const folderData = action.payload.data;
      if (folderData) {
        const found = state.folders.find((d) => d._id === folderData.id);
        if (found) {
          found.name = folderData.name;
        }
      }
      return state;
    },
    [createFolderAndUploadFiles.fulfilled]: (state, action) => {
      return state;
    }
  },
});

export const {
  initFolders,
  addFolder,
  removeFolder,
  setFileOrFolderOperationsInactive,
  setFileOrFolderOperationsActive,
  setSelectedFolder,
  removeSelectedFolder,
  resetSelectedFolders,
} = foldersSlice.actions;
export default foldersSlice.reducer;
