import {
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import ContextEditorIcon from "@mui/icons-material/TocRounded";
import InfoIcon from "@mui/icons-material/HelpOutline";
import DocAnnotationsView from "../Annotations/DocAnnotationsView";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#fff",
    // border: "1px solid green",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "5",
    height: "82vh",
    width: "26vw",
  },
  annotations: {
    height: "97%",
    overflowY: "auto",
    padding: "10px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "5px",
    width: "95%",
  },
}));
const NotesPanel = ({
  docId,
  deleteHighlight,
  themeColors,
  themeKey,
  visitHighlight,
}) => {
  const classes = useStyles();
  const globalTheme = useTheme();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div>Annotation help &nbsp;</div>
        <Tooltip
          arrow
          placement="bottom"
          title={
            <Typography fontSize={16}>
              <strong>Create Contextual Annotations</strong>
              <br />
              <br />
              <strong>Text annotations</strong>
              <br />{" "}
              <span style={{ color: "#ffff00" }}>
                Highlight text by clicking and dragging with mouse,
              </span>{" "}
              then choose a context from the pop up menu that appears after
              selection is done.
              <br />
              <br />
              <strong>Image annotations</strong> <br />
              <span style={{ color: "#ffff00" }}>
                Capture an image snippet by pressing ALT or Command key + clicking and
                dragging with mouse,
              </span>{" "}
              then choose a context from the pop up menu that appears after
              selection is done.
              <br />
              <br />
              The context labels and colors can be customized. Press the{" "}
              <ContextEditorIcon /> icon in the application navigation panel on
              the left to view the context theme editor.
            </Typography>
          }
          enterDelay={500}
          leaveDelay={200}
          enterNextDelay={500}
        >
          <InfoIcon
            fontSize="larger"
            style={{
              color: globalTheme.palette.primary.main,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </div>
      <div className={classes.annotations}>
        <DocAnnotationsView
          docId={docId}
          deleteHighlight={deleteHighlight}
          themeColors={themeColors}
          themeKey={themeKey}
          visitHighlight={visitHighlight}
        />
      </div>
    </div>
  );
};

export default NotesPanel;
