import {
  Checkbox,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../store/GlobalStateSlice";
import { updateUserSettings } from "../../../store/SliceUsers";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "25px",
    width: "300px",
  },
  panelHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  sectionHeader: {
    color: theme.palette.greyscale.main,
  },
  settingItem: {
    alignItems: "start",
    display: "flex",
    paddingTop: "5px",
  },
}));
const UserSettings = ({ callbackClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => state.users.preferences);
  const docOptions = useSelector(
    (state) => state.users.preferences.docOptions || false
  );
  const handleCloseSettingsPanel = (event) => {
    callbackClose();
  };
  const handleRightClickAnnotation = (event) => {
    dispatch(
      updateUserSettings({
        settings: {
          ...userSettings,
          docOptions: {
            ...userSettings.docOptions,
            rightClickContextMenu: event.target.checked,
          },
        },
      })
    ).then(() => {
      dispatch(
        setNotification({
          type: "KG_EVENT_SUCCESS",
          message: "The setting was saved!",
        })
      );
    });
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.panelHeader}>
        <div>
          <h3>Settings</h3>
        </div>
        <div>
          <IconButton onClick={handleCloseSettingsPanel}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div>&nbsp;</div>
      <div className={classes.sectionHeader}>Annotations</div>
      <div className={classes.settingItem}>
        <Checkbox
          checked={docOptions.rightClickContextMenu || false}
          onChange={handleRightClickAnnotation}
          style={{ paddingTop: "0px" }}
          disableRipple
        />
        <div>Open annotation context menu on mouse right click</div>
        <div>
          <Tooltip
            arrow
            placement="right"
            title={
              <Typography fontSize={16}>
                If checked, the annotation context menu in documents appears
                when you right-click highlighted text. <br />
                <br />
                Uncheck if you want the menu to appear as soon as text selection
                is completed.
              </Typography>
            }
            enterDelay={10}
            leaveDelay={200}
            enterNextDelay={500}
          >
            <InfoIcon className={""} />
          </Tooltip>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default UserSettings;
