import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckboxTree from "react-checkbox-tree";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFileLines,
  faFolder,
  faFolderOpen,
  faMinusSquare,
  faSquare,
} from "@fortawesome/free-regular-svg-icons";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./style/DocsAndFoldersSelector.css";

const useStyles = makeStyles((theme) => ({}));

const DocsAndFolderSelector = ({
  callbackCancel,
  callbackSubmit,
  contextKey,
  cancelButtonText,
  openDialog,
  proceedButtonText,
  title,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const globalTheme = useTheme();
  var folders = useSelector((state) => state.folders.folders);
  var allDocs = useSelector((state) => state.docs.docs);
  const [folderList, setFolderList] = useState([]);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const [sortDirn, setSortDirn] = useState(true);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    var nodes = [];
    nodes = folders.map((f, fi) => ({
      id: f._id,
      label: f.name,
      value: `folder-${fi}`,
      children: [],
    }));

    nodes.forEach((folder) => {
      var di = 0;
      allDocs.forEach((doc) => {
        if (doc._folder === folder.id) {
          folder.children.push({
            id: doc._id,
            value: `${doc._id}`,
            label: doc.name,
          });
          di += 1;
        }
      });
    });

    // Sorting options
    nodes.sort((a, b) => {
      if (a.label > b.label) return sortDirn ? 1 : -1;
      else if (a.label < b.label) return sortDirn ? -1 : 1;
      return 0;
    });
    setFolderList(nodes);
  }, [allDocs, folders]);

  const handleCheckboxTreeEvent = (event) => {
    // console.log(event);
    setChecked(event);
  };

  const submitSelection = () => {
    callbackSubmit(checked);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus={true}
        scroll="paper"
      >
        <div style={{ padding: "10px" }}>
          <Typography variant="h6">{title}</Typography>
        </div>

        <DialogContent dividers>
          <div style={{ width: "100%" }}>
            <CheckboxTree
              nodes={folderList}
              checked={checked}
              expanded={expanded}
              onCheck={handleCheckboxTreeEvent}
              onExpand={(expanded) => setExpanded(expanded)}
              icons={{
                check: (
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                uncheck: (
                  <FontAwesomeIcon
                    icon={faSquare}
                    style={{ color: globalTheme.palette.greyscale.main }}
                  />
                ),
                halfCheck: (
                  <FontAwesomeIcon
                    icon={faMinusSquare}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                expandClose: (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                expandOpen: (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                expandAll: (
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                collapseAll: (
                  <FontAwesomeIcon
                    icon={faMinusSquare}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                parentClose: (
                  <FontAwesomeIcon
                    icon={faFolder}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                parentOpen: (
                  <FontAwesomeIcon
                    icon={faFolderOpen}
                    style={{ color: globalTheme.palette.primary.main }}
                  />
                ),
                leaf: (
                  <FontAwesomeIcon
                    icon={faFileLines}
                    style={{ color: globalTheme.palette.greyscale.light }}
                  />
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={callbackCancel}
            variant="outlined"
            autoFocus
            color="secondary"
          >
            {cancelButtonText || "Cancel"}
          </Button>
          <Button
            onClick={submitSelection}
            variant="contained"
            color="secondary"
            disabled={!saveButtonEnabled}
            autoFocus
          >
            {proceedButtonText}
            {/* {selectedThemeKey === "KG_ANNOTATION_THEME_NEW" ? "Create Theme" : "Save and Close"} */}
            {/* {proceedButtonText || "Save"} */}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocsAndFolderSelector;
