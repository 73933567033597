import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "top",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  callout: {
    // backgroundColor: theme.palette.primary.lightest,
    backgroundColor: "#fafafa",
    border: "1px dashed #ccc",
    borderRadius: "8px",
    // boxShadow: "0px 2px 8px #bbb",
    color: "black",
    fontWeight: "600",
    padding: "13px",
  },
  emphasize: {
    fontWeight: "600",
  },
  headerWrapper: {},
  textPrimary: {
    color: theme.palette.primary.darker,
    fontWeight: "600",
  },
}));
const SectionText = ({ body }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <div
          className={
            body.titleAlignment === "left"
              ? classes.alignLeft
              : classes.alignRight
          }
        >
          <h4>
            <span className={classes.emphasize}>{body.titlePart1}</span>&nbsp;
            {body.titlePart2}
          </h4>
        </div>
      </div>
      <br />
      <div>
        <div className={classes.textPrimary}>{body.textPrimary}</div>
        <p>{body.textSecondary}</p>
        {body.textBulletList && body.textBulletList.length > 0 ? (
          <ul>
            {body.textBulletList.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        ) : (
          ""
        )}
        <br />
        {body.textTertiary ? (
          <div className={classes.callout}>{body.textTertiary}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default SectionText;
