import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ResetZoomIcon from "@mui/icons-material/Restore"
import { useEffect, useRef } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // borderRadius: "4px",
    backgroundColor: theme.palette.type === "dark" ? "#444" : "#f0f0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  zoomLevelPct: {
    // backgroundColor: "#fafafa",
    paddingLeft: "3px",
    paddingRight: "3px",
    textAlign: "center",
    width: "40px",
  },
  svg_icon: {
      color: theme.palette.primary.dark,
  },
}));

const DocumentActions = ({ doc, callbackSetZoomLevel }) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  var PDF_DOC_SCALE = useRef();
  useEffect(() => {
    PDF_DOC_SCALE.current = 1.25;
  }, []);

  const handlePDFZoomIn = () => {
    PDF_DOC_SCALE.current += globalTheme.kg_ui.doc_view.zoom_level_delta;
    if (PDF_DOC_SCALE.current >= globalTheme.kg_ui.doc_view.zoom_level_max)
      PDF_DOC_SCALE.current = globalTheme.kg_ui.doc_view.zoom_level_max;

    callbackSetZoomLevel(PDF_DOC_SCALE.current);
  };

  const handlePDFZoomOut = () => {
    PDF_DOC_SCALE.current -= globalTheme.kg_ui.doc_view.zoom_level_delta;
    if (PDF_DOC_SCALE.current <= globalTheme.kg_ui.doc_view.zoom_level_min)
      PDF_DOC_SCALE.current = globalTheme.kg_ui.doc_view.zoom_level_min;

    callbackSetZoomLevel(PDF_DOC_SCALE.current);
  };
  return (
    <div className={classes.wrapper}>
      {/* <Tooltip
        arrow
        enterDelay={1000}
        enterNextDelay={200}
        leaveDelay={200}
        placement="bottom"
        title={<Typography fontSize={16}>Zoom in</Typography>}
      > */}
        <IconButton className={classes.svg_icon} onClick={handlePDFZoomOut}>
          <ZoomOutIcon />
        </IconButton>
      {/* </Tooltip> */}
      <div className={classes.zoomLevelPct}>{100 * PDF_DOC_SCALE.current}%</div>
      {/* <Tooltip
        arrow
        enterDelay={1000}
        enterNextDelay={200}
        leaveDelay={200}
        placement="bottom"
        title={<Typography fontSize={16}>Zoom out</Typography>}
      > */}
        <IconButton className={classes.svg_icon} onClick={handlePDFZoomIn}>
          <ZoomInIcon />
        </IconButton>
      {/* </Tooltip> */}
    </div>
  );
};

export default DocumentActions;
