import React from "react";
import StaticPageSection from "./components/StaticPageSection";
import StaticSiteSection from "./components/StaticPageSection";
import { makeStyles } from "@material-ui/core";
import PageHeader from "./components/PageNavBar";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#f3f3f3",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "top",
    width: "100%",
  },
}));
const MainPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <PageHeader title={"Knowledge Garden"} />

      <StaticPageSection />
    </div>
  );
};

export default MainPage;
