import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

function FolderName({name, cbSetFolderName}) {
    const [folderName, setFolderName] = useState(name);
    const handleFolderNameChange = (event) => {
        setFolderName(event.target.value);
    }
    useEffect(() => {setFolderName(name)}, [name])
  return (
    <div>
      <TextField
        InputProps={{ disableUnderline: true }}
        onBlur={() => cbSetFolderName(folderName)}
        onChange={handleFolderNameChange}
        value={folderName}
        fullWidth
      />
    </div>
  );
}

export default FolderName;
