import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ReferencesCard from "./ReferenceCard";
const graphicReportHelp = require("../../../assets/design/ui/doc-view-report.png");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fff",
    // border: "1px solid #cccccc",
    boxShadow: "0px 1px 5px #e9e9e9",
    borderRadius: "4px",
    margin: "5px",
    marginBottom: "15px",
    // padding: "10px",
    width: "93%",
  },
  header: {
    padding: "10px",
  },
  reference_container: {
    alignItems: "top",
    display: "flex",
    justifyContent: "flex-start",
  },
  reference_fav_button: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
  reference_panel: {
    borderTop: "1px solid #f0f0f0",
    height: "67vh",
    overflow: "scroll",
    overflowX: "hidden",
    marginLeft: "10px",
    marginTop: "10px",
    padding: "5px",
    width: "93%",
  },
}));
const ReferencesList = ({
  docId,
  callbackSetFavoriteReference,
  numRefs,
  references,
  updatingRef,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.reference_panel}>
      {numRefs > 0
        ? references.map((ref, i) => (
            <div className={classes.reference_container} key={i}>
              <div>
                <ReferencesCard
                  bibitem={ref}
                  callbackSetFavRef={callbackSetFavoriteReference}
                  docId={docId}
                  refId={i}
                  updating={
                    updatingRef > -1 && updatingRef === i ? true : false
                  }
                />
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

export default ReferencesList;
