import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/logo-kg-1.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "white",
    alignItems: "top",
    boxShadow: "0px 5px 5px #ddd",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "sticky",
    top: "0",
    width: "100%",
    zIndex: "3",
  },
  title: {
    backgroundColor: "#fff",
    color: theme.palette.primary.darker,
    cursor: "pointer",
    fontSize: "1.25em",
    padding: "5px",
    width: "100%",
  },
  logo: {},
}));

const PageHeader = ({ title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleNavigateToLandingPage = () => {
    navigate("/");
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <img
          src={logo}
          style={{ padding: 5, height: 40, width: 40, cursor: "pointer" }}
          onClick={handleNavigateToLandingPage}
          alt=""
        />
      </div>
      <div className={classes.title} onClick={handleNavigateToLandingPage}>
        {title}
      </div>
    </div>
  );
};

export default PageHeader;
