import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import SortIcon from "@mui/icons-material/SortByAlpha";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FolderContainer from "../Views/Folders/FolderContainer";
import DocumentListMenu from "./DocumentListMenu";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    height: "76vh",
    overflowY: "auto",
  },
  docs_list_controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  doc_list: {
    overflowY: "auto",
  },
  empty_library_message: {
    backgroundColor: "#eeeeee",
    border: "1px solid #d9d9d9",
    borderRadius: "8px",
    boxShadow: "0px 0px 18px #dddddd",
    color: "#333333",
    margin: "15px",
    padding: "20px",
  },
  folder_options: {
    width: "60px",
  },
  svg_icons: {
    "& svg": {
      fill: "#aaa",
      fontSize: 30,
    },
    "& svg:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));
const DocumentList = ({ allDocs, folders }) => {
  const classes = useStyles();
  var folders = useSelector((state) => state.folders.folders);
  var allDocs = useSelector((state) => state.docs.docs);
  const selectedFolders = useSelector((state) => state.folders.selectedFolders);
  const [sortDirn, setSortDirn] = useState(true);
  const [folderList, setFolderList] = useState([]);

  var tempFolderList = [];
  useEffect(() => {
    tempFolderList = folders.map((f) => ({
      ...f,
      docs: [],
    }));

    tempFolderList.forEach((folder) => {
      var tempDocsList = [...allDocs];

      tempDocsList.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        return 0;
      });
      tempDocsList.forEach((doc) => {
        if (doc._folder === folder._id) {
          folder.docs.push(doc);
        }
      });
    });

    // Sorting options
    tempFolderList.sort((a, b) => {
      if (a.name > b.name) return sortDirn ? 1 : -1;
      else if (a.name < b.name) return sortDirn ? -1 : 1;
      return 0;
    });
    setFolderList(tempFolderList);
  }, [allDocs, folders, sortDirn]);

  const EmptyLibraryMessage = () => {
    return (
      <div className={classes.empty_library_message}>
        <p>
          <WarningIcon style={{ color: "salmon" }} /> Your library is empty. Add
          PDF documents by clicking the folder or document icons above.
        </p>
        <hr />
        <p>
          Download sample PDF files and then come back add them to the Library
          here -{" "}
          <a href="https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf">
            File 1
          </a>{" "}
          <a
            href="http://geoanalytics.net/and/papers/VA-vessels20.pdf"
            target={"_blank"}
          >
            File 2
          </a>
        </p>
      </div>
    );
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.docs_list_controls}>
        <DocumentListMenu docs={allDocs} folders={folders} />
        <div className={classes.folder_options}>
          {folders.length > 0 ? (
            <div>
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>Sort folders by name</Typography>
                }
                enterDelay={1000}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <IconButton
                  className={classes.svg_icons}
                  onClick={() => setSortDirn(!sortDirn)}
                >
                  <SortIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>{folderList.length === 0 ? <EmptyLibraryMessage /> : ""}</div>

      <div className={classes.doc_list}>
        {folderList.map((folder, i) => (
          <FolderContainer
            data={folder}
            key={i}
            folderTabId={folder._id}
            forcedChecked={
              selectedFolders.findIndex((f) => f === folder._id) > -1
                ? true
                : false
            }
          />
        ))}
      </div>
    </div>
  );
};

export default DocumentList;
