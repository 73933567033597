/* eslint-disable import/first */
import React, { Component } from "react";
import AddToDoIcon from "@mui/icons-material/NotificationAddOutlined";
import AddToNotesIcon from "@mui/icons-material/ContentCopyOutlined";
import AddToNotesSetIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ContextEditorIcon from "@mui/icons-material/TocRounded";
import QuickSelectIcon from "@mui/icons-material/Bolt";
import SetToDoIcon from "@mui/icons-material/NotificationsActiveRounded";
import StarIcon from "@mui/icons-material/StarRateRounded";
import StarUnsetIcon from "@mui/icons-material/StarOutlineRounded";

import "../style/Tip.css";
import {
  Button,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

interface State {
  compact: boolean;
  contextId: string;
  text: string;
  emoji: string;
  star: boolean;
  todo: boolean;
  copyToNotes: boolean;
}

interface Props {
  onConfirm: (
    comment: {
      text: string;
      emoji: string;
      star: boolean;
      todo: boolean;
      copyToNotes: boolean;
    },
    context: { contextId: string; themeId: string }
  ) => void;
  onOpen: () => void;
  onUpdate?: () => void;
  highlightType: string;
  themeColors: any;
  theme: any;
}

export class Tip extends Component<Props, State> {
  state: State = {
    compact: true,
    contextId: "",
    text: "",
    emoji: "",
    star: false,
    todo: false,
    copyToNotes: false,
  };

  // for TipContainer
  componentDidUpdate(nextProps: Props, nextState: State) {
    const { onUpdate } = this.props;

    if (onUpdate && this.state.compact !== nextState.compact) {
      onUpdate();
    }
  }

  componentDidMount(): void {
    this.props.onOpen();
    this.setState({ compact: false });
  }

  render() {
    const { onConfirm, onOpen } = this.props;
    const { compact, contextId, text, emoji, star, todo, copyToNotes } =
      this.state;

    if (
      !this.props.theme ||
      !this.props.theme.contexts ||
      !this.props.theme.themeId
    )
      return <></>;

    const contexts = this.props.theme.contexts || [];
    const themeId = this.props.theme.themeId;

    return (
      <div className="Tip">
        {compact ? (
          <div
            className="Tip__compact"
            onClick={() => {
              onOpen();
              this.setState({ compact: false });
            }}
          ></div>
        ) : (
          <form
            className="Tip__card"
            onSubmit={(event) => {
              console.log("SUBMITTING");
              event.preventDefault();
              onConfirm(
                { text, emoji, star, todo, copyToNotes },
                { contextId, themeId }
              );
            }}
          >
            <div
              style={{
                display: "flex",
                fontSize: "small",
                justifyContent: "space-between",
                paddingBottom: "3px",
              }}
            >
              <div>Select context for your highlight</div>
              <div>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <Typography fontSize={16}>
                      <strong>Add Context To Annotation</strong>
                      <br />
                      <span style={{ color: "#ffff00" }}>
                        Select a context for your annotation from the list of
                        color-coded context labels. Add optional text comment or
                        follow-up (bell) or favorite (star) flags, then press
                        save.
                      </span>
                      <br />
                      <br />
                      The context labels and colors can be customized. Press the{" "}
                      <ContextEditorIcon /> icon in the application navigation
                      panel on the left to view the context theme editor.
                      <br />
                      <br />
                      <strong>Quick-Select Context</strong> <br />
                      If you don't want to add a comment or set any flags, then
                      click the <QuickSelectIcon /> icon next to the context
                      labels.
                    </Typography>
                  }
                  enterDelay={0}
                  leaveDelay={100}
                  enterNextDelay={500}
                >
                  <InfoIcon style={{ color: "gray" }} />
                </Tooltip>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: "10px",
              }}
            >
              <div>
                {contexts.map((c: any, i: number) => (
                  <div
                    key={i}
                    className="Tip_context_entry"
                    style={{
                      backgroundColor:
                        this.props.themeColors[c.contextId].colorLighter1,
                      borderRadius: "3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "1px",
                    }}
                  >
                    <label
                      key={i}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <input
                          checked={contextId === c.contextId}
                          type="radio"
                          name={c.label}
                          value={c.contextId}
                          onChange={(event) =>
                            this.setState({ contextId: event.target.value })
                          }
                        />
                      </div>
                      <div>{c.label}</div>
                    </label>
                    <div>
                      <Link
                        id={c.contextId}
                        className="Tip_quick_select_context"
                        style={{
                          color: this.props.themeColors[c.contextId].colorMain,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          height: "20px",
                          marginRight: "3px",
                          padding: "3px",
                          width: "20px",
                        }}
                        type="text"
                        underline="none"
                        onClick={(event: any) => {
                          event.preventDefault();
                          const target = event.target as HTMLTextAreaElement;
                          const contextId = target.id;
                          onConfirm(
                            { text, emoji, star, todo, copyToNotes },
                            { contextId, themeId }
                          );
                        }}
                      >
                        <QuickSelectIcon
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <TextField
                  focused={true}
                  multiline
                  placeholder="Optional comment.."
                  rows={4}
                  value={text}
                  variant="filled"
                  onChange={(event) =>
                    this.setState({ text: event.target.value })
                  }
                  ref={(node) => {
                    if (node) node.focus();
                  }}
                />
              </div>
              {/* <div>
                {["💩", "😱", "😍", "🔥", "😳", "⚠️"].map((_emoji) => (
                  <label key={_emoji}>
                    <input
                      checked={emoji === _emoji}
                      type="radio"
                      name="emoji"
                      value={_emoji}
                      onChange={(event) =>
                        this.setState({ emoji: event.target.value })
                      }
                    />
                    {_emoji}
                  </label>
                ))}
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>Set as follow-up</Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton
                    onClick={() => this.setState({ todo: !this.state.todo })}
                    style={{ fontSize: "1.25em" }}
                  >
                    {this.state.todo ? (
                      <SetToDoIcon style={{ color: "#9c27b0" }} />
                    ) : (
                      <AddToDoIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  title={<Typography fontSize={16}>Set as favorite</Typography>}
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton
                    onClick={() => this.setState({ star: !this.state.star })}
                    style={{ fontSize: "1.25em" }}
                  >
                    {this.state.star ? (
                      <StarIcon style={{ color: "#9c27b0" }} />
                    ) : (
                      <StarUnsetIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                {this.props.highlightType === "text" ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <Typography fontSize={16}>
                        {
                          "Turn on to append the highlighted text and your comment to the Notepad when you click Save"
                        }
                      </Typography>
                    }
                    enterDelay={1000}
                    leaveDelay={200}
                    enterNextDelay={500}
                  >
                    <IconButton
                      onClick={() =>
                        this.setState({ copyToNotes: !this.state.copyToNotes })
                      }
                      style={{ fontSize: "1.25em" }}
                    >
                      {this.state.copyToNotes ? (
                        <AddToNotesSetIcon style={{ color: "#9c27b0" }} />
                      ) : (
                        <AddToNotesIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Button
                  disabled={contextId === "" ? true : false}
                  size="medium"
                  value="Save"
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    onConfirm(
                      { text, emoji, star, todo, copyToNotes },
                      { contextId, themeId }
                    );
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default Tip;
