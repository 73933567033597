import { ClassNames } from "@emotion/react";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
const kgLogo = require("../../../assets/logo/logo-kg-1.png");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#efefef",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // width: "100vw",
    height: "100vh",
  },
  content: {
    margin: "10px",
  },
}));
const My404Component = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <img src={kgLogo} width={100}/>
      </div>
      <div className={classes.content}><Typography variant="h5"> Page Not Found!</Typography></div>
      <div className={classes.content}>
        <Link to={"/"}>Go back</Link>
      </div>
    </div>
  );
};

export default My404Component;
