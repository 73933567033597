import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
      },
      "*, *::before, *::after": {
        boxSizing: "inherit",
      },
      body: {
        backgroundColor: theme.palette.type === "dark" ? "#444" : "#fff",
        fontSize: "0.85em"
      },
      "#root": {
      },
      "*::-webkit-scrollbar": {
        width: "0.6em",
        height: "0.5em",
      },
      "*::-webkit-scrollbar-track": {
        background: theme.palette.type === "dark" ? "#666" : "#eee",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor:
          theme.palette.type === "dark" ? "#2f2f2f" : "#9c27b022",
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            theme.palette.type === "dark"
              ? "rgba(0,0,0,.75)"
              : "rgba(0,0,0,.4)",
          width: "0.6em",
          height: "0.5em",
        },
        outline: "0px solid gray",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
