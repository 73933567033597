import { MuiThemeProvider } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import { grey } from "@mui/material/colors";
import OpenSans from "npm-font-open-sans";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AppTopLevelLayout from "./components/Layouts/AppTopLevelLayout";
import IdleTimer from "./components/LoginSignup/IdleTimer";
import LoginForm from "./components/LoginSignup/LoginForm";
import SignupForm from "./components/LoginSignup/SignupForm";
import AlertDialog from "./components/UIWidgets/AlertDialog";
import NotificationSnackbar from "./components/Views/Notification/NotificationSnackbar";
import My404Component from "./components/Views/PageNotFound/My404Component";
import PrivacyPolicy from "./static_site/src/components/PrivacyPolicy";
import TermsOfService from "./static_site/src/components/TermsOfService";
import MainPage from "./static_site/src/MainPage";
import { initAnnotations } from "./store/SliceAnnotations";
import { initDocuments } from "./store/SliceDocuments";
import { initFolders } from "./store/SliceFolders";
import { initUsers } from "./store/SliceUsers";
import GlobalStyles from "./styles/GlobalStyles";

const getGlobalTheme = (darkMode) => {
  return createTheme({
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xs: 0,
        sm: 1000,
        md: 1200,
        lg: 1500,
      },
    },
    palette: {
      primary: {
        main: purple[400],
        light: purple[300],
        lighter: purple[200],
        lightest: purple[50],
        dark: purple[500],
        darker: purple[600],
      },
      secondary: {
        main: "#00897b",
        light: "#26a69a",
        lighter: "#b2dfdb",
        lightest: "#b2dfdb",
      },
      greyscale: {
        main: grey[600],
        light: grey[500],
        lighter: grey[400],
      },
      error: {
        main: "#f44336",
      },
      type: darkMode ? "dark" : "light",
    },
    typography: {
      fontFamily: OpenSans,
    },
    kg_ui: {
      context_theme: {
        colors: [
          {
            id: "KG_CONTEXT_COLOR_GRAY_1",
            name: "gray 1",
            colorMain: "#c8c8c8",
            colorLighter1: "#d8d8d8",
            colorLighter2: "#e8e8e8",
          },

          {
            id: "KG_CONTEXT_COLOR_PURPLE_1",
            name: "purple1",
            colorMain: "#dd3497ee",
            colorLighter1: "#8c6bb188",
            colorLighter2: "#8c6bb122",
          },
          {
            id: "KG_CONTEXT_COLOR_RED_1",
            name: "red1",
            colorMain: "#fc8d62",
            colorLighter1: "#fc8d6288",
            colorLighter2: "#fc8d6222",
          },
          {
            id: "KG_CONTEXT_COLOR_YELLOW_1",
            name: "yellow1",
            colorMain: "#ffd92f",
            colorLighter1: "#ffd92f88",
            colorLighter2: "#ffd92f22",
          },
          {
            id: "KG_CONTEXT_COLOR_GREEN_1",
            name: "green1",
            colorMain: "#66c2a5",
            colorLighter1: "#66c2a588",
            colorLighter2: "#66c2a522",
          },
          {
            id: "KG_CONTEXT_COLOR_BLUE_1",
            name: "blue1",
            colorMain: "#8da0cb",
            colorLighter1: "#8da0cb88",
            colorLighter2: "#8da0cb22",
          },
          {
            id: "KG_CONTEXT_COLOR_GRAY_2",
            name: "gray 2",
            colorMain: "#688",
            colorLighter1: "#66666688",
            colorLighter2: "#66666622",
          },
          {
            id: "KG_CONTEXT_COLOR_PURPLE_2",
            name: "purple 2",
            colorMain: "#ae017e",
            colorLighter1: "#ae017e88",
            colorLighter2: "#ae017e22",
          },
          {
            id: "KG_CONTEXT_COLOR_RED_2",
            name: "red 2",
            colorMain: "#e31a1c",
            colorLighter1: "#e31a1c88",
            colorLighter2: "#e31a1c22",
          },
          {
            id: "KG_CONTEXT_COLOR_YELLOW_2",
            name: "yellow 2",
            colorMain: "#bf812d",
            colorLighter1: "#bf812d88",
            colorLighter2: "#bf812d22",
          },
          {
            id: "KG_CONTEXT_COLOR_GREEN_2",
            name: "green 2",
            colorMain: "#238443",
            colorLighter1: "#23844388",
            colorLighter2: "#23844322",
          },
          {
            id: "KG_CONTEXT_COLOR_BLUE_2",
            name: "blue 2",
            colorMain: "#2171b5",
            colorLighter1: "#2171b588",
            colorLighter2: "#2171b522",
          },
        ],
      },
      codes: {
        issues: {
          ISSUE_UNPARSED_REFERENCES: {
            key: "ISSUE_UNPARSED_REFERENCES",
            description: "Unable to parse references",
          },
          ISSUE_UNPARSED_ABSTRACT: {
            key: "ISSUE_UNPARSED_ABSTRACT",
            description: "Unable to parse abstract",
          },
        },
      },
      doc_tabs: {
        tab_group: {
          borderBottom: darkMode ? "1px solid #666" : "1px solid #ddd",
          marginLeft: "3px",
        },
        tabs: {
          border: darkMode ? "1px solid #666" : "1px solid #ddd",
          color: darkMode ? "#ccc" : "#222",
          "& .active": {
            borderBottom: "2px solid " + purple[400] + " !important",
            color: purple[400] + "!important",
          },
          "&:hover": {},
        },
      },
      navbar: {
        // tab_height: "25px",
      },
      tooltips: {
        doc_empty_notes:
          " Create contextual annotations. Highlight text or create an image snippet (ALT or Command key + mouse click & drag), then select context from a pop-up menu.",
      },
      doc_data: {
        maxTagPillTerms: 10,
      },
      doc_view: {
        zoom_level_delta: 0.25,
        zoom_level_max: 10,
        zoom_level_min: 0.25,
      },
      user_settings: {
        file_limit: {
          trial: 5,
          registered: 70,
        },
      },
    },
  });
};
const IdleDurationBeforeAutoLogout = 3600; // seconds; 1 hour of inactivity

export default function App() {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    (state) => state.globalState.user_preferences.app_ui.dark_theme
  );
  const [loggedIn, setLoggedIn] = useState({ auth: false, user: {} });
  const navigate = useNavigate();
  const sessionKhatam = useSelector((state) => state.users.sessionKhatam);
  const [showSessionExpiryAlert, setShowSessionExpiryAlert] = useState(false);
  const customTheme = getGlobalTheme(darkMode);
  var timer = null;

  useEffect(() => {
    if (loggedIn.auth) {
      timer = new IdleTimer({
        timeout: IdleDurationBeforeAutoLogout,
        onTimeout: () => {
          handleLogout();
        },
        onExpired: () => {},
      });
    }
  }, [loggedIn.auth]);

  const handleSignUpUser = (data) => {
    navigate("/login");
  };

  const handleLogin = (data) => {
    setLoggedIn({ auth: true, user: data });
    navigate("/dashboard");
  };

  async function handleLogout(navigateToPath) {
    await fetch("/user/signout")
      .then(() => {
        // Initialize all stores - there's probably a better way to do the following
        dispatch(initDocuments());
        dispatch(initAnnotations());
        dispatch(initFolders());
        dispatch(initUsers());

        if (navigateToPath) {
          // Load path specified by the user - not validated!
          navigate(navigateToPath);
        } else {
          // Load default path
          navigate("/login");
        }
        // Reset logged in state
        setLoggedIn({ auth: false, user: {} });
        timer && timer.cleanUp();
        timer = null;
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  const confirmSessionExpiryAlert = () => {
    console.log("here in confirmSessionExpiryAlert");
    setShowSessionExpiryAlert(false);
    handleLogout();
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <GlobalStyles />
      <Routes>
        <>
          <Route path="/" element={<MainPage />} />
          {/* <Route path="/" element={<LoginForm onLogin={handleLogin} />} /> */}
          {loggedIn ? (
            <Route
              path="/dashboard"
              element={
                <ProtectedContent loggedIn={loggedIn}>
                  <AppTopLevelLayout
                    handleLogout={handleLogout}
                    user={loggedIn.user}
                  />
                </ProtectedContent>
              }
            />
          ) : (
            <Navigate
              to={{
                pathname: "/login",
              }}
            />
          )}
          <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
          <Route
            path="/signup"
            element={<SignupForm onSignUpUser={handleSignUpUser} />}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="*" element={<My404Component />} />
        </>
      </Routes>
      <AlertDialog
        callbackCancel={null}
        callbackProceed={confirmSessionExpiryAlert}
        contentText={"Your session has expired, please log in again."}
        openDialog={showSessionExpiryAlert}
        proceedButtonText={"Ok"}
        title={"Session Expired"}
      />
      <NotificationSnackbar
        message={"Hello world!"}
        type={"KG_EVENT_SUCCESS"}
      />
    </MuiThemeProvider>
  );
}

function ProtectedContent({ children, loggedIn }) {
  return loggedIn.auth ? children : <Navigate to="/login" />;
}
