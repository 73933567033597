import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Treemap,
  ResponsiveContainer,
  LabelList,
  Text,
} from "recharts";
import REACT_APP_CONFIG from "../../../AppConfig";
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import PromoMessages from "../../../assets/guide/PromoMessages";
import "./SplashScreen.css";
import { useSelect } from "@mui/base";
import { useSelector } from "react-redux";

const bgImage = require("../../../assets/backgrounds/kg.png");

const SplashScreen = ({ openDashboard }) => {
  const [chartData, setChartData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const docsData = useSelector((state) => state.docs.docs);
  const foldersData = useSelector((state) => state.folders.folders);
  const snackbarRef = React.createRef(null);
  const globalTheme = useTheme();
  const large = useMediaQuery("(min-width:786px)");

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    var data = [];
    if (docsData && foldersData) {
      foldersData.forEach((f) => {
        var folderDocsData = [];
        docsData.forEach((d) => {
          if (d._folder === f._id) {
            folderDocsData.push({
              name: d.name.substr(0, 10),
              size: d.annotations.total > 0 ? d.annotations.total : 1,
            });
          }
        });

        data.push({
          name: f.name,
          children: folderDocsData,
          n: folderDocsData.length,
        });
      });
    }

    data.sort((a, b) => {
      if (a.name > b.name) return 1;
      else if (a.name < b.name) return -1;
      else return 0;
    });

    setChartData(data);
  }, [docsData, foldersData]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowDashboard = () => {
    openDashboard(true);
  };

  /*** Custom shape  ***/

  const getPath = (x, y, width, height) => `M${x},${y + height}
C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
    x + width / 2
  }, ${y}
C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
Z`;

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#111111cc",
            borderRadius: "5px",
            color: "white",
            padding: "8px",
          }}
        >
          <div className="label">{`Folder: ${
            label.length > 16 ? label.substr(0, 20) + ".." : label
          }`}</div>
          <div className="label">{`Documents : ${payload[0].value}`}</div>
        </div>
      );
    }
    return null;
  };
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 13;

    return (
      <g>
        <circle
          cx={x + width / 2}
          cy={y - radius}
          r={radius}
          fill={globalTheme.palette.primary.main}
        />
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };
  return (
    <div className="splash-screen">
      {REACT_APP_CONFIG.dev && 0 ? (
        <Snackbar
          ref={snackbarRef}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          // message="A customized garden will appear once your read and annotate documents in the app."
          message={REACT_APP_CONFIG.feature_flag}
          open={open}
          onClose={handleClose}
          style={{ width: 400 }}
        />
      ) : (
        ""
      )}
      <div className={"image-bg"}>
        {/* <img src={bgImage} className="img-fluid" alt="" /> */}
        {chartData.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              border: "0px solid red",
              width: "100%",
              height: "200px",
            }}
          >
            <ResponsiveContainer width="97.5%" height="100%">
              <BarChart
                width={200}
                height={200}
                data={chartData}
                margin={{ top: 30 }}
              >
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: globalTheme.palette.secondary.main + "44" }}
                  animationDuration={0}
                  position={{ y: 0 }}
                />
                <Bar dataKey="n" shape={<TriangleBar />}>
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={globalTheme.palette.primary.main}
                    />
                  ))}
                  <LabelList
                    dataKey={"n"}
                    position={"top"}
                    content={renderCustomizedLabel}
                  />
                </Bar>
                <XAxis
                  dataKey={"name"}
                  type="category"
                  tickFormatter={(label) => `${label}`}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>&nbsp;</div>
      <Grid item style={{ marginLeft: "10%", marginRight: "10%" }}>
        <div className="splash-screen-carousel-caption">
          <Typography
            variant={large ? "h4" : "h5"}
            style={{
              color: globalTheme.palette.primary.main,
              fontWeight: "400",
            }}
          >
            Build your garden of knowledge
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        className="splash-screen-carousel-container"
        style={{ marginLeft: "15%", marginRight: "15%" }}
      >
        <Carousel
          className="splash-screen-carousel"
          animation={"fade"}
          autoPlay={true}
          interval={6000}
          indicatorIconButtonProps={{
            style: {
              padding: "10px",
              color: "#eeeeee",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#ccc",
            },
          }}
          navButtonsAlwaysInvisible={true}
        >
          {PromoMessages.splash_screen.carousel_messages.map((item, i) => (
            <div key={i} className="splash-screen-carousel-item">
              <div className="splash-screen-carosel-item-contents">
                <Typography variant={large ? "h6" : "subtitle1"}>
                  {item.text}
                </Typography>
              </div>
            </div>
          ))}
        </Carousel>
      </Grid>
      {/* <div>&nbsp;</div> */}
      <Grid item xs={12} align="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleShowDashboard}
        >
          Start
        </Button>
      </Grid>
    </div>
  );
};

export default SplashScreen;
