import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AnnotationsAPI from "../api/AnnotationsAPI";
import { store } from "./store";

const initialState = {
  annotations: [],
  docStats: [],
  favoriteAnnotations: [],
  filteredBy: null,
  filteredByContext: null,
  filtered: [],
  globalContextCounts: {},
  pageStats: [],
  todoAnnotations: [],
  userTags: [],
};
export const getAnnotationsForAllDocs = createAsyncThunk(
  "/annotation/all_docs",
  async () => {
    const res = await AnnotationsAPI.annotationsApiGetForAllDocs();
    return res.data;
  }
);

export const getAllAnnotations = createAsyncThunk(
  "/annotation/all",
  async (id) => {
    const res = await AnnotationsAPI.annotationsApiGetAllForADoc(id);
    return res.data;
  }
);

export const getAllFavoriteAnnotations = createAsyncThunk(
  "/annotation/all_favorite",
  async () => {
    const res = await AnnotationsAPI.annotationsApiGetAllFavorite();
    return res.data;
  }
);

export const getAllTodoAnnotations = createAsyncThunk(
  "/annotation/all_todo",
  async () => {
    const res = await AnnotationsAPI.annotationsApiGetAllTodo();
    return res.data;
  }
);

export const getAllAnnotationsByContextLabel = createAsyncThunk(
  "/annotation/all_context",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiGetByContext(data);
    return res.data;
  }
);

export const getAnnotationsForSearchQuery = createAsyncThunk(
  "/annotation/get_for_search_query",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiGetAnnotationsForSearchQuery(
      data
    );
    return res.data;
  }
);

export const getContextCountsForAllDocs = createAsyncThunk(
  "/annotation/get_context_counts/all",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiGetContextCountsForAllDocs(
      data
    );
    return res.data;
  }
);

export const removeAnnotations = createAsyncThunk(
  "/annotation/remove",
  async (data) => {
    const res = await AnnotationsAPI.removeAnnotations(data);
    return res.data;
  }
);

export const annotationTest = createAsyncThunk(
  "/annotation/test",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiTest(data);
    return res.data;
  }
);

export const saveAnnotation = createAsyncThunk(
  "/annotation/save",
  async ({ annotations }) => {
    const res = await AnnotationsAPI.saveAnnotation(annotations);
    return res.data;
  }
);

export const updateAnnotation = createAsyncThunk(
  "/annotation/update",
  async (data) => {
    const res = await AnnotationsAPI.updateAnnotations(data);
    return res.data;
  }
);

export const updateThemeForAnnotations = createAsyncThunk(
  "/annotation/update_theme",
  async (data) => {
    const res = await AnnotationsAPI.annotationsApiUpdateTheme(data);
    return res.data;
  }
);

export const sliceAnnotations = createSlice({
  name: "annotations",
  initialState,
  reducers: {
    initAnnotations: (state, action) => {
      state = initialState;
      return state;
    },
    removeAnnotationByDocId: (state, action) => {
      state.annotations = state.annotations.filter(
        (d) => d._doc !== action.payload.docId
      );
      state.docStats = state.docStats.filter(
        (d) => d.docId !== action.payload.docId
      );
      state.pageStats = state.pageStats.filter(
        (d) => d.docId !== action.payload.docId
      );
      return state;
    },
    setPageWiseAnnotations: (state, action) => {
      const docId = action.payload.docId;
      const pages = action.payload.pages;
      state.pageStats.push({
        docId: docId,
        pages: [...pages],
      });
      return state;
    },
    setDocWiseAnnotations: (state, action) => {
      const docId = action.payload.docId;
      const stats = action.payload.docStats;
      state.docStats.push({
        docId: docId,
        stats: [...stats],
      });
      return state;
    },
    setAnnotationsFilteredBy: (state, action) => {
      state.filteredBy = action.payload;
      return state;
    },
    incrementPageWiseAnnotation: (state, action) => {
      const docId = action.payload.docId;
      const docIndex = state.pageStats.findIndex((d) => d.docId === docId);
      const contextId = action.payload.contextId;
      const pageNumber = action.payload.pageNumber;

      if (docIndex > -1) {
        if (pageNumber >= 1) {
          // Update page-level statistics
          const context = state.pageStats[docIndex].pages[
            pageNumber - 1
          ].ctxCounts.find((c) => c.ctx === contextId);
          if (context) {
            context.val += 1;
          }
          // Update document-level statistics
          const docStatsIndex = state.docStats.findIndex(
            (d) => d.docId === docId
          );
          if (docStatsIndex > -1) {
            const docContext = state.docStats[docStatsIndex].stats.find(
              (c) => c.ctx === contextId
            );
            if (docContext) {
              docContext.val += 1;
            }
          }
        }
      }
      return state;
    },
    decrementPageWiseAnnotation: (state, action) => {
      const docId = action.payload.docId;
      const pageNumber = action.payload.pageNumber;
      const contextId = action.payload.contextId;
      const docIndex = state.pageStats.findIndex((d) => d.docId === docId);

      if (docIndex > -1) {
        if (pageNumber >= 0) {
          // Update page-level statistics
          const context = state.pageStats[docIndex].pages[
            pageNumber - 1
          ].ctxCounts.find((c) => c.ctx === contextId);
          if (context) {
            context.val--;
          }

          // Update document-level statistics
          const docStatsIndex = state.docStats.findIndex(
            (d) => d.docId === docId
          );
          if (docStatsIndex > -1) {
            const docContext = state.docStats[docStatsIndex].stats.find(
              (c) => c.ctx === contextId
            );
            if (docContext) {
              docContext.val--;
            }
          }
        }
      }
      return state;
    },
    updateMultipleAnnotations: (state, action) => {
      const updatedAnnotations = action.payload;
      updatedAnnotations.forEach((updatedAnnotation) => {
        state.annotations = state.annotations.map((a) => {
          if (a._id === updatedAnnotation._id) {
            return Object.assign({}, updatedAnnotation);
          } else return a;
        });
      });
      // state.annotationsUpdated = new Date();
      return state;
    },
  },
  extraReducers: {
    [annotationTest.fulfilled]: (state, action) => {
      return state;
    },
    [getAnnotationsForAllDocs.fulfilled]: (state, action) => {
      state.annotations = [...action.payload.annotations];
      return state;
    },
    [getAllAnnotations.fulfilled]: (state, action) => {
      if (action.payload && action.payload.annotations) {
        action.payload.annotations.forEach((newAnnotation) => {
          if (!state.annotations.find((a) => a._id === newAnnotation._id))
            state.annotations.push(newAnnotation);
        });
      }
      return state;
    },
    [getAllAnnotationsByContextLabel.fulfilled]: (state, action) => {
      if (action.payload && action.payload.annotations) {
        state.filtered = [...action.payload.annotations];
      }
      return state;
    },
    [getAllFavoriteAnnotations.fulfilled]: (state, action) => {
      if (action.payload && action.payload.annotations) {
        state.favoriteAnnotations = [...action.payload.annotations];
      }
      return state;
    },
    [getAllTodoAnnotations.fulfilled]: (state, action) => {
      if (action.payload && action.payload.annotations) {
        state.todoAnnotations = [...action.payload.annotations];
      }
      return state;
    },
    [getAnnotationsForSearchQuery.fulfilled]: (state,action) => {
      // console.log("Result: ", action.payload)
    },
    [getContextCountsForAllDocs.fulfilled]: (state, action) => {
      if (action.payload["all_docs_context_counts"]) {
        state.globalContextCounts = Object.assign(
          {},
          action.payload["all_docs_context_counts"]
        );
      }
      return state;
    },
    [saveAnnotation.fulfilled]: (state, action) => {
      if (action.payload.annotations) {
        action.payload.annotations.forEach((a) => {
          state.annotations.push(a);
          // update the global count of annotation context
          const contextLabel = a["context"]["label"];
          if (state.globalContextCounts[contextLabel] === 0)
            state.globalContextCounts[contextLabel] = 1;
          else state.globalContextCounts[contextLabel] += 1;
        });
      }
      return state;
    },
    [removeAnnotations.fulfilled]: (state, action) => {
      if (action.payload.annotations) {
        action.payload.annotations.forEach((aId) => {
          // Reduce annotation count
          const ann = state.annotations.find((a) => a._id === aId);

          if (ann) {
            // const docId = action.payload.docId;
            const docId = ann._doc;
            const pageNumber = ann.position.pageNumber;
            const contextId = ann.context.contextId;

            const docIndex = state.pageStats.findIndex(
              (d) => d.docId === docId
            );

            if (docIndex > -1) {
              if (pageNumber >= 1) {
                const context = state.pageStats[docIndex].pages[
                  pageNumber - 1
                ].ctxCounts.find((c) => c.ctx === contextId);
                if (context) {
                  context.val -= 1;
                }
              }
            }

            // Update document-level statistics
            const docStatsIndex = state.docStats.findIndex(
              (d) => d.docId === docId
            );
            if (docStatsIndex > -1) {
              const docContext = state.docStats[docStatsIndex].stats.find(
                (c) => c.ctx === contextId
              );
              if (docContext) {
                docContext.val--;
              }
            }

            // update the global count of annotation context
            const contextLabel = ann["context"]["label"];
            state.globalContextCounts[contextLabel] -= 1;
          }

          // remove annotation entry
          state.annotations = state.annotations.filter((a) => a._id !== aId);

          state.favoriteAnnotations = state.annotations.filter(
            (a) => a.comment.star === true
          );
          state.todoAnnotations = state.annotations.filter(
            (a) => a.comment.todo === true
          );
        });
        // state.annotationsUpdated = new Date();
      }
      return state;
    },
    [updateAnnotation.fulfilled]: (state, action) => {
      const updatedAnnotation = action.payload.annotation;
      state.annotations = state.annotations.map((a) => {
        if (a._id === updatedAnnotation._id) {
          return Object.assign({}, updatedAnnotation);
        } else return a;
      });

      return state;
    },
    [updateThemeForAnnotations.fulfilled]: (state, action) => {
      if (action.payload.annotations) {
        const updatedAnnotations = action.payload.annotations;
        updatedAnnotations.forEach((updatedAnnotation) => {
          state.annotations = state.annotations.map((a) => {
            if (a._id === updatedAnnotation._id)
              return Object.assign({}, updatedAnnotation);
            else return a;
          });
        });
      }
      return state;
    },
  },
});

export const {
  initAnnotations,
  removeAnnotationByDocId,
  setAnnotationsFilteredBy,
  setDocWiseAnnotations,
  setPageWiseAnnotations,
  incrementPageWiseAnnotation,
  decrementPageWiseAnnotation,
  updateMultipleAnnotations,
} = sliceAnnotations.actions;
export default sliceAnnotations.reducer;
