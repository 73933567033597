const InMemoryJWTManager = () => {
  var inMemoryJWT = null;
  var inMemoryUserId = null;
  var inMemoryUserName = null;
  var inMemoryJWTTokenExpiry = null;
  var inMemoryUserIsAdmin = false;
  var inMemoryUserIsGuest = false;

  const getToken = () => inMemoryJWT;

  const setToken = (token) => {
    inMemoryJWT = token;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
  };

  const setUserId = (userId) => {
    inMemoryUserId = userId;
    // window.blah = inMemoryUserId;
  };
  const getUserId = () => inMemoryUserId;
  const eraseUserId = () => (inMemoryUserId = null);

  const setUserName = (userName) => {
    inMemoryUserName = userName;
  }
  const getUserName = () => inMemoryUserName;
  const eraseUserName = () => (inMemoryUserName = null);

  const setUserIsAdmin = (isAdmin) => {
    inMemoryUserIsAdmin = isAdmin;
  }
  const getUserIsAdmin = () => inMemoryUserIsAdmin;
  const eraseUserIsAdmin = () => (inMemoryUserIsAdmin = null);

  const setUserIsGuest = (isGuest) => {
    inMemoryUserIsGuest = isGuest;
  }
  const getUserIsGuest = () => inMemoryUserIsGuest;
  const eraseUserIsGuest = () => (inMemoryUserIsGuest = null);

  const getExpiry = () => inMemoryJWTTokenExpiry;
  const setExpiry = (expiry) => (inMemoryJWTTokenExpiry = expiry);

  return {
    eraseToken,
    getToken,
    setToken,
    getExpiry,
    setExpiry,
    setUserId,
    getUserId,
    eraseUserId,
    setUserIsAdmin,
    getUserIsAdmin,
    eraseUserIsAdmin,
    setUserIsGuest,
    getUserIsGuest,
    eraseUserIsGuest,
    setUserName,
    getUserName,
    eraseUserName,
  };
};

export default InMemoryJWTManager();
