import REACT_APP_CONFIG from "../../AppConfig";
const debugLog = (componentName, message, object = {}) => {
  if (REACT_APP_CONFIG.debug_log)
    console.log("[", componentName, "]", message, " ", object);
};
const handleError = (component, error) => {
  console.log("[", component, "]", error);
};

const appEvents = {
  debugLog,
  handleError,
};

export default appEvents;
