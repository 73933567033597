import { IconButton, TextField, useTheme } from "@material-ui/core";
import CancelRoundedIcon from "@mui/icons-material/Clear";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const SearchWidget = ({ callbackUpdateResults, reset }) => {
  const globalTheme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSearchQuery("");
  }, [reset]);
  const handleClearSearch = () => {
    setSearchQuery("");
    callbackUpdateResults("");
  };

  const handleSearchQuery = () => {
    if (searchQuery !== "") callbackUpdateResults(searchQuery);
  };

  return (
    <form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <TextField
          id="search-bar"
          className="text"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton aria-label="clear search" onClick={handleClearSearch}>
                <CancelRoundedIcon />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "small" },
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              handleSearchQuery();
              ev.preventDefault();
            }
          }}
          onInput={(e) => {
            setSearchQuery(e.target.value);
          }}
          // label="Enter search string"
          variant="standard"
          placeholder="Search Annotations and Notes"
          size="small"
          value={searchQuery}
        />
        <IconButton onClick={handleSearchQuery} aria-label="search">
          <GridSearchIcon style={{ color: globalTheme.palette.primary.main }} />
        </IconButton>
      </div>
    </form>
  );
};

export default SearchWidget;
