import {
  Badge,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  setRef,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReferencesCard from "../References/ReferenceCard";
import BookmarkIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkAllIcon from "@mui/icons-material/BookmarksOutlined";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import SadFaceIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import WarningIcon from "@mui/icons-material/Warning";
import { reportUserIssue } from "../../../store/SliceUsers";
import InMemoryJWTManger from "../../../api/JWTManager";
import IconStarUnset from "@mui/icons-material/BookmarkBorder";

import { styled } from "@material-ui/styles";
import ReferencesList from "../References/ReferencesList";
import { toggleReferenceBookmark } from "../../../store/SliceDocuments";
const graphicReportHelp = require("../../../assets/design/ui/doc-view-report.png");

const MyComponent = ({
  numAllRefs,
  numBookmarkedRefs,
  value,
  callbackSetValue,
  callbackClear,
  callbackShowBookmarkedRefs,
  showBookmarkedRefs,
}) => {
  const globalTheme = useTheme();
  const [showBookmarked, setShowBookmarked] = useState(false);
  const [val, setVal] = useState(value);
  const handleChange = (data) => {
    setVal(data);
    callbackSetValue(data);
  };
  const handleClear = (data) => {
    setVal("");
    callbackClear();
  };
  const handleToggleBookmarked = () => {
    setShowBookmarked(!showBookmarked);
    callbackShowBookmarkedRefs();
  };
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: 15,
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          arrow
          placement="top"
          title={
            <Typography>
              Show {showBookmarked ? "all" : "bookmarked"} references
            </Typography>
          }
          enterDelay={500}
          leaveDelay={200}
          enterNextDelay={500}
        >
          <IconButton
            onClick={handleToggleBookmarked}
            style={{ color: globalTheme.palette.primary.main }}
          >
            {showBookmarked ? (
              <Badge
                badgeContent={numBookmarkedRefs}
                color="secondary"
                overlap="rectangular"
                showZero={false}
                variant="standard"
              >
                <BookmarkAllIcon />
              </Badge>
            ) : (
              <Badge
                badgeContent={numBookmarkedRefs}
                color="secondary"
                overlap="rectangular"
                showZero={false}
                variant="standard"
              >
                <BookmarkIcon />
              </Badge>
            )}
             
          </IconButton>
        </Tooltip>
      </div>
      <TextField
        autoComplete="off"
        autoFocus
        fullWidth
        label="Search references"
        id="search-textfield"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        value={val}
        InputLabelProps={{
          style: {
            fontSize: "0.9em",
            marginTop: 5,
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: value ? "visible" : "hidden" }}
              onClick={handleClear}
            >
              <CloseIcon style={{ fontSize: "0.8em" }} />
            </IconButton>
          ),
          style: {
            height: 44,
          },
        }}
      />
    </div>
  );
};

const graphicReparseDoc = require("../../../assets/design/ui/dashboard-reparse-doc.png");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fafafa",
    height: "82vh",
    width: "28vw",
  },
  header: {
    paddingLeft: "10px",
  },
  reference_info_container: {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
    marginLeft: "10px",
    paddingTop: "10px",
    width: "100%",
  },
  reference_info: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "15px",
    "&>div": { marginRight: 15 },
    width: "95%",
  },
  reference_info_icon: {
    alignItems: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    display: "flex",
  },
  reference_info_empty_message: {
    color: "gray",
  },
  reference_report_issue: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    padding: "3px",
  },
  reference_issue_reported: {
    backgroundColor: theme.palette.primary.lightest,
    borderRadius: "4px",
    color: "#222",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
  },
  reference_controls: {
    alignItems: "center",
    color: "gray",
    display: "flex",
    justifyContent: "flex-start",
    padding: "15px",
    paddingTop: 0,
    paddingBottom: 0,
  },
  number_of_references: {
    backgroundColor: "#f3f3f3",
    borderRadius: "6px",
    display: "flex",
    fontSize: "small",
    justifyContent: "space-around",
    padding: "5px",
    marginRight: "15px",
    textAlign: "center",
    width: "125px",
    "& > span": {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  reference_panel: {
    height: "65vh",
    overflow: "scroll",
    overflowX: "hidden",
    marginLeft: "10px",
    marginTop: "10px",
    padding: "10px",
    width: "90%",
  },
}));

const ReferencesPanel = ({ docId, callbackSetNumReferences }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const docs = useSelector((state) => state.docs.docs);
  const globalTheme = useTheme();
  const issues = useSelector((state) => state.users.issues);
  const docFound = docs.filter((d) => d._id === docId);
  const [reportedUnparsedReference, setReportedUnparsedReferences] =
    useState(false);
  const [updatingRef, setUpdatingRef] = useState(-1);
  const [showBookmarkedRefs, setShowBookmarkedRefs] = useState(false);
  const [numRefs, setNumRefs] = useState(0);
  const [numRefsBookmarked, setNumRefsBookmarked] = useState(0);
  const [refSearchString, setRefSearchString] = useState("");

  var thisDoc = null;
  if (docFound && docFound.length >= 1) {
    thisDoc = docFound[0];
  }
  const [references, setReferences] = useState([]);

  useEffect(() => {
    if (
      thisDoc.metadata &&
      thisDoc.metadata.docData &&
      thisDoc.metadata.docData.bibliography
    ) {
      setNumRefs(thisDoc.metadata.docData.bibliography.length);
      const numBookmarked = thisDoc.metadata.docData.bibliography.filter(
        (ref) => ref.fav
      ).length;
      setNumRefsBookmarked(numBookmarked);
      callbackSetNumReferences(thisDoc.metadata.docData.bibliography.length);
    }
  }, []);

  useEffect(() => {
    if (
      thisDoc.metadata &&
      thisDoc.metadata.docData &&
      thisDoc.metadata.docData.bibliography
    ) {
      var refs = thisDoc.metadata.docData.bibliography;
      if (refSearchString !== "") {
        refs = refs.filter(
          (ref) =>
            ref.text.toLowerCase().indexOf(refSearchString.toLowerCase()) > -1
        );
      }
      if (showBookmarkedRefs) {
        refs = refs.filter((ref) => ref.fav);
        setNumRefsBookmarked(refs.length);
      }
      setReferences(refs);
      callbackSetNumReferences(thisDoc.metadata.docData.bibliography.length);
    }
  }, [thisDoc]);

  useEffect(() => {
    const thisDocIssue = issues.find((issue) => issue._doc === docId);
    if (thisDocIssue) {
      if (
        thisDocIssue.issue.key ===
        globalTheme.kg_ui.codes.issues.ISSUE_UNPARSED_REFERENCES.key
      ) {
        setReportedUnparsedReferences(true);
      }
    }
  }, [issues]);

  const handleFavoriteReference = (ref, refId) => {
    const isFavorite = !ref.fav;
    setUpdatingRef(refId);
    if (isFavorite) setNumRefsBookmarked(numRefsBookmarked + 1);
    else setNumRefsBookmarked(numRefsBookmarked - 1);
    dispatch(
      toggleReferenceBookmark({
        doc_id: docId,
        ref: { ...ref, fav: isFavorite },
      })
    ).then(() => {
      setUpdatingRef(-1);
    });
  };

  const handleReportUnparsedReferences = () => {
    var created = new Date();
    var seconds = Math.round(created.getTime() / 1000);

    dispatch(
      reportUserIssue({
        _doc: docId,
        issue: globalTheme.kg_ui.codes.issues["ISSUE_UNPARSED_REFERENCES"],
        bib_parsed:
          thisDoc.metadata.docData.bibliography.length > 0 ? true : false,
        timestamp: created,
        _user: InMemoryJWTManger.getUserId(),
      })
    );
  };

  const handleShowBookmarkedRefsOnly = () => {
    const bookmarkedOnly = !showBookmarkedRefs;
    if (
      thisDoc.metadata &&
      thisDoc.metadata.docData &&
      thisDoc.metadata.docData.bibliography
    ) {
      setShowBookmarkedRefs(bookmarkedOnly);
      var refs = thisDoc.metadata.docData.bibliography;
      if (refSearchString !== "") {
        refs = refs.filter(
          (ref) =>
            ref.text.toLowerCase().indexOf(refSearchString.toLowerCase()) > -1
        );
      }
      if (bookmarkedOnly) {
        refs = refs.filter((ref) => ref.fav);
      }
      setReferences(refs);
    }
  };

  const handleSetRefSearchString = (data) => {
    setRefSearchString(data);
    if (
      thisDoc.metadata &&
      thisDoc.metadata.docData &&
      thisDoc.metadata.docData.bibliography
    ) {
      var refs = [];
      if (showBookmarkedRefs) {
        refs = thisDoc.metadata.docData.bibliography.filter((ref) => ref.fav);
      } else refs = thisDoc.metadata.docData.bibliography;
      refs = refs.filter(
        (ref) => ref.text.toLowerCase().indexOf(data.toLowerCase()) > -1
      );
      setReferences(refs);
    }
  };
  const handleClearRefSearchString = () => {
    setRefSearchString("");
    var refs = [];
    if (
      thisDoc.metadata &&
      thisDoc.metadata.docData &&
      thisDoc.metadata.docData.bibliography
    ) {
      if (showBookmarkedRefs) {
        refs = thisDoc.metadata.docData.bibliography.filter((ref) => ref.fav);
      } else refs = thisDoc.metadata.docData.bibliography;
      setReferences(refs);
    }
  };
  const flagAllowReferenceIssueReporting = true;

  const ZeroReferences = () => {
    return (
      <div
        style={{
          color: "gray",
          padding: "10px",
          borderRadius: "4px",
          border: "1px solid #dddddd",
          margin: "10px",
        }}
      >
        <span>
          Knowledge Garden uses text analysis and parsing to extract
          bibliographic information from scholarly articles based on standard
          bibliography formats such as ACM, APA, and IEEE.
        </span>
        <hr />
        <span>
          <WarningIcon fontSize="small" /> Note that references may be missing
          or parsed incorrectly for documents such as books, dissertations, or
          those with unsupported bibliography formats.
          <br />
          <br />
          Click the button 'Report Issue' above to notify us about issues with
          references.
          <br />
          <br />
          You can also try to reparse the document - reparsing is available via
          the ellipses menu next to the document name in the Library on the
          Dashboard (see below).
        </span>
        <br />
        <br />
        <img src={graphicReparseDoc} width={225} />
      </div>
    );
  };

  if (thisDoc === null) return <></>;
  return (
    <div className={classes.wrapper}>
      {/* <div className={classes.header}>
        <Typography variant="h5">References</Typography>
      </div> */}
      <div className={classes.reference_info_container}>
        {numRefs >= 0 ? (
          <div className={classes.reference_info}>
            <div>
              <Typography style={{ fontSize: "1.2em" }}>References</Typography>
            </div>
            <div>
              {numRefs > 0 ? (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography>
                      <strong>References / Bibliography</strong>
                      <br />
                      <br />
                      <span>
                        Knowledge Garden uses text analysis and parsing to
                        extract bibliographic information from scholarly
                        articles based on standard bibliography formats such as
                        ACM, APA, and IEEE.
                      </span>
                      <br />
                      <br />
                      <span style={{ color: "yellow" }}>
                        <WarningIcon fontSize="small" /> Note that references
                        may be missing or parsed incorrectly for documents such
                        as books, dissertations, or those with unsupported
                        bibliography formats.
                        <br />
                        <br />
                        Click 'Report Issue' to notify us about issues with
                        references. You can also try to reparse the document -
                        reparsing is available via the ellipses menu next to the
                        document name in the Library on the Dashboard.
                      </span>
                      <br />
                      <br />
                      References can be bookmarked. The bookmarked references
                      appear in the document report, which is available via the
                      document tools menu.
                      <br />
                      <br />
                      <img src={graphicReportHelp} width={"70%"} />
                    </Typography>
                  }
                  enterDelay={500}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <div className={classes.reference_info_icon}>
                    <InfoIcon />
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <div className={classes.reference_report_issue}>
              {!reportedUnparsedReference ? (
                <div>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={handleReportUnparsedReferences}
                  >
                    Report Issue
                  </Button>
                  {/* <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      <Typography fontSize={16}>
                        <strong>
                          Trouble With References? <SadFaceIcon />
                        </strong>
                        <br />
                        <br />
                        <span>
                          The references text may sometime appear broken into
                          multiple lines, incorrectly merged, or be missing
                          entirely. Click 'Report Issue' so that we can evaluate
                          the issue. Only the document and user IDs will be
                          shared.
                        </span>
                      </Typography>
                    }
                    enterDelay={0}
                    leaveDelay={100}
                    enterNextDelay={500}
                  >
                    <div className={classes.reference_info_icon}>
                      <InfoIcon />
                    </div>
                  </Tooltip> */}
                </div>
              ) : (
                <span>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <Typography fontSize={16}>
                        <span>
                          You reported an issue with references for this
                          document.
                        </span>
                      </Typography>
                    }
                    enterDelay={0}
                    leaveDelay={100}
                    enterNextDelay={500}
                  >
                    <span>
                      <ThumbUpIcon /> &nbsp;Reported
                    </span>
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>{numRefs === 0 ? <ZeroReferences /> : ""}</div>
      {numRefs > 0 ? (
        <div>
          <div className={classes.reference_controls}>
            <div
              className={classes.number_of_references}
              style={{
                backgroundColor:
                  references.length === numRefs
                    ? ""
                    : globalTheme.palette.primary.lightest,
                border:
                  references.length === numRefs
                    ? `1px solid #f3f3f3`
                    : `1px solid ${globalTheme.palette.primary.lighter}`,
                color:
                  references.length === numRefs
                    ? ""
                    : globalTheme.palette.primary.dark,
              }}
            >
              <span>
                Showing <br />
                {references.length} of {numRefs}
              </span>
            </div>
            <MyComponent
              numAllRefs={numRefs}
              numBookmarkedRefs={numRefsBookmarked}
              value={refSearchString}
              bookmarkedOnly={showBookmarkedRefs}
              callbackClear={handleClearRefSearchString}
              callbackSetValue={handleSetRefSearchString}
              callbackShowBookmarkedRefs={handleShowBookmarkedRefsOnly}
            />
          </div>

          <div>
            <ReferencesList
              docId={thisDoc._id}
              callbackSetFavoriteReference={handleFavoriteReference}
              filterBookmarkedOnly={showBookmarkedRefs}
              numRefs={numRefs}
              references={references}
              searchStr={refSearchString}
              updatingRef={updatingRef}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReferencesPanel;
