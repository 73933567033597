import { makeStyles } from "@material-ui/core";
import React from "react";
import RBTabs from "../Views/ReactBootstrapTabs/RBT";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "stretch",
  },
});

const MainViewLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <RBTabs />
    </div>
  );
};
export default MainViewLayout;
