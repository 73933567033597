import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InMemoryJWTManger from "../../../api/JWTManager";
import {
  getAllAnnotations,
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  removeAnnotations,
  saveAnnotation,
  updateThemeForAnnotations,
} from "../../../store/SliceAnnotations";
import { updateDocContextTheme } from "../../../store/SliceDocuments";
import DocumentContext from "../../Document/DocumentContext";
import PdfView from "../PdfView/PdfView";
import DocumentActions from "./DocumentActions";
import DocumentInfo from "./DocumentInfo";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // border: "1px solid red",
    backgroundColor: theme.palette.type === "dark" ? "#444" : "#f0f0f0",
    flex: "1 1 auto",
    justifyContent: "stretch",
    height: "92.7vh",
  },
  doc_info_panel: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
const DocumentView = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalTheme = useTheme();

  const allAnnotations = useSelector((state) => state.notes.annotations);
  const [thisDocsAnnotation, setAnnotations] = useState([]);
  const docs = useSelector((state) => state.docs.docs);
  const docObj = docs.filter((doc) => doc._id === id);
  const doc = docObj.length === 1 ? docObj[0] : null;
  const docOptions = useSelector(
    (state) => state.users.preferences.docOptions || {}
  );
  const [themeKey, setThemeKey] = useState(doc.annotations.contexts.themeId);
  const docsLoading = useSelector((state) => state.docs.docsLoading);
  const isLoading = docsLoading.find((d) => d === id) || false;
  const userContexts = useSelector((state) => state.users.contexts);
  const [themeColors, setThemeColors] = useState(null);
  const [zoomLevel, setZoomLevel] = useState("auto");

  // useEffect(() => {
  //   setAnnotations(allAnnotations.filter((a) => a._doc === id));
  // }, [allAnnotations]);
  useEffect(() => {
    ////////////////////////////////////////////////////////////
    // Update the theme colors every time the user changes the
    // theme or user contexts
    ////////////////////////////////////////////////////////////

    const tempThemeColors = {};

    // Get the current theme
    const docCurrentTheme = userContexts[themeKey]
      ? userContexts[themeKey]
      : userContexts["KG_ANNOTATION_THEME_DEFAULT"];

    const globalColorSet = globalTheme.kg_ui.context_theme.colors;

    // Go through all contexts in the current theme
    docCurrentTheme.contexts.forEach((c) => {
      // Get context ID for the context
      const contextId = c.contextId;
      // Get the color ID assigned to the context
      const colorId = c.colorId || null;

      if (colorId) {
        // Create an empty object for the context, hashed by its context ID
        tempThemeColors[contextId] = {};
        // Find the color that matches the context's color ID
        const color = globalColorSet.find((clr) => clr.id === colorId);
        if (color) {
          // Add color information
          tempThemeColors[contextId] = { ...color };
        }
      }
    });
    setThemeColors(tempThemeColors);
  }, [themeKey, userContexts, doc]);

  var pdfDoc = null;

  if (doc && doc.pdfDoc) {
    pdfDoc = doc.pdfDoc;
  }

  const pdfGetAllAnnotations = (id) => {
    return dispatch(getAllAnnotations({ id: id }));
  };

  const pdfSaveAnnotation = (data) => {
    return dispatch(saveAnnotation({ annotations: [data] }));
  };

  const pdfDeleteAnnotations = (data) => {
    return dispatch(removeAnnotations(data));
  };

  const handleSetThemeKey = (data) => {
    setThemeKey(data.newKey);
    dispatch(
      updateDocContextTheme({
        docId: doc._id,
        themeId: data.newKey,
      })
    ).then(() => {
      dispatch(
        updateThemeForAnnotations({
          docId: doc._id,
          newThemeId: data.newKey,
          newTheme: userContexts[data.newKey],
        })
      ).then(() => {
        dispatch(getAllFavoriteAnnotations());
        dispatch(getAllTodoAnnotations());
      });
    });
  };

  const handleZoomLevelChange = (value) => {
    setZoomLevel(value);
  };

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        ""
      ) : (
        <div>
          <div className={classes.doc_info_panel}>
            <DocumentInfo doc={doc} />
            <DocumentActions
              doc={doc}
              callbackSetZoomLevel={handleZoomLevelChange}
            />
            <DocumentContext
              doc={doc}
              themeKey={themeKey}
              callbackSetDocTheme={handleSetThemeKey}
              themeColors={themeColors || null}
            />
          </div>
          <PdfView
            annotations={allAnnotations.filter((a) => a._doc === id)}
            doc={doc}
            docId={id}
            getAllAnnotations={pdfGetAllAnnotations}
            options={docOptions}
            pdfObj={pdfDoc}
            removeAnnotations={pdfDeleteAnnotations}
            saveAnnotation={pdfSaveAnnotation}
            themeKey={themeKey}
            themeColors={themeColors || null}
            themeObj={userContexts[themeKey]}
            userId={InMemoryJWTManger.getUserId()}
            zoomLevel={zoomLevel}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentView;
