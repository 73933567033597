import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TinyBarChart from "../../Visualizations/TinyBarChart";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // border: "1px solid red",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
  },
  bar_chart: {
    backgroundColor: theme.palette.secondary.lighter,

    border: "1px solid #00000000",
    borderRadius: "2px",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#fafafa",
    },
  },
  bar_chart_tooltip_content: {
    backgroundColor: "#22222266",
    display: "flex",
    justifyContent: "left",
    padding: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  doc_folder_and_name: {
    display: "flex",
    flexDirection: "column",
  },
  folder_name: {
    color: "#666666",
    backgroundColor: "#f3f3f3",
    borderRadius: "50px",
    fontSize: "0.75em",
    marginBottom: "5px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
}));
const RBTDocTab = ({
  doc,
  folder,
  onClose,
  maxContextCountOverAllDocs,
  selected,
}) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const globalColorSet = globalTheme.kg_ui.context_theme.colors;

  const [contextColors, setContextColors] = useState({});
  const [contextLabels, setContextLabels] = useState({});

  const docStats = useSelector((state) => state.notes.docStats);
  const themeUpdated = useSelector((state) => state.docs.themeUpdated);
  const userContexts = useSelector((state) => state.users.contexts);

  const currentTheme = userContexts[doc.themeId]
    ? userContexts[doc.themeId]
    : userContexts["KG_ANNOTATION_THEME_DEFAULT"];

  useEffect(() => {
    var tempColorsMap = {};
    const tempLabelsMap = {};
    currentTheme.contexts.forEach((ctx) => {
      // Find and map the color for the context
      const color = globalColorSet.find((clr) => clr.id === ctx.colorId);
      if (color) {
        tempColorsMap[ctx.contextId] = color.colorMain;
      }

      // Map the label for the context
      tempLabelsMap[ctx.contextId] = ctx.label;
    });

    setContextColors(tempColorsMap);
    setContextLabels(tempLabelsMap);
  }, [themeUpdated, userContexts]);

  const thisDocStats = docStats.find((d) => d.docId === doc.fileId);

  var maxYVal = -1;
  if (thisDocStats)
    thisDocStats.stats.forEach((e) => {
      maxYVal = e.val > maxYVal ? e.val : maxYVal;
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.doc_folder_and_name}>
        <div className={classes.folder_name}>
          {folder && folder.length === 1
            ? folder[0].name.length > 20
              ? folder[0].name.substring(0, 20) + ".."
              : folder[0].name
            : ""}
        </div>
        <Tooltip
          arrow
          placement="bottom"
          title={<Typography fontSize={16}>{doc.name}</Typography>}
          enterDelay={500}
          leaveDelay={100}
          enterNextDelay={400}
        >
          <div
            style={{
              color: selected ? "white" : "black",
              fontSize: "0.9em",
              fontWeight: selected ? "600" : "normal",
            }}
          >
            {doc.name.length > 16 ? doc.name.substring(0, 16) + ".." : doc.name}
          </div>
        </Tooltip>
      </div>
      <div>
        {maxYVal > 0 ? (
          <Tooltip
            arrow
            placement="bottom"
            title={
              <Typography fontSize={16}>
                Number of annotations by context
                {thisDocStats
                  ? thisDocStats.stats.map((s, i) => (
                      <span
                        key={i}
                        className={classes.bar_chart_tooltip_content}
                      >
                        <span>{s.val}</span>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <span>{contextLabels[s.ctx]}</span>
                      </span>
                    ))
                  : ""}
              </Typography>
            }
            enterDelay={500}
            leaveDelay={100}
            enterNextDelay={400}
          >
            <div className={classes.bar_chart}>
              <TinyBarChart
                width={60}
                height={30}
                margin={{
                  top: 5,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                id={1}
                data={thisDocStats ? thisDocStats.stats : []}
                contextColors={contextColors}
                maxYVal={maxContextCountOverAllDocs}
                showYVal={true}
              />
            </div>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
      <div>
        <IconButton component="div" onClick={(event) => onClose(event, doc)}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default RBTDocTab;
