import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotesIcon from "@mui/icons-material/ArticleOutlined";
import NewDocIcon from "@mui/icons-material/Circle";
import MoreActionsIcon from "@mui/icons-material/MoreVertOutlined";
import EyeIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InMemoryJWTManager from "../../../api/JWTManager";
import REACT_APP_CONFIG from "../../../AppConfig";
import {
  getPDFTextAllPages,
  loadPDFFromURL,
  pdfProcessReferences,
} from "../../../libs/utils/PdfProcessor";
import {
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  getContextCountsForAllDocs,
  removeAnnotationByDocId,
} from "../../../store/SliceAnnotations";
import {
  addDocLoading,
  closeDoc,
  deleteDoc,
  emptyReturn,
  getDoc,
  loadFile,
  openDoc,
  parseDocText,
  updateDocReferences,
} from "../../../store/SliceDocuments";
import AlertDialog from "../../UIWidgets/AlertDialog";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#f3f3f3",
    borderBottom:
      theme.palette.type === "dark" ? "1px solid #222" : "1px solid #d0d0d0",
    display: "flex",
    flexDirection: "row",
    fontSize: "0.875em",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 0,
    padding: 5,
    paddingLeft: "15px",
  },
  file_open_button: {
    color: theme.palette.primary.main,
    fontSize: "0.75em",
  },
  file_actions: {
    color: "gray",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // marginRight: "px",
  },
  file_icon: {
    color: "#bbb",
    transform: "scale(.8)",
  },
  file_name: {
    cursor: "default",
    display: "flex",
    fontWeight: "600",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "260px",
    "&:hover": { color: theme.palette.primary.main },
  },
  file_status: {},
  icon_file_launch: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: 65,
    transform: "scale(.8)",
    width: 50,
  },
  icon_new_doc_status: {
    color: theme.palette.secondary.main,
    marginLeft: "-8px",
    marginRight: "5px",
    transform: "scale(0.4)",
  },
}));

const FileContainer = ({ data }) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileStatus, setFileStatus] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const openFileMenu = Boolean(anchorEl);
  const isAdmin = InMemoryJWTManager.getUserIsAdmin();

  // Get the list of all opened documents
  const openedDocs = useSelector((state) => state.docs.docsOpened);

  // Check if this document is in the list of opened documents (check it's fileId)
  const result = openedDocs.find((d) => d.fileId === data._id);

  // Set flag if document is opened for reading or not
  const isOpened = result ? true : false;

  const handleLoadDocument = (event, forceParse) => {
    const parseDoc = forceParse || false;
    // Change status of the doc to loading
    setLoading(true);

    // Change status of the doc to loading
    dispatch(addDocLoading({ docId: data._id }));

    // Get document from DB
    dispatch(getDoc({ id: data._id }))
      .then((documentObj) => {
        if (documentObj.error && documentObj.error.message) {
          //// File Not Found or Session Expired
          // console.log(
          //   "[FileContainer] Error",
          //   documentObj.error.message,
          //   " - got while fetching document"
          // );
        } else {
          const document = documentObj.payload.document;
          if (document) {
            const isDocDataPresent = parseDoc
              ? false
              : document.metadata && document.metadata.docData
              ? true
              : false;

            const isCitationDataParsed =
              document.metadata.docData &&
              document.metadata.docData.bibliography
                ? true
                : false;

            // Get file from storage
            setFileStatus("Fetching file..");
            dispatch(loadFile({ id: document._id })).then((fileData) => {
              loadPDFFromURL(fileData.payload.file).then((pdfObj) => {
                document.pdfDoc = pdfObj;
                // console.log("is doc data present?", isDocDataPresent, " citations? ", isCitationDataParsed);
                if (isDocDataPresent && isCitationDataParsed) {
                  setFileStatus("Opening file..");
                  dispatch(
                    openDoc({
                      docId: document._id,
                      pdfObj: pdfObj,
                      folderId: document._folder,
                      docData: isDocDataPresent,
                    })
                  );
                  setFileStatus("");
                  setLoading(false);
                } else {
                  setFileStatus("Processing text..");
                  const promisePdfGetText = getPDFTextAllPages(pdfObj);
                  promisePdfGetText.then((parsedText) => {
                    dispatch(
                      parseDocText({
                        id: document._id,
                        pageData: parsedText,
                        forceParse: parseDoc || false,
                      })
                    ).then((data) => {
                      // console.log("DOC DATA > ", data);
                      setFileStatus("Opening document..");
                      dispatch(
                        updateDocReferences({
                          docId: document._id,
                          references: data.payload.data.docData.bibliography,
                        })
                      ).then(() => {
                        dispatch(
                          emptyReturn({
                            docId: document._id,
                            docData: true,
                            folderId: document._folder,
                            pdfObj: pdfObj,
                          })
                        ).then(() => {
                          setFileStatus("");
                          setLoading(false);
                        });
                      });
                    });
                  });
                }
              });
            });
          }
        }
      })
      .catch((error) => {
        console.log("[FileContainer] !Error: ", error);
      });
  };

  const FileName = ({ name }) => {
    return (
      <div>
        <Tooltip
          arrow
          placement="top"
          title={<Typography fontSize={16}>{name}</Typography>}
          enterDelay={200}
          leaveDelay={200}
          enterNextDelay={500}
          style={{ maxWidth: "50px" }}
        >
          <span>
            {name.length > 25 ? `${name.substring(0, 25)}.. .pdf` : name}
          </span>
        </Tooltip>
      </div>
    );
  };

  const callbackCancel = () => {
    setShowDeleteDialog(false);
  };

  const callbackProceed = () => {
    setShowDeleteDialog(false);
    deleteFile();
  };

  const deleteFile = () => {
    // Remove document from store
    dispatch(closeDoc({ docId: data._id }));

    // Remove annotations for document from the store
    dispatch(removeAnnotationByDocId({ docId: data._id }));

    dispatch(deleteDoc(data._id)).then((data) => {
      dispatch(getAllFavoriteAnnotations());
      dispatch(getAllTodoAnnotations());
      dispatch(getContextCountsForAllDocs());
    });
  };

  const downloadFile = () => {};

  const moveFile = () => {};

  const handleOpenFileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFileMenu = (event) => {
    // Close the menu
    setAnchorEl(null);

    // Get action item from the menu
    const value = event.nativeEvent.target.innerText.trim();
    if (value === "") {
      return;
    }
    switch (value) {
      case "Delete":
        setShowDeleteDialog(true);
        break;
      case "Download":
        downloadFile();
        break;
      case "Move file":
        moveFile();
        break;
      case "Reparse document":
        handleLoadDocument(null, true);
        break;
    }
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div>
            {!data.flags || !data.flags.docParsed ? (
              <NewDocIcon className={classes.icon_new_doc_status} />
            ) : (
              ""
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.file_name}>
              <FileName name={data.name} />
            </div>
            <div className={classes.file_status}>{fileStatus}</div>
          </div>
          <div>
            {data.annotations.total > 0 ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>Number of annotations</Typography>
                }
                enterDelay={200}
                leaveDelay={200}
                enterNextDelay={500}
                style={{ maxWidth: "50px" }}
              >
                <div
                  style={{
                    width: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "gray",
                    cursor: "default",
                    "&:hover": {
                      border: "1px solid gray",
                      color: "gray",
                    },
                  }}
                >
                  {data.annotations.total}
                  <NotesIcon style={{ transform: "scale(.758)" }} />
                </div>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classes.file_actions}>
          <div className={classes.icon_file_launch}>
            {loading ? (
              <div style={{}}>
                <CircularProgress size={30} style={{ fontSize: "1.2em" }} />
              </div>
            ) : isOpened ? (
              <div style={{ color: globalTheme.palette.secondary.main }}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>Document is open</Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={800}
                >
                  <EyeIcon />
                </Tooltip>
              </div>
            ) : (
              <div>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  disabled={isOpened}
                  onClick={handleLoadDocument}
                >
                  Open
                </Button>
                {isAdmin ? (
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    disabled={isOpened}
                    onClick={() => handleLoadDocument(null, true)}
                  >
                    Reparse
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div>
            <IconButton id={data.fileId} onClick={handleOpenFileMenu}>
              <MoreActionsIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFileMenu}
          onClose={handleCloseFileMenu}
          MenuListProps={{
            name: "FileActions",
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleCloseFileMenu}>
            <span style={{ color: "red" }}>Delete</span>
          </MenuItem>
          {REACT_APP_CONFIG.dev ? (
            <MenuItem
              onClick={handleCloseFileMenu}
              disabled={isOpened ? true : false}
            >
              Reparse document{" "}
              {isOpened ? (
                " - Close the doc to reparse"
              ) : (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>
                      Re run text analytics on the document to extract data such
                      as text excerpts, keywords, references, and web links.
                    </Typography>
                  }
                  enterDelay={200}
                  leaveDelay={200}
                  enterNextDelay={500}
                  style={{ maxWidth: "50px" }}
                >
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
        <AlertDialog
          callbackCancel={callbackCancel}
          callbackProceed={callbackProceed}
          contentText={`The document and all your notes in the document will be deleted permanently. Proceed with caution!`}
          openDialog={showDeleteDialog}
          proceedButtonText={"Delete Permanently"}
          title={`Remove Document - ${data.name}`}
        />
      </div>
    </div>
  );
};

export default FileContainer;
