import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserDataAPI from "../api/UserDataAPI";

const initialState = {
  contexts: {},
  issues: [],
  preferences: { docOptions: {} },
  session: {
    expired: false,
  },
  sessionKhatam: false,
  themeUpdated: new Date(),
};

export const getAllUsers = createAsyncThunk(
  "userdata/all_users",
  async (data) => {
    var res = await UserDataAPI.userdataApiGetAllUsers(data);
    return res.data;
  }
);

export const addUserData = createAsyncThunk("userdata/add", async (data) => {
  var res = await UserDataAPI.userdataApiAddUserData(data);
  return res.data;
});

export const getUserData = createAsyncThunk("userdata/get", async () => {
  var res = await UserDataAPI.userdataApiGetUserData();
  return res.data;
});

export const addNewUserContextTheme = createAsyncThunk(
  "userdata/addtheme",
  async (data) => {
    var res = await UserDataAPI.userdataApiAddNewContextTheme(data);
    return res.data;
  }
);

export const deleteUserContextTheme = createAsyncThunk(
  "userdata/deletetheme",
  async (data) => {
    var res = await UserDataAPI.userdataApiDeleteContextTheme(data);
    return res.data;
  }
);

export const updateUserContextTheme = createAsyncThunk(
  "userdata/updatetheme",
  async (data) => {
    var res = await UserDataAPI.userdataApiUpdateContextTheme(data);
    return res.data;
  }
);

export const getUserIssues = createAsyncThunk(
  "document/report_issue_reference_unparsed",
  async (data) => {
    const res = await UserDataAPI.docsApiGetUserIssues(data);
    return res.data;
  }
);

export const removeUser = createAsyncThunk(
  "useer/remove_user",
  async ({ id }) => {
    const res = await UserDataAPI.userdataApiRemoveuser({ id });
    return res.data;
  }
);

export const reportUserIssue = createAsyncThunk(
  "document/report_issue_reference_unparsed",
  async (data) => {
    const res = await UserDataAPI.docsApiReportUserIssue(data);
    return res.data;
  }
);

export const updateUserSettings = createAsyncThunk(
  "userdata/updatesettings",
  async (data) => {
    const res = await UserDataAPI.userdataApiUpdateSetting(data);
    return res.data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    initUsers: (state, action) => {
      state = initialState;
      return state;
    },
    sessionExpired: (state, action) => {
      return { ...state, sessionKhatam: action.payload };
    },
  },
  extraReducers: {
    [addUserData.fulfilled]: (state, action) => {},
    [getUserData.fulfilled]: (state, action) => {
      if (action.payload.userData && action.payload.userData.data) {
        state.contexts = { ...action.payload.userData.data.contextThemes };
        state.preferences = {
          ...(action.payload.userData.data.preferences || {}),
        };
      }
      return state;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      return state;
    },
    [addNewUserContextTheme.fulfilled]: (state, action) => {
      if (action.payload.contexts) state.contexts = action.payload.contexts;
      return state;
    },
    [deleteUserContextTheme.fulfilled]: (state, action) => {
      if (action.payload.contexts) {
        state.contexts = action.payload.contexts;
      }
      return state;
    },
    [updateUserContextTheme.fulfilled]: (state, action) => {
      if (action.payload.contexts) state.contexts = action.payload.contexts;
      state.themeUpdated = new Date();
      return state;
    },
    [getUserIssues.fulfilled]: (state, action) => {
      if (action.payload) {
      }
    },
    [reportUserIssue.fulfilled]: (state, action) => {
      if (action.payload.data) state.issues.push(action.payload.data);
      return state;
    },
    [removeUser.fulfilled]: (state, action) => {
      return state;
    },
    [updateUserSettings.fulfilled]: (state, action) => {
      if (action.payload.preferences)
        state.preferences = { ...action.payload.preferences };
      return state;
    },
  },
});

export const { initUsers, sessionExpired } = usersSlice.actions;

export default usersSlice.reducer;
