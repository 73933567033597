import {
  Avatar,
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import REACT_APP_CONFIG from "../../../AppConfig";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ContextEditorIcon from "@mui/icons-material/TocRounded";
import GuestUserIcon from "@mui/icons-material/EmojiPeople";
import QuickTourIcon from "@mui/icons-material/LocalLibraryOutlined";
import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import InMemoryJWTManager from "../../../api/JWTManager";
import AdminView from "../Admin/AdminView";
import ContextEditorDialog from "../ContextEditor/ContextEditorDialog";
import UserGuide from "../UserGuide/UserGuide";
import UserSettings from "../UserSettings/UserSettings";
import AppHelpGuidePrompt from "./AppHelpGuidePrompt";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { populateDocText } from "../../../store/SliceDocuments";

const logo = require("../../../assets/logo/logo-kg-1.png");

const useStyles = makeStyles((theme) => ({
  nav: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexGrow: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
    maring: 14,
    paddingLeft: 10,
    paddingRight: 4,
  },
  nav_bottom_part: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  nav_top_part: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: 10,
  },
  svg_icons: {
    color: theme.palette.primary.lighter,
    transform: "scale(1.15)",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  icon_info: {
    color: "#999999",
  },
  is_admin: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "3px",
    cursor: "pointer",
    fontSize: ".75em",
    padding: "3px",
    textAlign: "center",
    "&:hover": {
      boxShadow: "2px 2px 4px #999999",
    },
  },
  feedback: {
    // border: `1px solid ${theme.palette.primary.lighter}`,
    color: theme.palette.primary.main,
    borderRadius: "3px",
    cursor: "pointer",
    fontSize: ".8em",
    padding: "4px",
    marginBottom: "10px",
    textAlign: "center",
    "&:hover": {
      // boxShadow: "1px 1px 4px #bbbbbb",
      backgroundColor: "#f0f0f0",
      color: theme.palette.primary.darker,
    },
  },
  tour: {
    border: `0px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    borderRadius: "3px",
    cursor: "pointer",
    fontSize: ".75em",
    padding: "4px",
    marginBottom: "10px",
    textAlign: "center",
    "&:hover": {
      boxShadow: "2px 2px 4px #c9c9c9",
    },
  },
}));

const NavPanel = ({ callbackLogout, openDashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAdminView, setShowAdminView] = useState(false);
  const [showContextEditor, setShowContextEditor] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [showAppGuide, setShowAppGuide] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  const handleShowUserGuide = () => {
    setShowUserGuide(true);
  };

  const handleCloseUserGuide = () => {
    setShowUserGuide(false);
  };

  const handleShowContextEditor = () => {
    setShowContextEditor(true);
  };

  const handleViewSplashScreen = () => {
    openDashboard();
  };

  const handleCloseContextEditor = () => {
    setShowContextEditor(false);
  };

  const handleShowAdminView = () => {
    setShowAdminView(true);
  };

  const handleCloseAdminView = () => {
    setShowAdminView(false);
  };

  const handleToggleSettingsDrawer = (event) => {
    setShowUserSettings(!showUserSettings);
  };

  const handleCancelShowAppGuide = (event, reason) => {
    // if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setShowAppGuide(false);
  };

  const handleFeedback = () => {
    window.open("https://forms.gle/zRVYp7vRpZinGmYn7", "_blank");
  };

  async function handleSignup() {
    callbackLogout("/signup");
  }

  const handleOpenTour = () => {
    setShowAppGuide(true);
  };

  const handlePopulateDocs = () => {
    console.log("here")
    dispatch(populateDocText({})).then((result) => {
      console.log(result);
    });
  };

  return (
    <div className={classes.nav}>
      <div className={classes.nav_top_part}>
        <div>
          <img
            src={logo}
            style={{ height: 50, width: 50, cursor: "pointer" }}
            onClick={handleViewSplashScreen}
            alt=""
          />
        </div>
        <div>&nbsp;</div>
        <div>
          {InMemoryJWTManager.getUserIsGuest() ? (
            <div>
              <div
                style={{
                  alignItems: "center",
                  color: "gray",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  pointerEvents: "none",
                  textAlign: "center",
                }}
              >
                <span>&nbsp;</span>
                <GuestUserIcon />
                <span>
                  Hello
                  <br /> Guest!
                </span>
              </div>
              <div>&nbsp;</div>
              <Tooltip
                arrow
                placement="right"
                title={
                  <Typography fontSize={16}>
                    Create an account to access all features in Knowledge Garden
                  </Typography>
                }
                enterDelay={10}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <Button
                  onClick={handleSignup}
                  color="primary"
                  size="small"
                  variant="outlined"
                >
                  Sign up
                </Button>
              </Tooltip>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          {InMemoryJWTManager.getUserIsAdmin() ? (
            <div className={classes.is_admin} onClick={handleShowAdminView}>
              ADMIN
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          {InMemoryJWTManager.getUserIsAdmin() && REACT_APP_CONFIG.dev === true ? (
            <div className={classes.is_admin} onClick={handlePopulateDocs}>
              Run
            </div>
          ) : (
            ""
          )}
        </div>
        <div>&nbsp;</div>
        <Tooltip
          arrow
          placement="right"
          title={<Typography fontSize={16}>Open quick tour</Typography>}
          enterDelay={10}
          leaveDelay={200}
          enterNextDelay={500}
        >
          <IconButton className={classes.svg_icons} onClick={handleOpenTour}>
            <QuickTourIcon />
          </IconButton>
        </Tooltip>
        <div>&nbsp;</div>
        <div>
          <Tooltip
            arrow
            enterDelay={1000}
            enterNextDelay={200}
            leaveDelay={200}
            placement="right"
            title={<Typography fontSize={16}>View user guide </Typography>}
          >
            <IconButton
              className={classes.svg_icons}
              onClick={handleShowUserGuide}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>&nbsp;</div>
        <div>
          <Tooltip
            arrow
            enterDelay={500}
            enterNextDelay={200}
            leaveDelay={200}
            placement="right"
            title={
              <Typography fontSize={16}>
                <strong>Create or Edit Context Themes</strong> <br />
                <br />
                <span style={{ color: "#ffff00" }}>
                  A context theme is a set of labels for adding personal context
                  as you read and annotate text or images in documents.
                </span>
                <br />
                <br />
                The context labels are color coded and visualized in the
                Knowledge Garden app to help with contextual search and recall
                of information.
              </Typography>
            }
          >
            <IconButton
              onClick={handleShowContextEditor}
              style={
                {
                  // backgroundColor: theme.palette.primary.lightest,
                }
              }
            >
              <ContextEditorIcon
                className={classes.svg_icons}
                style={{
                  // color: theme.palette.primary.darker,
                  transform: "rotate(180deg)scale(1.25)",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
        <div>&nbsp;</div>
        <div className={classes.nav_bottom_part}>
          <Tooltip
            arrow
            placement="right"
            title={<Typography fontSize={16}>Settings</Typography>}
            enterDelay={10}
            leaveDelay={200}
            enterNextDelay={500}
          >
            <IconButton
              className={classes.svg_icons}
              onClick={handleToggleSettingsDrawer}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>&nbsp;</div>
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography fontSize={16}>
              Share your feedback, feature <br />
              requests, or any issues using KG.
            </Typography>
          }
          enterDelay={10}
          leaveDelay={200}
          enterNextDelay={500}
        >
          <div className={classes.feedback} onClick={handleFeedback}>
            Feedback
          </div>
        </Tooltip>
      </div>
      <hr />
      <div className={classes.nav_bottom_part}>
        <Tooltip
          arrow
          placement="right"
          title={<Typography fontSize={16}>Logout</Typography>}
          enterDelay={10}
          leaveDelay={200}
          enterNextDelay={500}
        >
          <IconButton className={classes.svg_icons} onClick={callbackLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>

      {/* <div>
        <Tooltip
          arrow
          placement="right"
          title={<Typography fontSize={16}>Toggle dark mode</Typography>}
        >
          <Switch
            checked={globalDarkMode}
            disableRipple
            color="primary"
            label="Toggle dark mode"
            onChange={handleThemeToggle}
          />
        </Tooltip>
        <div className={classes.account_icon}>
          <IconButton>
            <AccountCircleIcon fontSize="large" />
          </IconButton>
        </div>
      </div> */}
      {showAdminView ? (
        <AdminView
          openDialog={showAdminView}
          callbackCancel={handleCloseAdminView}
        />
      ) : (
        ""
      )}
      {showContextEditor ? (
        <ContextEditorDialog
          callbackCancel={handleCloseContextEditor}
          contextKey={"KG_ANNOTATION_THEME_DEFAULT"}
          openDialog={showContextEditor}
          proceedButtonText={"Save Theme"}
          title={"Create or Edit Context Themes"}
        />
      ) : (
        ""
      )}
      {showUserGuide ? (
        <UserGuide
          openDialog={showUserGuide}
          callbackCancel={handleCloseUserGuide}
          cancelButtonText={"Close"}
          contentText={"Coming soon.."}
        />
      ) : (
        ""
      )}
      {showUserSettings ? (
        <React.Fragment key={"left"}>
          <Drawer
            anchor={"left"}
            open={showUserSettings}
            onClose={handleToggleSettingsDrawer}
          >
            <UserSettings callbackClose={handleToggleSettingsDrawer} />
          </Drawer>
        </React.Fragment>
      ) : (
        ""
      )}
      <AppHelpGuidePrompt
        openDialog={showAppGuide}
        callbackCancel={handleCancelShowAppGuide}
        callbackShowUserGuide={handleShowUserGuide}
        cancelButtonText={"Go To Dashboard"}
      />
    </div>
  );
};

export default NavPanel;
