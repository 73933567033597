import {
  Badge,
  Chip,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import FilterIcon from "@mui/icons-material/FolderSpecialOutlined";
import IconToDo from "@mui/icons-material/NotificationsActiveRounded";
import IconStar from "@mui/icons-material/StarRateRounded";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyIllustration from "../../../assets/illustrations/annotations_empty_state.svg";
import {
  getAllAnnotationsByContextLabel,
  getAnnotationsForSearchQuery
} from "../../../store/SliceAnnotations";
import SelectionWidget from "../../UIWidgets/SelectionWidget";
import AnnotationCard from "../../Views/Annotations/AnnotationCard";
import SearchWidget from "../Search/SearchWidget";
import DocsAndFolderSelector from "./DocsAndFolderSelector";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflowY: "auto",
    height: "76vh",
  },
  annotations_panel: {
    display: "flex",
    flexDirection: "column",
    height: "75vh",
    justifyContent: "flex-start",
    overflowY: "auto",
    marginTop: "10px",
  },
  filter_info: {
    alignItems: "baseline",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "small",
    marginRight: 8,
    "& .filter_info_caption": {
      color: theme.palette.greyscale.main,
      marginRight: 5,
    },
  },
  filter_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
    width: "100%",
  },
  filter_options: {
    display: "flex",
  },
  filter_by_context: {
    alignItems: "center",
    display: "flex",
  },
  filter_by_container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "flex-end",
  },
  filter_by_docs_caption: {
    color: theme.palette.greyscale.main,
    fontSize: "small",
  },
  filter_icons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  filter_result_summary: {
    color: "#333",
    fontWeight: "600",
    margin: "2px",
    padding: "2px",
    textAlign: "left",
  },
  filter_ui: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px",
  },
  empty_library_message: {
    color: "#bbbbbb",
    fontStyle: "italic",
    margin: "15px",
    padding: "20px",
    textAlign: "center",
  },
}));

const FilterAnnotations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalTheme = useTheme();

  const allAnnotations = useSelector((state) => state.notes.annotations);
  const contextStats = useSelector((state) => state.notes.globalContextCounts);
  const [allAnnotationsView, setAnnotations] = useState([]);
  const [contextLabels, setContextLabels] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [filterContextData, setFilterContextData] = useState(null);
  const [showDocsAndFolderSelector, setShowDocsAndFolderSelector] =
    useState(false);

  // ## Search-related
  const [numberOfAnnotations, setNumberOfAnnotations] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  // const [annotationsFound, setAnnotations] = useState([]);
  const [documentsFound, setDocumentsFound] = useState([]);
  const [resetSearch, setResetSearch] = useState(new Date());
  // ##

  const favoriteAnnotations = useSelector(
    (state) => state.notes.favoriteAnnotations
  );
  const todoAnnotations = useSelector((state) => state.notes.todoAnnotations);

  const [docsSelected, setDocsSelected] = useState([]);
  const [numFavoriteAnnotations, setNumFavoriteAnnotations] = useState(0);
  const [numTodoAnnotations, setNumTodoAnnotations] = useState(0);

  const documentContextThemeUpdated = useSelector(
    (state) => state.docs.themeUpdated
  );
  const userContexts = useSelector((state) => state.users.contexts);
  const userContextThemeUpdated = useSelector(
    (state) => state.users.themeUpdated
  );

  useEffect(() => {
    if (filterOption === "KG_FILTER_CONTEXT") {
      handleThemeSelection(filterContextData);
    }
  }, [contextStats]);

  useEffect(() => {
    setAnnotations(favoriteAnnotations);
    setNumFavoriteAnnotations(favoriteAnnotations.length);
  }, [favoriteAnnotations]);

  useEffect(() => {
    setAnnotations(todoAnnotations);
    setNumTodoAnnotations(todoAnnotations.length);
  }, [todoAnnotations]);

  // Reset the filtered view if user updated context
  useEffect(() => {
    setAnnotations([]);
  }, [documentContextThemeUpdated, userContextThemeUpdated]);

  useEffect(() => {
    const contextKeys = Object.keys(userContexts);
    var tempContextLabelsList = [];

    contextKeys.forEach((themeKey) => {
      const themeData = {
        label: userContexts[themeKey].name,
        contexts: [],
        themeKey: themeKey,
      };
      userContexts[themeKey].contexts.forEach((c) => {
        themeData.contexts.push({
          contextId: c.contextId,
          count: contextStats[c.label] || 0,
          label: c.label,
          themeId: themeKey,
          type: "item",
        });
      });
      tempContextLabelsList.push(themeData);
    });
    setContextLabels(tempContextLabelsList);
  }, [
    contextStats,
    documentContextThemeUpdated,
    userContextThemeUpdated,
    userContexts,
  ]);

  const getContextColor = (annotation) => {
    const globalColorSet = globalTheme.kg_ui.context_theme.colors;
    const themeId = annotation.context.themeId;
    var contextColors = null;
    if (userContexts[themeId]) {
      contextColors = {};
      userContexts[themeId].contexts.forEach((c) => {
        const color = globalColorSet.find((clr) => clr.id === c.colorId);
        if (color) {
          contextColors[c.contextId] = { ...color };
        }
      });
    }
    return contextColors;
  };

  const handleThemeSelection = (selectionData, docs) => {
    dispatch(getAllAnnotationsByContextLabel(selectionData)).then((res) => {
      if (res && res.payload && res.payload.annotations) {
        var listOfDocs = docs || docsSelected;

        // Check if we need to filter by documents
        if (listOfDocs.length > 0) {
          var filteredAnnotations = [];
          var temp = [];
          listOfDocs.forEach((doc) => {
            temp = res.payload.annotations.filter((ann) => ann._doc === doc);
            filteredAnnotations = filteredAnnotations.concat(temp);
          });
          setAnnotations(filteredAnnotations);
        } else {
          setAnnotations(res.payload.annotations);
        }
        setFilterOption("KG_FILTER_CONTEXT");
        setFilterContextData(selectionData);
      }
    });
  };

  const handleCloseDocsAndFolderSelector = () => {
    setShowDocsAndFolderSelector(false);
  };

  const handleSubmitDocsAndFolderSelector = (selectedDocs) => {
    if (filterOption === "KG_FILTER_CONTEXT") {
      handleThemeSelection(filterContextData, selectedDocs);
    } else if (filterOption === "KG_FILTER_FAVORITE") {
      // Check if we need to filter by documents
      var filteredAnnotations = Object.assign([], favoriteAnnotations);
      if (selectedDocs.length > 0) {
        var temp = [];
        var selectedAnnotations = [];
        selectedDocs.forEach((doc) => {
          temp = filteredAnnotations.filter((ann) => ann._doc === doc);
          selectedAnnotations = selectedAnnotations.concat(temp);
        });
        setAnnotations(selectedAnnotations);
      } else {
        setAnnotations(favoriteAnnotations);
      }
    } else if (filterOption === "KG_FILTER_TODO") {
      // Check if we need to filter by documents
      var filteredAnnotations = Object.assign([], todoAnnotations);
      if (selectedDocs.length > 0) {
        var temp = [];
        var selectedAnnotations = [];
        selectedDocs.forEach((doc) => {
          temp = filteredAnnotations.filter((ann) => ann._doc === doc);
          selectedAnnotations = selectedAnnotations.concat(temp);
        });
        setAnnotations(selectedAnnotations);
      } else {
        setAnnotations(todoAnnotations);
      }
    } else {
      console.log("TO DO > filter by document ids");
    }
    setDocsSelected(selectedDocs);
    setShowDocsAndFolderSelector(false);
  };

  const handleShowDocsAndFolderSelectorDialog = () => {
    setShowDocsAndFolderSelector(true);
  };

  const handleFilterByFavoriteNotes = () => {
    var filteredAnnotations = Object.assign([], favoriteAnnotations);
    if (docsSelected.length > 0) {
      var temp = [];
      var selectedAnnotations = [];
      docsSelected.forEach((doc) => {
        temp = filteredAnnotations.filter((ann) => ann._doc === doc);
        selectedAnnotations = selectedAnnotations.concat(temp);
      });
      setAnnotations(selectedAnnotations);
    } else {
      console.log(filteredAnnotations);
      setAnnotations(filteredAnnotations);
    }
    setResetSearch(new Date());
    setFilterOption("KG_FILTER_FAVORITE");
  };

  const handleFilterByNotesWithReminders = () => {
    var filteredAnnotations = Object.assign([], todoAnnotations);
    if (docsSelected.length > 0) {
      var temp = [];
      var selectedAnnotations = [];
      docsSelected.forEach((doc) => {
        temp = filteredAnnotations.filter((ann) => ann._doc === doc);
        selectedAnnotations = selectedAnnotations.concat(temp);
      });
      setAnnotations(selectedAnnotations);
    } else {
      setAnnotations(filteredAnnotations);
    }
    setResetSearch(new Date());
    setFilterOption("KG_FILTER_TODO");
  };

  const handleSearchResults = (data) => {
    if (data === "") {
      setAnnotations([]);
      setFilterOption("");
      setNumberOfAnnotations(0);
    } else if (data === searchQuery) {
    } else {
      // setAnnotations("KG_SEARCH_BUSY");
      setSearchQuery(data);
      dispatch(getAnnotationsForSearchQuery({ searchQuery: data })).then(
        (result) => {
          if (result.payload.search_results) {
            var numAnnotations = 0;
            result.payload.search_results.forEach((d) => {
              if (d.length === 2) {
                numAnnotations += d[1].length;
              }
            });

            var annotations = [];
            result.payload.search_results.forEach((item) => {
              if (item.length === 2) {
                annotations = annotations.concat(item[1]);
              }
            });
            setFilterOption("KG_FILTER_SEARCH");
            setAnnotations(annotations);
            setNumberOfAnnotations(numAnnotations);
          } else {
            setAnnotations([]);
            setNumberOfAnnotations(0);
          }
        }
      );
    }
  };

  const handleDownloadSelectedAnnotations = () => {
    exportAnnotationsAsPDF(allAnnotationsView);
  };

  const handleClearDocsFilter = () => {
    if (filterOption === "KG_FILTER_CONTEXT") {
      handleThemeSelection(filterContextData, []);
    } else if (filterOption === "KG_FILTER_FAVORITE") {
      setAnnotations(favoriteAnnotations);
    } else if (filterOption === "KG_FILTER_TODO") {
      setAnnotations(todoAnnotations);
    }
    setDocsSelected([]);
  };

  const exportAnnotationsAsPDF = (annotations) => {
    // console.log("> ", annotations);

    var pageWidth = 8.5,
      lineHeight = 1.2,
      margin = 0.75,
      maxLineWidth = pageWidth - margin * 2,
      fontSize = 18,
      ptsPerInch = 72,
      oneLineHeight = (fontSize * lineHeight) / ptsPerInch;

    var pdf = new jsPDF({ unit: "in", lineHeight: lineHeight });
    pdf.setFont("helvetica");
    pdf.setFontSize(fontSize);

    var str = "";
    var xPos = 20;
    var yPos = 0;
    var yIncr = 0.25;

    pdf.setLineWidth(0.5);
    pdf.setDrawColor(240);
    yPos += yIncr;
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight + 0.13,
      margin + 7,
      yPos + margin + oneLineHeight + 0.175
    );

    // Title
    pdf.setTextColor(171, 71, 188);
    yPos += yIncr;
    str = "REPORT: Annotations";
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);

    // // Doc name
    // pdf.setFontSize(14);
    // pdf.setTextColor(171, 71, 188);
    // yPos += yIncr * 2;
    // str = "Document: " + docData.name;
    // // pdf.text(str, margin, yPos + margin + oneLineHeight);
    // var textLines = pdf.splitTextToSize(str, maxLineWidth);
    // pdf.text(textLines, margin, yPos + margin + oneLineHeight);
    // yPos += yIncr * textLines.length;

    // KG date time
    pdf.setFontSize(12);
    pdf.setTextColor(100);
    yPos += 0.5;
    var dateObj = new Date();
    str = "Created by Knowledge Garden: " + dateObj;
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);

    // Section: CONTEXTUAL ANNOTATIONS
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Annotations";
    yPos += 1;

    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    str = "";
    yPos += 0.33;
    pdf.setFontSize(12);
    pdf.setTextColor(150);
    for (var annIndex = 0; annIndex < annotations.length; annIndex++) {
      const ann = annotations[annIndex];
      str = "";
      if (ann["content"]["text"]) {
        str =
          "A." +
          (annIndex + 1) +
          "\n" +
          `[Document] ${ann["docName"]}\n` +
          `[Theme] ${ann["context"]["themeName"]}\n` +
          `[Context] ${ann["context"]["label"]}\n` +
          `${ann["comment"]["star"] ? "[Favorite] YES \n" : ""}` +
          `${ann["comment"]["todo"] ? "[Follow-Up] YES \n " : ""}`;
        str += "\n";
        str += "[Highlight] " + ann["content"]["text"] + "\n";
        if (ann["comment"]["text"]) {
          str += "\n";
          str += "[My comment] " + ann["comment"]["text"] + "\n";
        }
        var textLines = pdf.splitTextToSize(str, maxLineWidth);
        if (yPos + yIncr * (textLines.length - 2) > 10) {
          pdf.addPage();
          yPos = 0.25;
        } else {
          yPos += yIncr;
        }
        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(textLines, margin, yPos + margin + oneLineHeight);
        yPos += yIncr * (textLines.length - 3);
      } else if (ann["content"]["image"]) {
        yPos += yIncr;
        str = "A." + (annIndex + 1) + " [Image highlight] ";
        str =
          "A." +
          (annIndex + 1) +
          "\n" +
          `[Document] ${ann["docName"]}\n` +
          `[Theme] ${ann["context"]["themeName"]}\n` +
          `[Context] ${ann["context"]["label"]}\n` +
          `${ann["comment"]["star"] ? "[Favorite] YES \n" : ""}` +
          `${ann["comment"]["todo"] ? "[Follow-Up] YES \n " : ""}` +
          `[Image highlight]\n`;
        str += "\n";
        var textLines = pdf.splitTextToSize(str, maxLineWidth);
        if (yPos + yIncr * (textLines.length - 2) > 10) {
          pdf.addPage();
          yPos = 0.25;
        } else {
          yPos += yIncr;
        }
        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(textLines, margin, yPos + margin + oneLineHeight);
        yPos += yIncr * (textLines.length - 3);

        // Get image width and height from the bounding rect object
        const rect = ann.position.boundingRect;
        const imageHeight = Math.abs(rect.y2 - rect.y1);
        const imageWidth = Math.abs(rect.x2 - rect.x1);
        var scaledHeight = imageHeight / ptsPerInch;
        var scaledWidth = imageWidth / ptsPerInch;
        const maxDimension = 6; // inches
        if (scaledWidth > maxDimension && scaledWidth > scaledHeight) {
          scaledWidth = maxDimension;
          scaledHeight = (scaledWidth * imageHeight) / imageWidth;
        }
        if (scaledHeight > maxDimension && scaledHeight > scaledWidth) {
          scaledHeight = maxDimension;
          scaledWidth = (scaledHeight * imageWidth) / imageHeight;
        }
        if (yPos + scaledHeight > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.addImage(
          ann["content"]["image"],
          "PNG",
          margin,
          yPos + margin + oneLineHeight,
          scaledWidth,
          scaledHeight
        );

        pdf.setLineWidth(0.01);
        pdf.setDrawColor(150);
        pdf.rect(
          margin,
          yPos + margin + oneLineHeight,
          scaledWidth,
          scaledHeight
        );
        yPos += scaledHeight;
        yPos += yIncr;
        if (yPos + scaledHeight > 10) {
          pdf.addPage();
          yPos = 0.25;
        }

        if (ann["comment"]["text"] !== "") {
          str = "My comment: " + ann["comment"]["text"] + "\n";
          var textLines = pdf.splitTextToSize(str, maxLineWidth);
          if (yPos + yIncr * (textLines.length - 2) > 10) {
            pdf.addPage();
            yPos = 0.25;
          } else {
            yPos += yIncr;
          }
          if (yPos > 10) {
            pdf.addPage();
            yPos = 0.25;
          }
          pdf.text(textLines, margin, yPos + margin + oneLineHeight);
          yPos += yIncr * (textLines.length - 2);
        }
      }
    }

    // Done
    yPos += yIncr;

    pdf.text("--- End of report ---", margin, yPos + margin + oneLineHeight);

    // Save the report

    var month = parseInt(dateObj.getMonth()) + 1;
    var day = parseInt(dateObj.getDate());
    var year = dateObj.getFullYear();
    var outputFileName =
      "KG_Annotations_" +
      (month < 10 ? "0" + month : month) +
      "_" +
      (day < 10 ? "0" + day : day) +
      "_" +
      year +
      ".pdf";
    pdf.save(outputFileName);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        {/* <div className={classes.search_widget}>
          <SearchWidget callbackUpdateResults={handleSearchResults} resetSearch={resetSearch}/>
        </div> */}
        <div className={classes.filter_container}>
          <div className={classes.filter_options}>
            <div className={classes.filter_by_context}>
              <SelectionWidget
                filterOption={filterOption}
                itemList={contextLabels}
                callbackItemSelection={handleThemeSelection}
              />
            </div>
            <div className={classes.filter_icons}>
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>
                      <strong> View Favorite Annotations</strong>
                      <br />
                      <br />
                      Mark annotations as favorite when creating a new
                      annotation or by editing existing annotation in the
                      Annotations panel in the document view.
                    </Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton onClick={handleFilterByFavoriteNotes}>
                    <Badge
                      badgeContent={numFavoriteAnnotations}
                      color="secondary"
                      overlap="rectangular"
                    >
                      <IconStar
                        style={{
                          color:
                            filterOption === "KG_FILTER_FAVORITE"
                              ? "purple"
                              : "",
                        }}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>
                      <strong> View Follow-up Annotations</strong>
                      <br />
                      <br />
                      Mark annotations as follow-up when creating a new
                      annotation or by editing existing annotation in the
                      Annotations panel in the document view.
                    </Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton onClick={handleFilterByNotesWithReminders}>
                    <Badge
                      badgeContent={numTodoAnnotations}
                      color="secondary"
                      overlap="rectangular"
                    >
                      <IconToDo
                        style={{
                          color:
                            filterOption === "KG_FILTER_TODO" ? "purple" : "",
                        }}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={classes.filter_by_container}>
            <span className={classes.filter_by_docs_caption}>Filter by</span>
            <Tooltip
              arrow
              placement="top"
              title={
                <Typography fontSize={16}>
                  Filter annotations by documents
                </Typography>
              }
              enterDelay={1000}
              leaveDelay={200}
              enterNextDelay={500}
            >
              <IconButton onClick={handleShowDocsAndFolderSelectorDialog}>
                <Badge
                  badgeContent={docsSelected.length}
                  color="primary"
                  overlap="rectangular"
                >
                  <FilterIcon
                    style={{
                      color:
                        filterOption === "KG_FILTER_DOCS_AND_FOLDERS"
                          ? "purple"
                          : "",
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.filter_info}>
          <div>
            <span className="filter_info_caption">Filters</span>{" "}
            {filterOption === "KG_FILTER_CONTEXT" &&
            filterContextData &&
            filterContextData["contextName"] ? (
              <Chip
                label={`${filterContextData["contextName"]}`}
                variant="outlined"
              />
            ) : filterOption === "KG_FILTER_FAVORITE" ? (
              <Chip label={"Favorites"} variant="outlined" />
            ) : filterOption === "KG_FILTER_TODO" ? (
              <Chip label={"Follow-Ups"} variant="outlined" />
            ) : filterOption === "KG_FILTER_SEARCH" ? (
              <Chip
                label={
                  searchQuery.length > 10
                    ? searchQuery.substring(0, 15) + ".."
                    : searchQuery
                }
              />
            ) : filterOption === "" ? (
              ""
            ) : (
              ""
            )}
            {docsSelected.length > 0 ? (
              <>
                {" "}
                <Chip
                  label={"Documents selected"}
                  variant="outlined"
                  onDelete={handleClearDocsFilter}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            {
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>
                    Download filtered annotations in PDF format
                  </Typography>
                }
                enterDelay={1000}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <span>
                  <IconButton
                    disabled={allAnnotationsView.length > 0 ? false : true}
                    onClick={handleDownloadSelectedAnnotations}
                  >
                    <DownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>
            }
          </div>
        </div>
        {allAnnotationsView.length === 0 ? (
          <div className={classes.empty_library_message}>
            <div>
              <p>
                <img src={EmptyIllustration} width="100px" alt="Image" />
              </p>
              <div>
                <p>
                  Nothing to show.
                  <br />
                  {filterOption === "KG_FILTER_CONTEXT"
                    ? "No annotation tagged with selected context."
                    : filterOption === "KG_FILTER_FAVORITE"
                    ? "No annotations tagged as favorite."
                    : filterOption === "KG_FILTER_TODO"
                    ? "No annotations tagged as to-do."
                    : filterOption === ""
                    ? "Select a filter option above."
                    : ""}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.annotations_panel}>
            {allAnnotationsView.length > 0
              ? allAnnotationsView.map((a, i) => (
                  <AnnotationCard
                    key={i}
                    annotation={a}
                    context={a.context}
                    docId={a._doc}
                    deleteHighlight={null}
                    disableActions={true}
                    themeColors={getContextColor(a)}
                    truncateText={true}
                    viewOutsideDocument={true}
                    visitHighlight={null}
                  />
                ))
              : ""}
          </div>
        )}
      </div>
      {true ? (
        <DocsAndFolderSelector
          callbackCancel={handleCloseDocsAndFolderSelector}
          callbackSubmit={handleSubmitDocsAndFolderSelector}
          contextKey={"KG_ANNOTATION_THEME_DEFAULT"}
          openDialog={showDocsAndFolderSelector}
          proceedButtonText={"Continue"}
          title={"Select to filter by documents"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterAnnotations;
