const PromoMessages = {
  splash_screen: {
    carousel_messages: [
      {
        text: "Gather insights as you seek new knowledge.",
      },
      {
        text: "Recall information better through contextual notes.",
      },
      {
        text: "Explore documents via text mining and visual analytics.",
      },
      {
        // text: "Build it. Curate it. Watch it blossom.",
        text: "Discover. Link information. Watch your knowledge blossom.",
      },
    ],
  },
};

export default PromoMessages;
