import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
const SelectionWidget = ({ filterOption, itemList, callbackItemSelection }) => {
  const [selectedItem, setSelectedItem] = useState("None");
  const handleChange = (event, index) => {
    setSelectedItem(event.target.value);
    const tokens = event.target.value.split("~~");
    if (tokens.length === 2)
      callbackItemSelection({ themeKey: tokens[0], contextName: tokens[1] });
  };
  const ThemeList = ({ themeContexts, themeKey }) => {
    return (
      <>
        {themeContexts.map((c, i) => (
          <option key={i} value={`${themeKey}~~${c.label}`}>
            {c.label}{" "}{c.count > 0 ? `(${c.count})` : ""}
          </option>
        ))}
      </>
    );
  };
  if (!itemList) return <></>;
  return (
    <div>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-standard-label">
          Filter annotations by context
        </InputLabel> */}
        <Select
          native
          id="select-context"
          onChange={handleChange}
          style={{ fontSize: "smaller" }}
          value={filterOption !== "KG_FILTER_CONTEXT" ? "None" : selectedItem}
        >
          <option value={"None"}>Select context</option>
          {itemList.map((c, i) => (
            <optgroup key={i} id={i} label={c.label}>
              <ThemeList themeContexts={c.contexts} themeKey={c.themeKey} />
            </optgroup>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectionWidget;
