import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import KGGuideStepper from "../UserGuide/QuickGuide";
import QuickTourIcon from "@mui/icons-material/LocalLibraryOutlined";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
}));

const AppHelpGuidePrompt = ({
  cancelButtonText,
  callbackCancel,
  callbackShowUserGuide,
  openDialog,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Dialog
        fullWidth={false}
        maxWidth={"md"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ color: "gray", fontSize: "1em" }}>
            <QuickTourIcon /> &nbsp;Quick Tour
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <KGGuideStepper />
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            onClick={callbackShowUserGuide}
          >
            Open User Guide
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={(event, reason) => callbackCancel(event, reason)}
          >
            {cancelButtonText || "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppHelpGuidePrompt;
