import { makeStyles, Tooltip, withStyles } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ImageIcon from "@mui/icons-material/ImageOutlined";
import NotesIcon from "@mui/icons-material/ArticleOutlined";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TinyBarChart from "../../Visualizations/TinyBarChart";
import AnnotationCard from "./AnnotationCard";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#fafafa",
  },
  annotations: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  annotation_types: {
    color: "#888888",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transform: "scale(0.875)",
  },
  title_bar: {
    backgroundColor: "#f0f0f0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "3px",
  },
  bar_chart: {
    // border: "1px solid #00000000",
    borderRadius: "4px",
    // padding: "2px",
    "&:hover": {
      backgroundColor: "#efefef",
    },
  },
  bar_chart_tooltip_content: {
    backgroundColor: "#22222266",
    display: "flex",
    justifyContent: "left",
    padding: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  spacer: {
    width: "25px",
  },
}));
const PageAnnotations = ({
  contextColors,
  // contextTheme,
  data,
  docId,
  deleteHighlight,
  maxYVal,
  pageNumberIndex,
  pageWiseAnnotations,
  themeColors,
  themeKey,
  visitHighlight,
}) => {
  const classes = useStyles();
  const userContexts = useSelector((state) => state.users.contexts);
  const contextTheme = userContexts.hasOwnProperty(themeKey)
    ? userContexts[themeKey]
    : userContexts["KG_ANNOTATION_THEME_DEFAULT"];

  const getContext = (contextId) => {
    const context = contextTheme.contexts.find(
      (c) => c.contextId === contextId
    );
    return context || null;
  };

  const getContextLabel = (contextId) => {
    const context = contextTheme.contexts.find(
      (c) => c.contextId === contextId
    );
    return context ? context.label : "No label";
  };

  const [expanded, setExpanded] = React.useState();

  var maxCategoryCount = -1;
  if (
    pageWiseAnnotations &&
    pageWiseAnnotations.pages &&
    pageNumberIndex < pageWiseAnnotations.pages.length
  ) {
    pageWiseAnnotations.pages[pageNumberIndex].ctxCounts.forEach((c) => {
      maxCategoryCount = c.val > maxCategoryCount ? maxCategoryCount : c.val;
    });
  }

  var numImageNotes = 0;
  var numTextNotes = 0;
  data.annotations.forEach((a) => {
    // console.log("Ann>", a);
    if (a.content.text) numTextNotes++;
    else if (a.content.image) numImageNotes++;
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "3px",
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  return (
    <div>
      <Accordion
        className={classes.wrapper}
        expanded={expanded}
        onChange={handleChange(!expanded)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Typography>Page {data.pageNumber}</Typography>
            </div>
            <div className={classes.annotation_types}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>Number of text notes</Typography>
                  }
                  enterDelay={200}
                  leaveDelay={200}
                  enterNextDelay={500}
                  style={{ maxWidth: "50px" }}
                >
                  <div>
                    {numTextNotes} <NotesIcon />
                  </div>
                </Tooltip>
              </div>
              <div className={classes.spacer}></div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>Number of image notes</Typography>
                  }
                  enterDelay={200}
                  leaveDelay={200}
                  enterNextDelay={500}
                  style={{ maxWidth: "50px" }}
                >
                  <div>
                    {numImageNotes} <ImageIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              {pageWiseAnnotations ? (
                <div className={classes.bar_chart}>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      <Typography fontSize={16}>
                        Number of annotations by context
                        {pageWiseAnnotations.pages[pageNumberIndex]
                          ? pageWiseAnnotations.pages[
                              pageNumberIndex
                            ].ctxCounts.map((s, i) => (
                              <span
                                key={i}
                                className={classes.bar_chart_tooltip_content}
                              >
                                <span>{s.val}</span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span>{getContextLabel(s.ctx)}</span>
                              </span>
                            ))
                          : ""}
                      </Typography>
                    }
                    enterDelay={500}
                    leaveDelay={100}
                    enterNextDelay={400}
                  >
                    <div className={classes.bar_chart}>
                      <TinyBarChart
                        width={60}
                        height={40}
                        margin={{
                          top: 5,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        id={pageWiseAnnotations.pg}
                        data={
                          pageWiseAnnotations.pages &&
                          pageWiseAnnotations.pages[pageNumberIndex]
                            ? pageWiseAnnotations.pages[pageNumberIndex]
                                .ctxCounts
                            : []
                        }
                        contextColors={contextColors}
                        maxYVal={maxYVal}
                        showYVal={true}
                      />
                    </div>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {data.annotations.map((a, i) => (
            <AnnotationCard
              annotation={a}
              context={getContext(a.context.contextId)}
              docId={docId}
              deleteHighlight={deleteHighlight}
              disableActions={false}
              themeColors={themeColors}
              visitHighlight={visitHighlight}
              key={i}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PageAnnotations;
