import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import ContextColorLegend from "@mui/icons-material/SquareRounded";
import EditThemeIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import ContextEditorDialog from "../Views/ContextEditor/ContextEditorDialog";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "5px",
  },
  theme_legend: {
    display: "flex",
    alignItems: "center",
  },
  theme_legend_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "smaller",
    marginLeft: "10px",
    marginRight: "10px",
  },
  theme_options_editcreate: {
    color: theme.palette.primary.main,
  },
  theme_options_editcreate_icon: {
    fontSize: "0.8em",
  },
}));
const DocumentContext = ({
  doc,
  callbackSetDocTheme,
  themeColors,
  themeKey,
}) => {
  const classes = useStyles();
  const userContexts = useSelector((state) => state.users.contexts);
  const [showContextEditor, setShowContextEditor] = useState(false);

  var contextList = [];
  const contextKeys = Object.keys(userContexts);
  contextKeys.sort((a, b) => {
    if (userContexts[a].name > userContexts[b].name) return 1;
    else if (userContexts[a].name < userContexts[b].name) return -1;
    return 0;
  });
  contextKeys.forEach((k) => {
    contextList.push(userContexts[k]);
  });
  const handleCloseContextEditor = () => {
    setShowContextEditor(false);
  };

  const handleContextChange = (event) => {
    const oldKey = themeKey;
    themeKey = event.target.value;
    if (themeKey === "KG_THEME_EDIT_OR_CREATE") {
      setShowContextEditor(true);
    } else {
      callbackSetDocTheme({ newKey: event.target.value, oldKey: oldKey });
    }
  };

  if (!themeColors) return <></>;

  const docTheme = userContexts[themeKey]
    ? userContexts[themeKey]
    : userContexts["KG_ANNOTATION_THEME_DEFAULT"];

  const themeSelected = userContexts[themeKey]
    ? themeKey
    : "KG_ANNOTATION_THEME_DEFAULT";

  return (
    <div className={classes.wrapper}>
      <FormControl
        variant="standard"
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          minWidth: "120px",
          width: "200px",
        }}
      >
        <InputLabel id={themeSelected}>Select Context Theme</InputLabel>
        <Select
          id="select"
          labelId="select-label"
          onChange={handleContextChange}
          value={themeSelected}
        >
          {contextList.map((c, i) => (
            <MenuItem key={i} value={contextKeys[i]}>
              {c.name}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            key={contextList.length + 1}
            className={classes.theme_options_editcreate}
            value="KG_THEME_EDIT_OR_CREATE"
          >
            <Tooltip
              arrow
              enterDelay={500}
              enterNextDelay={200}
              leaveDelay={200}
              placement="right"
              title={
                <Typography fontSize={16}>
                  Open the context theme editor to create or edit the labels and
                  color codes for a context theme.
                </Typography>
              }
            >
              <div>
                <EditThemeIcon fontSize="small" />
                &nbsp; Create or Edit Theme
              </div>
            </Tooltip>
          </MenuItem>
        </Select>
      </FormControl>
      <div className={classes.theme_legend}>
        {docTheme.contexts.map((c, i) => (
          <div key={i} className={classes.theme_legend_item}>
            <ContextColorLegend
              style={{ color: themeColors[c.contextId].colorMain }}
            />
            {c.label}
          </div>
        ))}
      </div>
      {showContextEditor ? (
        <ContextEditorDialog
          callbackCancel={handleCloseContextEditor}
          contextKey={"KG_ANNOTATION_THEME_DEFAULT"}
          openDialog={showContextEditor}
          proceedButtonText={"Save Theme"}
          title={"Create or Edit Context Themes"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DocumentContext;
