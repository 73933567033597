import { mutate, query } from "./api";
import InMemoryJWTManager from "./JWTManager";

const userdataApiGetAllUsers = (userData) => {
  return query("/user/all", userData);
};

const userdataApiAddUserData = (userData) => {
  return mutate("/userdata/add", userData);
};

const userdataApiGetUserData = () => {
  return query(`/userdata/${InMemoryJWTManager.getUserId()}`);
};

const userdataApiAddNewContextTheme = (data) => {
  return mutate("/userdata/addtheme", data);
};

const userdataApiUpdateContextTheme = (data) => {
  return mutate("/userdata/updatetheme", data);
};

const userdataApiDeleteContextTheme = (data) => {
  return mutate("/userdata/deletetheme", data);
};

const userdataApiRemoveuser = (user) => {
  return mutate(`/user/remove/${user.id}`);
};

const docsApiReportUserIssue = (user) => {
  return mutate("/userdata/report_issue", user);
};

const docsApiGetUserIssues = (data) => {
  return query("/userdata/get_all_issues");
};

const userdataApiUpdateSetting = (data) => {
  return mutate("/userdata/updatesettings", data);
}

const UserDataAPI = {
  userdataApiGetAllUsers,
  userdataApiAddUserData,
  userdataApiGetUserData,
  userdataApiAddNewContextTheme,
  userdataApiDeleteContextTheme,
  userdataApiUpdateContextTheme,
  userdataApiRemoveuser,
  userdataApiUpdateSetting,
  docsApiGetUserIssues,
  docsApiReportUserIssue,
};

export default UserDataAPI;
