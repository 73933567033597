import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../UIWidgets/AlertDialog";
import SortIcon from "@mui/icons-material/Sort";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../../store/SliceUsers";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@material-ui/core";

const AdminUserView = ({ users, guest }) => {
  const dispatch = useDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [usersToShow, setUsersToShow] = React.useState([]);
  const [userToDelete, setUserToDelete] = React.useState({});
  const [sortMode, setSortMode] = React.useState("KG_SORT_ASCENDING_CREATED");
  const contexts = useSelector((state) => state.users.contexts);

  React.useEffect(() => {
    if (users.length <= 0) setShowProgress(true);
    else setShowProgress(false);

    // Create a list of users based on whether they are guest
    var userList = [];
    if (guest) {
      userList = users.filter((u) => u.is_guest);
    } else {
      userList = users.filter((u) => !u.is_guest);
    }
    userList.forEach((user) => {
      user.utc = Date.parse(user.created);
    });
    setUsersToShow(userList);
  }, [users]);
  const callbackCancel = () => {
    setShowDeleteDialog(false);
  };
  const callbackProceed = (data) => {
    setShowDeleteDialog(false);
    handleDeleteUser(data.id);
  };
  const prepareToDeleteUser = (userId) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };
  const handleDeleteUser = (userId) => {
    dispatch(removeUser({ id: userId })).then((resp) => {
      const newUserList = usersToShow.filter((u) => u.id !== userId);
      setUsersToShow(newUserList);
    });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleSortByCreatedDate = () => {
    var userList = [];

    if (guest) {
      userList = users.filter((u) => u.is_guest);
    } else {
      userList = users.filter((u) => !u.is_guest);
    }
    userList = userList.sort((a, b) => {
      if (a.utc < b.utc)
        return sortMode === "KG_SORT_ASCENDING_CREATED" ? 1 : -1;
      else if (a.utc > b.utc)
        return sortMode === "KG_SORT_ASCENDING_CREATED" ? -1 : 1;
      else return 0;
    });

    if (sortMode === "KG_SORT_ASCENDING_CREATED")
      setSortMode("KG_SORT_DESCENDING_CREATED");
    else if (sortMode === "KG_SORT_DESCENDING_CREATED")
      setSortMode("KG_SORT_ASCENDING_CREATED");
    else setSortMode("KG_SORT_ASCENDING_CREATED");
    setUsersToShow(userList);
  };
  const handleSortByNumDocs = () => {
    var userList = [];

    if (guest) {
      userList = users.filter((u) => u.is_guest);
    } else {
      userList = users.filter((u) => !u.is_guest);
    }
    userList = userList.sort((a, b) => {
      if (a.docs.length < b.docs.length)
        return sortMode === "KG_SORT_ASCENDING_NUM_DOCS" ? 1 : -1;
      else if (a.docs.length > b.docs.length)
        return sortMode === "KG_SORT_ASCENDING_NUM_DOCS" ? -1 : 1;
      else return 0;
    });

    if (sortMode === "KG_SORT_ASCENDING_NUM_DOCS")
      setSortMode("KG_SORT_DESCENDING_NUM_DOCS");
    else if (sortMode === "KG_SORT_DESCENDING_NUM_DOCS")
      setSortMode("KG_SORT_ASCENDING_NUM_DOCS");
    else setSortMode("KG_SORT_ASCENDING_NUM_DOCS");
    setUsersToShow(userList);
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          dense="true"
          table="true"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              {/* <StyledTableCell align="left">Email</StyledTableCell> */}
              <StyledTableCell align="left">Last Login</StyledTableCell>
              <StyledTableCell align="left">
                Created
                <IconButton
                  onClick={handleSortByCreatedDate}
                  style={{ color: "white" }}
                >
                  <SortIcon />
                </IconButton>
              </StyledTableCell>
              <StyledTableCell align="center">
                Num. Docs
                <IconButton
                  onClick={handleSortByNumDocs}
                  style={{ color: "white" }}
                >
                  <SortIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToShow.map((user) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell>
                  <IconButton
                    onClick={() => prepareToDeleteUser(user)}
                    style={{ color: "salmon" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {user.name}
                </StyledTableCell>
                {/* <StyledTableCell align="left">{user.email}</StyledTableCell> */}
                <StyledTableCell align="left">
                  {user.logins ? user.logins[user.logins.length - 1] : "-"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {user.created !== "" ? user.created : "-"}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {user.docs.length > 0 ? user.docs.length : ""}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showProgress ? (
        <CircularProgress size={25} style={{ padding: "20px" }} />
      ) : (
        ""
      )}
      <AlertDialog
        callbackCancel={callbackCancel}
        callbackProceed={() => callbackProceed(userToDelete)}
        contentText={`The user "${userToDelete.name}" and their data will be deleted permanently. Proceed with caution!`}
        openDialog={showDeleteDialog}
        proceedButtonText={"Remove User"}
        title={"Remove User"}
      />
    </div>
  );
};

export default AdminUserView;
