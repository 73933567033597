import { Button, makeStyles, Tooltip, Typography } from "@material-ui/core";
import IconCheck from "@mui/icons-material/Check";
import IconDelete from "@mui/icons-material/Delete";
import IconEdit from "@mui/icons-material/Edit";
import IconToDoSet from "@mui/icons-material/NotificationAddOutlined";
import IconToDoUnset from "@mui/icons-material/NotificationsActiveRounded";
import IconStarUnset from "@mui/icons-material/StarOutlineRounded";
import IconStar from "@mui/icons-material/StarRateRounded";
import { useDispatch } from "react-redux";
import {
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  updateAnnotation,
} from "../../../store/SliceAnnotations";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    "&.disabled": { pointerEvents: "none" },
    paddingBottom: "5px",
    paddingTop: "10px",
    width: "100%",
  },
  annotation_actions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100px",
  },
  annotation_save_actions: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "10px",
    width: "140px",
  },
  svg_icons: {
    cursor: "pointer",
    borderRadius: "50px",
    padding: "3px",
    transform: "scale(.8)",
    "&:hover": {
      background: "#efefef",
    },
    "&.set": {
      color: theme.palette.primary.main,
    },
    "&.delete": {
      color: "salmon",
    },
  },
}));
const AnnotationActions = ({
  annotation,
  callbackDeleteCard,
  disableActions,
  editMode,
  callbackEditAnnotation,
  callbackSaveAnnotation,
  callbackCancelEdit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tooltipsEnterDelay = 1000; // Seconds

  const handleFavoriteSet = () => {
    const newAnnotation = {
      ...annotation,
      comment: { ...annotation.comment, star: true },
    };
    dispatch(updateAnnotation(newAnnotation)).then((data) => {
      dispatch(getAllFavoriteAnnotations());
    });
  };
  const handleFavoriteUnset = () => {
    const newAnnotation = {
      ...annotation,
      comment: { ...annotation.comment, star: false },
    };
    dispatch(updateAnnotation(newAnnotation)).then((data) => {
      dispatch(getAllFavoriteAnnotations());
    });
  };
  const handleTodoSet = () => {
    const newAnnotation = {
      ...annotation,
      comment: { ...annotation.comment, todo: true },
    };
    dispatch(updateAnnotation(newAnnotation)).then((data) => {
      dispatch(getAllTodoAnnotations());
    });
  };
  const handleTodoUnset = () => {
    const newAnnotation = {
      ...annotation,
      comment: { ...annotation.comment, todo: false },
    };
    dispatch(updateAnnotation(newAnnotation)).then((data) => {
      dispatch(getAllTodoAnnotations());
    });
  };
  const handleDelete = () => {
    callbackDeleteCard();
  };

  return (
    <div className={`${classes.wrapper} ${disableActions ? "disabled" : ""}`}>
      <div
        className={classes.annotation_actions}
        style={{ justifyContent: disableActions ? "flex-end" : "" }}
      >
        <div>
          <Tooltip
            arrow
            placement="top"
            title={
              <Typography fontSize={16}>
                Add or remove a follow-up reminder
              </Typography>
            }
            enterDelay={tooltipsEnterDelay}
            leaveDelay={200}
            enterNextDelay={500}
          >
            {annotation.comment.todo ? (
              <IconToDoUnset
                className={`${classes.svg_icons} set`}
                style={{ fontSize: "1.75em" }}
                onClick={handleTodoUnset}
              />
            ) : (
              <IconToDoSet
                className={classes.svg_icons}
                style={{ fontSize: "1.75em" }}
                onClick={handleTodoSet}
              />
            )}
          </Tooltip>
        </div>
        <div>
          <Tooltip
            arrow
            placement="top"
            title={
              <Typography fontSize={16}>Add or remove as favorite</Typography>
            }
            enterDelay={tooltipsEnterDelay}
            leaveDelay={200}
            enterNextDelay={500}
          >
            {annotation.comment.star ? (
              <IconStar
                className={`${classes.svg_icons} set`}
                style={{ fontSize: "1.75em" }}
                onClick={handleFavoriteUnset}
              />
            ) : (
              <IconStarUnset
                className={classes.svg_icons}
                style={{ fontSize: "1.75em" }}
                onClick={handleFavoriteSet}
              />
            )}
          </Tooltip>
        </div>

        {!disableActions ? (
          <div>
            {editMode ? (
              <div className={classes.annotation_save_actions}>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={callbackCancelEdit}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={callbackSaveAnnotation}
                >
                  Save
                </Button>
              </div>
            ) : (
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>Edit the annotation</Typography>
                }
                enterDelay={tooltipsEnterDelay}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <IconEdit
                  className={`${classes.svg_icons} `}
                  style={{ fontSize: "1.45em" }}
                  onClick={callbackEditAnnotation}
                />
              </Tooltip>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div>&nbsp;&nbsp;</div>
      <div className={classes.annotation_delete}>
        {!disableActions ? (
          <div>
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={16}>Delete forever</Typography>}
              enterDelay={1000}
              leaveDelay={200}
              enterNextDelay={500}
            >
              <IconDelete
                className={`${classes.svg_icons} delete`}
                onClick={handleDelete}
              />
            </Tooltip>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AnnotationActions;
