import React, { PureComponent } from "react";
import { Treemap, ResponsiveContainer } from "recharts";
import { Box, Grid, Typography, useTheme, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnnotationsForSearchQuery } from "../../../store/SliceAnnotations";
import AnnotationCard from "./AnnotationCard";
import SearchWidget from "../Search/SearchWidget";
import FilterAnnotations from "./FilterAnnotations";
import { getDocumentsForSearchQuery } from "../../../store/SliceDocuments";
import DocumentCard from "../DocumentView/DocumentCard";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  dashboard_container: {
    // display: "flex",
    flexDirection: "row",
    margin: 0,
    width: "33%",
  },
  dashboard_header: {
    paddingBottom: "10px",
  },
  dashboard_filter_container: {
    borderRight: "1px solid gray",
    flexGrow: "0",
    padding: "3px",
  },
  dashboard_visualization: {
    flexGrow: "1",
    padding: "3px",
  },
  annotations_results_count: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  annotations_section: {
    marginBottom: 30,
  },
  annotations_doc_header: {
    padding: 15,
    // paddingTop: 15,
    // paddingBottom: 10,
    fontWeight: 600,
  },
  annotation_context: {
    backgroundColor: theme.palette.primary.lightest,
    borderRadius: 12,
    padding: 5,
    marginBottom: 10,
    marginTop: 20,
    // paddingTop: 15,
    // paddingBottom: 10,
    fontWeight: 600,
  },
  annotations_results: {
    paddingLeft: 50,
  },
}));

const AnnotationsDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const globalColorSet = globalTheme.kg_ui.context_theme.colors;
  const [numberOfAnnotations, setNumberOfAnnotations] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [annotationsFound, setAnnotationsFound] = useState([]);
  const [documentsFound, setDocumentsFound] = useState([]);
  const [treemapData, setTreemapData] = useState([
    { name: "Empty", children: [] },
  ]);
  const userContexts = useSelector((state) => state.users.contexts);

  const sample_data = [
    {
      name: "axis",
      children: [
        { name: "Axes", size: 1302 },
        { name: "Axis", size: 24593 },
        { name: "AxisGridLine", size: 652 },
        { name: "AxisLabel", size: 636 },
        { name: "CartesianAxes", size: 6703 },
      ],
    },
    {
      name: "controls",
      children: [
        { name: "AnchorControl", size: 2138 },
        { name: "ClickControl", size: 3824 },
        { name: "Control", size: 1353 },
        { name: "ControlList", size: 4665 },
        { name: "DragControl", size: 2649 },
        { name: "ExpandControl", size: 2832 },
        { name: "HoverControl", size: 4896 },
        { name: "IControl", size: 763 },
        { name: "PanZoomControl", size: 5222 },
        { name: "SelectionControl", size: 7862 },
        { name: "TooltipControl", size: 8435 },
      ],
    },
    {
      name: "data",
      children: [
        { name: "Data", size: 20544 },
        { name: "DataList", size: 19788 },
        { name: "DataSprite", size: 10349 },
        { name: "EdgeSprite", size: 3301 },
        { name: "NodeSprite", size: 19382 },
        {
          name: "render",
          children: [
            { name: "ArrowType", size: 698 },
            { name: "EdgeRenderer", size: 5569 },
            { name: "IRenderer", size: 353 },
            { name: "ShapeRenderer", size: 2247 },
          ],
        },
        { name: "ScaleBinding", size: 11275 },
        { name: "Tree", size: 7147 },
        { name: "TreeBuilder", size: 9930 },
      ],
    },
    {
      name: "events",
      children: [
        { name: "DataEvent", size: 7313 },
        { name: "SelectionEvent", size: 6880 },
        { name: "TooltipEvent", size: 3701 },
        { name: "VisualizationEvent", size: 2117 },
      ],
    },
    {
      name: "legend",
      children: [
        { name: "Legend", size: 20859 },
        { name: "LegendItem", size: 4614 },
        { name: "LegendRange", size: 10530 },
      ],
    },
    {
      name: "operator",
      children: [
        {
          name: "distortion",
          children: [
            { name: "BifocalDistortion", size: 4461 },
            { name: "Distortion", size: 6314 },
            { name: "FisheyeDistortion", size: 3444 },
          ],
        },
        {
          name: "encoder",
          children: [
            { name: "ColorEncoder", size: 3179 },
            { name: "Encoder", size: 4060 },
            { name: "PropertyEncoder", size: 4138 },
            { name: "ShapeEncoder", size: 1690 },
            { name: "SizeEncoder", size: 1830 },
          ],
        },
        {
          name: "filter",
          children: [
            { name: "FisheyeTreeFilter", size: 5219 },
            { name: "GraphDistanceFilter", size: 3165 },
            { name: "VisibilityFilter", size: 3509 },
          ],
        },
        { name: "IOperator", size: 1286 },
        {
          name: "label",
          children: [
            { name: "Labeler", size: 9956 },
            { name: "RadialLabeler", size: 3899 },
            { name: "StackedAreaLabeler", size: 3202 },
          ],
        },
        {
          name: "layout",
          children: [
            { name: "AxisLayout", size: 6725 },
            { name: "BundledEdgeRouter", size: 3727 },
            { name: "CircleLayout", size: 9317 },
            { name: "CirclePackingLayout", size: 12003 },
            { name: "DendrogramLayout", size: 4853 },
            { name: "ForceDirectedLayout", size: 8411 },
            { name: "IcicleTreeLayout", size: 4864 },
            { name: "IndentedTreeLayout", size: 3174 },
            { name: "Layout", size: 7881 },
            { name: "NodeLinkTreeLayout", size: 12870 },
            { name: "PieLayout", size: 2728 },
            { name: "RadialTreeLayout", size: 12348 },
            { name: "RandomLayout", size: 870 },
            { name: "StackedAreaLayout", size: 9121 },
            { name: "TreeMapLayout", size: 9191 },
          ],
        },
        { name: "Operator", size: 2490 },
        { name: "OperatorList", size: 5248 },
        { name: "OperatorSequence", size: 4190 },
        { name: "OperatorSwitch", size: 2581 },
        { name: "SortOperator", size: 2023 },
      ],
    },
  ];

  const getContextColor = (annotation) => {
    const globalColorSet = globalTheme.kg_ui.context_theme.colors;
    const themeId = annotation.context.themeId;
    var contextColors = null;
    if (userContexts[themeId]) {
      contextColors = {};
      userContexts[themeId].contexts.forEach((c) => {
        const color = globalColorSet.find((clr) => clr.id === c.colorId);
        if (color) {
          contextColors[c.contextId] = { ...color };
        }
      });
    }
    return contextColors;
  };

  const getColor = (colorId) => {
    // console.log("input color: ", colorId);
    // console.log("color set", globalColorSet);
    for (var i = 0; i < globalColorSet.length; i++) {
      if (globalColorSet[i].id === colorId) return globalColorSet[i].colorMain;
    }
    return "#333333";
  };
  const handleSearchResults = (data) => {
    if (data === "") {
      setAnnotationsFound([]);
      setDocumentsFound([]);
      setNumberOfAnnotations(0);
      setTreemapData([{ name: "Empty", children: [] }]);
    }
    // else if (data === searchQuery) {
    // }
    else {
      setAnnotationsFound("KG_SEARCH_BUSY");
      setSearchQuery(data);
      //   dispatch(getDocumentsForSearchQuery({ searchQuery: data })).then(
      //     (result) => {
      //       console.log("Docs search> ", result);
      //       //   result.payload.search_results.forEach((doc) => {
      //       //     console.log(">", doc.name);
      //       //   });
      //       setDocumentsFound(result.payload.search_results);
      //     }
      //   );
      dispatch(getAnnotationsForSearchQuery({ searchQuery: data })).then(
        (result) => {
          if (result.payload.search_results) {

            var numAnnotations = 0;
            var annotations = [];
            result.payload.search_results.forEach((d) => {
              // var top_level = { name: d["doc"], children: [] };
              if (d["contexts"]) {
                d["contexts"].forEach((c) => {
                  // var second_level = { name: c["ctx"], children: [] };
                  if (c["annotations"]) {
                    numAnnotations += c["annotations"].length;
                    // c["annotations"].forEach((a, ai) => {
                    //   second_level["children"].push({
                    //     color: getColor(a["context"]["colorId"]),
                    //     name: "ann-" + ai,
                    //     size: 1,
                    //   });
                    // });
                  }
                  // top_level["children"].push(second_level);
                });
                // annotations.push(top_level);
              }
            });
            // console.log("Num: ", numAnnotations);
            // console.log(annotations);
            setNumberOfAnnotations(numAnnotations);
            setAnnotationsFound(result.payload.search_results);
            // setTreemapData(annotations);
          } else {
            setAnnotationsFound([]);
            setNumberOfAnnotations(0);
            setTreemapData([{ name: "Empty", children: [] }]);
          }
        }
      );
    }
  };
  const COLORS = [
    "#8889DD",
    "#9597E4",
    "#8DC77B",
    "#A5D297",
    "#E2CF45",
    "#F8C12D",
  ];

  class CustomizedContent extends PureComponent {
    render() {
      const {
        root,
        depth,
        x,
        y,
        width,
        height,
        index,
        payload,
        colors,
        rank,
        name,
        color,
      } = this.props;
    //   console.log("name: ", depth, name, color);
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            // style={{
            //   fill:
            //     depth < 2
            //       ? colors[Math.floor((index / root.children.length) * 6)]
            //       : "#ffffff00",
            //   stroke: "#fff",
            //   strokeWidth: 2 / (depth + 1e-10),
            //   strokeOpacity: 1 / (depth + 1e-10),
            // }}
            style={{
              fill: depth <= 2 ? "ffffff00" : color,
              stroke: "#fff",
              strokeWidth: 8 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
            }}
          />
          {depth === 2 ? (
            <text
              x={x + width / 2}
              y={y + height / 2 + 7}
              textAnchor="middle"
              fill="#fff"
              fontSize={14}
            >
              {name}
            </text>
          ) : null}
          {depth === 0 ? (
            <text
              x={x + 4}
              y={y + 18}
              fill="#fff"
              fontSize={16}
              fillOpacity={0.9}
            >
              {index + 1}
            </text>
          ) : null}
        </g>
      );
    }
  }
  return (
    <div>
      <div className={classes.dashboard_container}>
        <div>
          <SearchWidget callbackUpdateResults={handleSearchResults} />
        </div>
        <div className={classes.dashboard_visualization}>
          {/* <div>
            {annotationsFound !== "KG_SEARCH_BUSY" &&
              documentsFound.map((doc, i) => (
                <div key={i}>
                  <DocumentCard data={doc} />
                </div>
              ))}
            <hr />
          </div> */}

          <div className={classes.annotations_results_count}>
            {annotationsFound === "KG_SEARCH_BUSY"
              ? "Searching.."
              : `${numberOfAnnotations} Annotation(s) found`}
          </div>
          {/* <div
            style={{ border: "1px solid red", height: "500px", width: "500px" }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <Treemap
                width={600}
                height={600}
                data={treemapData}
                dataKey="size"
                aspectRatio={4 / 4}
                stroke="#fff"
                fill="#8884d8"
                content={<CustomizedContent colors={COLORS} />}
                isAnimationActive={false}
              />
            </ResponsiveContainer>
          </div> */}
          <div>
            {annotationsFound !== "KG_SEARCH_BUSY" &&
              annotationsFound.map((item, i) => (
                <div className={classes.annotations_section} key={i}>
                  <div className={classes.annotations_doc_header}>
                    {item["doc"]}
                  </div>
                  <div className="">
                    {item["contexts"].map((c, ci) => (
                      <div className="" key={ci}>
                        <div className={classes.annotation_context}>{c["ctx"]}</div>
                        {c["annotations"].map((a, ai) => (
                          <AnnotationCard
                            key={ai}
                            annotation={a}
                            context={a.context}
                            docId={a._doc}
                            deleteHighlight={null}
                            disableActions={true}
                            themeColors={getContextColor(a)}
                            truncateText={true}
                            viewOutsideDocument={true}
                            visitHighlight={null}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnotationsDashboard;
