import { mutate, query } from "./api";
import DocumentAPI from "./DocumentAPI";
import InMemoryJWTManager from "./JWTManager";
import { store } from "../store/store";
import {
  addFolder,
  setFileOrFolderOperationsInactive,
} from "../store/SliceFolders";
import { addDocs } from "../store/SliceDocuments";
import { setNotification } from "../store/GlobalStateSlice";

const folderApiAddFolder = (folderData) => {
  return mutate("/folder/add", folderData);
};

const folderApiChangeFolderName = (folderData) => {
  return mutate("/folder/change-name", folderData);
};

const folderApiGetFolderById = (folderId) => {
  return query(`/folder/${folderId}`);
};

const folderApiGetFolderDocs = (folderId) => {
  return query(`/folder/folder/${folderId}`);
};

const folderApiGetAllFolders = () => {
  return query("/folder/all");
};

const folderApiRemoveFolderById = (folderId) => {
  return query(`/folder/remove/${folderId}`);
};

const folderApiRemoveFoldersByIds = (folderIds) => {
  return mutate("/folder/remove_by_ids", folderIds);
};

const folderApiRemoveAllFolders = () => {
  return query("/folder/remove/all");
};

const folderApiUpdateFolder = (folderData) => {
  return mutate("/folder/update", folderData);
};

const folderApiCreateDefaultFolder = () => {
  return query("/folder/create-default");
};

const folderApiCreateFolder = (folderData) => {
  return mutate("/folder/create", folderData);
};

const folderApiUploadFilesToAFolder = async (data) => {
  if (!data.folder)
    throw new Error("Did not find folder while uploading files.");
  if (!data.files) throw new Error("Did not find files to upload.");
  try {
    // Create file uploading task to existing folder
    const fileUploadTask = DocumentAPI.docsApiUploadMultipleFiles(data.files);
    // Handle the completion of the file load task
    fileUploadTask
      .then((response) => {
        // Execute the promise in the response
        return response.json();
      })
      .then((json) => {
        // Extract data from the response - the data contains
        // the list of all files uploaded to the storage
        const uploadedFiles = json.data || null;
        if (uploadedFiles) {
          uploadedFiles.forEach((f) => {
            f.annotations = {
              contexts: {
                themeId: "KG_ANNOTATION_THEME_DEFAULT",
              },
              total: 0,
              type: {
                image: 0,
                text: 0,
              },
            };
            f.flags = {
              docParsed: false,
              favorite: false,
              shared: false,
            };
            f._folder = data.folder._id;
            f.metadata = {
              author: "",
              citation: "",
              year: "",
            };
            f.tags = [];
            f.userId = InMemoryJWTManager.getUserId();
          });

          const addDocsToDBTask = DocumentAPI.docsApiAddManyDocs(uploadedFiles);
          addDocsToDBTask.then((resp) => {
            if (data.newFolder) {
              store.dispatch(addFolder(data.folder));
            }
            if (resp.data.documents) {
              store.dispatch(addDocs({ documents: resp.data.documents }));
            }
            store.dispatch(setFileOrFolderOperationsInactive());
            const filesAddedMessage =
              data.files.length > 1
                ? `${data.files.length} Documents added to \"${data.folder.name}\"`
                : `1 Document added to \"${data.folder.name}\"`;
            store.dispatch(setNotification({ message: filesAddedMessage }));
          });
        }
      });
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      console.log(error.request);
    } else if (error.message) {
      console.log("Error", error.message);
    }
  }
  return;
};

const folderApiUploadFilesToNewFolder = async (data) => {
  if (!data.folder)
    throw new Error("Did not find folder while uploading files.");
  if (!data.files) throw new Error("Did not find files to upload.");
  try {
    // Create task to create a new default folder
    const resp = await folderApiCreateFolder(data.folder);

    // Handle the completion of creation of default folder
    const folder = resp.data.folder || null;
    if (folder) {
      folderApiUploadFilesToAFolder({
        folder: folder,
        files: data.files,
        newFolder: true,
      });
    }
  } catch (error) {
    console.log("Error: ", error);
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
    } else if (error.message) {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
    }
  }
};

const folderApiUploadFilesToNewDefaultFolder = async (data) => {
  try {
    // Create task to create a new default folder
    const resp = await folderApiCreateDefaultFolder();

    // Handle the completion of creation of default folder
    const folder = resp.data.folder || null;
    if (folder) {
      folderApiUploadFilesToAFolder({
        folder: folder,
        files: data,
        newFolder: true,
      });
    }
  } catch (error) {
    console.log("Error: ", error);
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
    } else if (error.message) {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
    }
  }
};

const FolderAPI = {
  //   folderApiUploadFiles,
  folderApiAddFolder,
  folderApiChangeFolderName,
  folderApiCreateFolder,
  folderApiCreateDefaultFolder,
  folderApiGetAllFolders,
  folderApiGetFolderById,
  folderApiGetFolderDocs,
  folderApiRemoveFolderById,
  folderApiRemoveFoldersByIds,
  folderApiRemoveAllFolders,
  folderApiUpdateFolder,
  folderApiUploadFilesToAFolder,
  folderApiUploadFilesToNewFolder,
  folderApiUploadFilesToNewDefaultFolder,
};

export default FolderAPI;
