import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import { Badge, IconButton, Typography } from "@material-ui/core";
import AdminContent from "./AdminContent";
import Tab from "react-bootstrap/Tab";
import { Nav } from "react-bootstrap";
import IssueReport from "./IssueReport";
import { getAllUsers, getUserIssues } from "../../../store/SliceUsers";
import { useDispatch } from "react-redux";
import AdminUserView from "./AdminUserView";
import AdminUsersGridView from "./AdminUsersGridView";

const AdminView = ({
  contentText,
  cancelButtonText,
  callbackCancel,
  callbackProceed,
  openDialog,
  title,
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState("TAB_ID_USERS");
  const [users, setUsers] = React.useState([]);
  const [issues, setIssues] = React.useState([]);
  const [numGuests, setNumGuests] = React.useState(0);
  const [numRegisteredUsers, setNumRegisteredUsers] = React.useState(0);
  React.useEffect(() => {
    dispatch(getAllUsers()).then((userData) => {
      dispatch(getUserIssues()).then((issueData) => {
        if (userData.payload && userData.payload.data) {
          var numGuestUsers = 0;
          var numUsers = 0;
          const users = [...userData.payload.data];
          users.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            return 0;
          });
          users.forEach((user) => {
            if (user.is_guest) numGuestUsers++;
            else numUsers++;
            user.docs.sort((a, b) => {
              if (a.name > b.name) return 1;
              else if (a.name < b.name) return -1;
              return 0;
            });
          });
          setUsers([...users]);
          setNumRegisteredUsers(numUsers);
          setNumGuests(numGuestUsers);
        }
        setIssues(issueData.payload.issues || []);
      });
    });
  }, []);

  const handleTabSelection = (activeKey) => {
    setSelectedTab(activeKey);
  };
  const handleCancel = (event, reason) => {
    // if (reason && reason == "backdropClick") return;
    if (callbackCancel) callbackCancel();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: "2em" }}>Admin</Typography>
          <div>
            <IconButton onClick={() => callbackCancel()}>
              <CloseIcon style={{ fontSize: "2em" }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Tab.Container
            id="admin-panel-tabs"
            defaultActiveKey="TAB_ID_USERS"
            activeKey={selectedTab}
            unmountOnExit={false}
            onSelect={handleTabSelection}
          >
            <Nav className="admin-doc-tabs" variant="tabs">
              <Nav.Item id="TAB_ID_USERS">
                <Nav.Link eventKey="TAB_ID_USERS">
                  Users - {numRegisteredUsers}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id="TAB_ID_USERS">
                <Nav.Link eventKey="TAB_ID_GUEST_USERS">
                  Guest users - {numGuests}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id="TAB_ID_ISSUES">
                <Nav.Link eventKey="TAB_ID_ISSUES">Issues</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="TAB_ID_USERS">
                {/* <AdminUserView users={users} guest={false} /> */}
                <AdminUsersGridView users={users} guest={false} />
              </Tab.Pane>
              <Tab.Pane eventKey="TAB_ID_GUEST_USERS">
                <AdminUserView users={users} guest={true} />
                {/* <AdminUsersGridView users={users} guest={true} /> */}
              </Tab.Pane>
              <Tab.Pane eventKey="TAB_ID_ISSUES">
                <IssueReport issues={issues} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminView;
