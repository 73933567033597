const REAT_APP_CONFIG_PROD = {
  url: {
    API_URL: "https://kg.onrender.com/api/v1",
  },
  allow_guest: true,
  dev: false,
  debug_log: false,
};
const REAT_APP_CONFIG_DEV = {
  url: {
    API_URL: "http://localhost:5000/api/v1",
  },
  allow_guest: true,
  dev: true,
  debug_log: true,
  feature_flag: "FEATURES: landing page",
};

const REACT_APP_CONFIG =
  process.env.NODE_ENV === "production"
    ? REAT_APP_CONFIG_PROD
    : REAT_APP_CONFIG_DEV;

REACT_APP_CONFIG["versions"] = {
  released: {
    number: "0.4.9",
    date: "Sep 13 2023",
    features: ["Experimental text search and visualization - dev only"],
    contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
    copyrightText: "Copyright 2023, Knowledge Garden.",
    license:
      "Trial - All rights reserved. See data and terms policy on the main website.",
  },
  candidate: {
    number: "0.5",
    date: "- - 2023",
    features: ["TO DO"],
    contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
    copyrightText: "Copyright 2023, Knowledge Garden.",
    license:
      "Trial - All rights reserved. See data and terms policy on the main website.",
  },
  history: [
    {
      number: "0.2",
      date: "June 7 2022",
      features: ["Alpha release"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license: "Trial - All rights reserved.",
    },
    {
      number: "0.3",
      date: "June 18 2022",
      features: ["Alpha release"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license: "Trial - All rights reserved.",
    },
    {
      number: "0.3.1.0",
      date: "July 06 2022",
      features: [
        "Alpha release",
        "Reference parsing",
        "Reference parsing error reporting",
        "File status display",
      ],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license: "Trial - All rights reserved.",
    },
    {
      number: "0.3.3.3",
      date: "November 28 2022",
      features: ["UI Refinements", "Bug fixes"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.3.3.5",
      date: "December 11 2022",
      features: ["Guest features", "Bug fixes"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.2",
      date: "Dec 13 2022",
      features: ["Minor bug fix"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.3",
      date: "Dec 14 2022",
      features: ["Updated references"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2022, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.4",
      date: "Jan 05 2022",
      features: [
        "Updated reference parsing",
        "Bookmark references",
        "Refined tooltips",
        "Guest log out cleanly",
        "Updated user guide images",
      ],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2023, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.5",
      date: "Jan 14 2022",
      features: ["Search references"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2023, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.6",
      date: "Jan 20 2022",
      features: ["Formatted report"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2023, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.7",
      date: "Jan 31 2022",
      features: ["Filter by documents"],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2023, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
    {
      number: "0.4.8",
      date: "Feb 14 2023",
      features: [""],
      contact: { name: "Sid Thakur", email: "knowledge.garden.app@gmail.com" },
      copyrightText: "Copyright 2023, Knowledge Garden.",
      license:
        "Trial - All rights reserved. See data and terms policy on the main website.",
    },
  ],
};
export default REACT_APP_CONFIG;
