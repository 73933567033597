import REACT_APP_CONFIG from "../AppConfig";
import { mutate, query } from "./api";
import InMemoryJWTManger from "./JWTManager";

const docsApiAddDoc = (docData) => {
  return mutate("/document/add", docData);
};

const docsApiAddManyDocs = (docsData) => {
  return mutate("/document/addall", docsData);
};

const docsApiGetDocById = (fileId) => {
  return query(`/document/${fileId.id}`);
};

const docsApiLoadFileById = (data) => {
  return query(`/document/file/${data.id}`);
};

const docsApiGetAllDocs = () => {
  return query(`/document/all`);
};

const docsApiGetDocumentsForSearchQuery = (data) => {
  return mutate(`/document/get_by_search_query`, data);
};

const docsApiRemoveDocById = (fileId) => {
  return query(`/document/remove/${fileId}`);
};

const docsApiRemoveDocsById = (fileId) => {
  return query(`/document/remove/${fileId}`);
};

const docsApiRemoveAllDocs = () => {
  return query("/document/remove/all");
};

const docsApiUploadMultipleFiles = (files) => {
  // Prepare form data with the files to upload
  const formData = new FormData();
  for (var key in Object.keys(files)) {
    formData.append("file", files[key]);
    formData.append("fileName", files[key].name);
  }
  return fetch(`${REACT_APP_CONFIG.url.API_URL}/folder/upload-multiple-files`, {
    method: "post",
    body: formData,
    mode: "cors",
    headers: {
      "x-access-token": InMemoryJWTManger.getToken(),
    },
  });
};

const docsApiGetDocData = (id) => {
  return query(`/document/docdata/${id}`);
};

const docsApiParseText = (id, pageData, forceParse) => {
  return mutate("/document/text", {
    id: id,
    data: pageData,
    forceParse: forceParse,
  });
};

const docsApiUpdateContextTheme = (data) => {
  return mutate("/document/update_theme", { data: data });
};

const docsApiUpdateDocReferences = (data) => {
  return mutate("/document/update_references", { data: data });
};

const docsApiGetDocNotes = (data) => {
  return mutate(`/document/get_doc_notes/${data.id}`);
};

const docsApiSaveDocNotes = (data) => {
  return mutate("/document/save_doc_notes", { data: data });
};

// @@ ADMIN
const docsApiPopulateDocText = (data) => {
  return mutate("/document/populate_doc_text", { data: data });
};

const docsApiPromiseImmediateResolve = (data) => {
  return Promise.resolve(data);
};

const DocumentAPI = {
  docsApiAddDoc,
  docsApiGetDocById,
  docsApiAddManyDocs,
  docsApiGetAllDocs,
  docsApiGetDocumentsForSearchQuery,
  docsApiGetDocData,
  docsApiGetDocNotes,
  docsApiSaveDocNotes,
  docsApiLoadFileById,
  docsApiParseText,
  docsApiRemoveDocById,
  docsApiRemoveDocsById,
  docsApiRemoveAllDocs,
  docsApiUpdateContextTheme,
  docsApiUpdateDocReferences,
  docsApiUploadMultipleFiles,
  docsApiPopulateDocText,
  docsApiPromiseImmediateResolve,
};

export default DocumentAPI;
