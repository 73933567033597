import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  getContextCountsForAllDocs,
} from "../../store/SliceAnnotations";
import { getAllDocs } from "../../store/SliceDocuments";
import { getAllFolders } from "../../store/SliceFolders";
import { getUserData } from "../../store/SliceUsers";
import SplashScreen from "../Views/SplashScreen/SplashScreen";
import TabsAndPanelsLayout from "./AppContentLayout";

const AppTopLevelLayout = ({ user, handleLogout }) => {
  const [showDashobard, setShowDashboard] = useState(false);
  // const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData()).then((userData) => {
      dispatch(getAllFolders()).then((folderData) => {
        dispatch(getAllDocs()).then((docsData) => {
          dispatch(getAllFavoriteAnnotations());
          dispatch(getAllTodoAnnotations());
          dispatch(getContextCountsForAllDocs());
          // setUserData({
          //   docs: docsData.payload.documents,
          //   folders: folderData.payload.folders,
          // });
        });
      });
    });
  }, []);

  return (
    <div>
      {showDashobard ? (
        <TabsAndPanelsLayout
          openDashboard={setShowDashboard}
          callbackLogout={handleLogout}
        />
      ) : (
        <SplashScreen user={user} openDashboard={setShowDashboard} />
      )}
    </div>
  );
};

export default AppTopLevelLayout;
