import { makeStyles, Snackbar, useTheme } from "@material-ui/core";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  alert_error: { backgroundColor: "salmon" },
  alert_success: { backgroundColor: "gray" },
  alert_warning: { backgroundColor: "orange" },
}));
const NotificationSnackbar = () => {
  const notificationData = useSelector(
    (state) => state.globalState.notification
  );
  const [open, setOpen] = useState(notificationData.show || false);
  useEffect(() => {
    setOpen(true);
  }, [notificationData.updated]);
  if (notificationData && notificationData.message === "") {
    return <></>;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={4000}
      open={open}
      onClose={() => setOpen(false)}
      style={{
        width: 400,
      }}
    >
      {notificationData.type === "KG_EVENT_ERROR" ? (
        <Alert severity="error">{notificationData.message}</Alert>
      ) : notificationData.type === "KG_EVENT_WARNING" ? (
        <Alert severity="warning">{notificationData.message}</Alert>
      ) : (
        <Alert severity="success">{notificationData.message}</Alert>
      )}
    </Snackbar>
  );
};

export default NotificationSnackbar;
