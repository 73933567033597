import { resolve } from "node:path/win32";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  Popup,
  Tip,
} from "rph/dist/esm";
import type { IHighlight, NewHighlight } from "rph/src/types";
import {
  decrementPageWiseAnnotation,
  getAllAnnotations,
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  incrementPageWiseAnnotation,
  removeAnnotations,
  setDocWiseAnnotations,
  setPageWiseAnnotations,
} from "../../../store/SliceAnnotations";
import {
  getDocNotes,
  saveDocNotes,
  updateDoc,
} from "../../../store/SliceDocuments";
import { updateAnnotationCount } from "../../../store/SliceDocuments";
import { store } from "../../../store/store";
import AnnotationView from "./AnnotationView";

interface State {
  url: string;
  highlights: Array<IHighlight>;
}

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const PDF_URL = "<someurl>";

const searchParams = new URLSearchParams(document.location.search);

const initialUrl = searchParams.get("url") || PDF_URL;

class PdfView extends Component<
  {
    doc: any;
    docId: string;
    themeColors: Array<Object>;
    themeKey: string;
    themeObj: any;
    pdfObj: any;
    userId: string;
    annotations: any;
    getAllAnnotations: CallableFunction;
    getAllFavoriteAnnotations: CallableFunction;
    getAllTodoAnnotations: CallableFunction;
    getDocNotes: CallableFunction;
    saveDocNotes: CallableFunction;
    updateDoc: CallableFunction;
    removeAnnotations: CallableFunction;
    saveAnnotation: CallableFunction;
    setDocWiseAnnotations: CallableFunction;
    setPageWiseAnnotations: CallableFunction;
    incrementPageWiseAnnotation: CallableFunction;
    decrementPageWiseAnnotation: CallableFunction;
    updateAnnotationCount: CallableFunction;
    options: any;
    store: any;
    zoomLevel: number;
  },
  State
> {
  state = {
    // annotations: this.props.annotations,
    url: initialUrl,
    highlights: [...this.props.annotations],
    store: this.props.store.getState(),
  };

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );

    // @@ get annotations from the store
    this.props.getAllAnnotations(this.props.docId).then((data: any) => {
      if (!this.props.pdfObj) {
        return;
      }
      if (data.payload && data.payload.annotations) {
        const numberOfPages = this.props.pdfObj.numPages;
        this.setState({
          highlights: data.payload.annotations,
        });
        this.getPageWiseHighlights(numberOfPages, data.payload.annotations);
      }
    });
  }

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.id === id);
  }

  addHighlight(highlight: NewHighlight) {
    const { highlights } = this.state;
    const currentTheme =
      this.props.themeObj ||
      this.state.store.users.contexts["KG_ANNOTATION_THEME_DEFAULT"];

    var contextId = highlight.context.contextId || null;
    var context = null;
    if (contextId && currentTheme) {
      const contextFound = currentTheme.contexts.find(
        (c: any) => c.contextId === contextId
      );
      if (contextFound) {
        context = {
          ...contextFound,
          themeId: currentTheme.themeId,
          themeName: currentTheme.name,
        };
      }
    }

    // @@ Create a date-time stamp in seconds
    var d = new Date();
    var seconds = Math.round(d.getTime() / 1000);

    //// Following uses computed image width and height
    // const isImage = highlight.content.image ? true : false;
    // var imageHeight = -1;
    // var imageWidth = -1;

    // if (isImage) {
    //   // Get image width and height from the bounding rect object
    //   const rect = highlight.position.boundingRect;
    //   imageHeight = Math.abs(rect.y2 - rect.y1);
    //   imageWidth = Math.abs(rect.x2 - rect.x1);
    // }
    //
    // var newHighlightData = { ...highlight };
    // if (isImage) {
    //   newHighlightData = {
    //     ...highlight,
    //     content: {
    //       ...highlight.content,
    //       height: imageHeight,
    //       width: imageWidth,
    //     },
    //   };
    // }
    const highlightData = {
      ...highlight,
      _user: this.props.userId,
      _doc: this.props.docId,
      docName: this.props.doc.name,
      created: seconds,
      context: context,
    };

    this.props
      .saveAnnotation(highlightData)
      .then((res: any) => {
        if (!res) return;
        if (res && res.payload && res.payload.annotations) {
          const ann =
            res.payload.annotations.length > 0
              ? res.payload.annotations[0]
              : {};
          if (ann._id) {
            const obj = {
              highlights: [
                {
                  ...highlightData,
                  id: getNextId(),
                  _id: ann._id,
                },
                ...highlights,
              ],
            };
            this.setState(obj); // @@ Not needed?
            this.props.incrementPageWiseAnnotation({
              docId: this.props.docId,
              pageNumber: highlightData.position.pageNumber,
              contextId: highlightData.context.contextId,
            });
            // Update annotation count for corresponding document
            this.props.updateAnnotationCount({
              docId: ann._doc,
              updateType: "INCREMENT",
              contextId: ann.context.contextId,
            });
            this.props.getAllFavoriteAnnotations();
            this.props.getAllTodoAnnotations();
          }
        }
      })
      .then(() => {
        this.props.updateDoc({ id: this.props.docId });
      });

    if (highlight.comment.copyToNotes) {
      this.props.getDocNotes({ id: this.props.docId }).then((resp: any) => {
        var userNotes = "";
        if (resp.payload.data && resp.payload.data.doc.annotations.userNotes) {
          userNotes = resp.payload.data.doc.annotations.userNotes;
        }
        userNotes +=
          "<br/>" +
          "<strong>Annotation text:</strong> " +
          highlight.content.text +
          (highlight.comment.text === ""
            ? ""
            : "<br/><br/><i>Your comment:</i> " + highlight.comment.text) +
          "<br/>---<br/>";
        this.props.saveDocNotes({ id: this.props.docId, userNotes: userNotes });
      });
    }
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    // console.log("Image data: ", content);
    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  deleteHighlight = (id: string) => {
    const highlightToDelete = this.state.highlights.find((h) => h._id === id);

    if (highlightToDelete) {
      this.setState((state) => ({
        highlights: state.highlights.filter((h) => h._id !== id),
      }));

      // NOTE - removeAnnotations call handles updates to page wise count but not saveAnnotation
      // this.props.decrementPageWiseAnnotation({
      //   docId: this.props.docId,
      //   pageNumber: highlightToDelete.position.pageNumber,
      //   contextId: highlightToDelete.context.contextId,
      // });

      // Update annotation count for corresponding document

      this.props.updateAnnotationCount({
        docId: highlightToDelete._doc,
        updateType: "DECREMENT",
        contextId: highlightToDelete.context.contextId,
      });

      this.props
        .removeAnnotations({
          docId: this.props.docId,
          ids: [highlightToDelete._id],
        })
        .then(() => {
          this.props.updateDoc({ id: this.props.docId });
        });
    }
  };

  getPageWiseHighlights = (numberOfPages: Number, highlights: any) => {
    // const currentTheme = this.state.store.users.contexts[this.props.themeKey]
    //   ? this.state.store.users.contexts[this.props.themeKey]
    //   : this.state.store.users.contexts["KG_ANNOTATION_THEME_DEFAULT"];
    const currentTheme =
      this.props.themeObj ||
      this.state.store.users.contexts["KG_ANNOTATION_THEME_DEFAULT"];

    // Get all unique pages in ascending order
    var pages: any = [];
    var docStats: any = [];

    for (var i = 0; i < numberOfPages; i++) {
      const pageStats: any = [];
      currentTheme.contexts.forEach((c: any) => {
        pageStats.push({ ctx: c.contextId, label: c.label, val: 0 });
      });
      pages.push({
        pg: i + 1,
        ctxCounts: [...pageStats],
        // counts: {
        //   favorites: 0,
        //   images: 0,
        //   notes: 0,
        //   todo: 0,
        // },
      });
    }

    // create data structure for doc level stats
    currentTheme.contexts.forEach((c: any) => {
      docStats.push({ ctx: c.contextId, label: c.label, val: 0 });
    });

    // Go through each highlight and calculate counts for each context
    highlights.forEach((h: any) => {
      const pageNumber = (h.position && h.position.pageNumber) || null;

      const pageIndex = pages.findIndex((p: any) => p.pg === pageNumber);

      if (pageIndex > -1) {
        const contextId = h.context.contextId;
        const contextIndex = pages[pageIndex].ctxCounts.findIndex(
          (c: any) => c.ctx === contextId
        );
        if (contextIndex > -1) {
          pages[pageIndex].ctxCounts[contextIndex].val += 1;
        }
      }

      // Update doc-level stats
      const docContext = docStats.find(
        (dc: any) => dc.ctx === h.context.contextId
      );
      if (docContext) {
        docContext.val += 1;
      }
    });

    pages.sort((a: any, b: any) => {
      if (a.pageNumber > b.pageNumber) return 1;
      else if (a.pageNumber < b.pageNumber) return -1;
      else return 0;
    });

    this.props.setPageWiseAnnotations({
      docId: this.props.docId,
      pages: pages,
    });

    this.props.setDocWiseAnnotations({
      docId: this.props.docId,
      docStats: docStats,
    });
  };

  render() {
    if (!this.props.themeColors) return <></>;

    const pdfDocument: any = this.props.pdfObj;
    const currentTheme =
      this.props.themeObj ||
      this.state.store.users.contexts["KG_ANNOTATION_THEME_DEFAULT"];

    return (
      <div style={{ display: "flex", height: "86vh", width: "96vw" }}>
        <div
          style={{
            // height: "85vh",
            width: "100vw",
            position: "relative",
            marginRight: "1px",
          }}
        >
          <PdfHighlighter
            options={this.props.options}
            pdfDocument={pdfDocument}
            enableAreaSelection={(event) => event.altKey}
            onScrollChange={resetHash}
            // pdfScaleValue="page-width"
            pdfScaleValue={`${this.props.zoomLevel}`}
            scrollRef={(scrollTo) => {
              this.scrollViewerTo = scrollTo;
              this.scrollToHighlightFromHash();
            }}
            onSelectionFinished={(
              position,
              content,
              hideTipAndSelection,
              transformSelection
            ) => (
              <Tip
                onOpen={transformSelection}
                onConfirm={(comment, context) => {
                  hideTipAndSelection();
                  this.addHighlight({ content, position, comment, context });
                }}
                highlightType={
                  content.hasOwnProperty("image") ? "image" : "text"
                }
                theme={currentTheme}
                themeColors={this.props.themeColors}
              />
            )}
            highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo
            ) => {
              const isTextHighlight = !Boolean(
                highlight.content && highlight.content.image
              );

              const component = isTextHighlight ? (
                <Highlight
                  isScrolledTo={isScrolledTo}
                  position={highlight.position}
                  comment={highlight.comment}
                  context={highlight.context}
                  themeColors={this.props.themeColors}
                />
              ) : (
                <AreaHighlight
                  isScrolledTo={isScrolledTo}
                  highlight={highlight}
                  onChange={(boundingRect) => {
                    console.log("BR > ", boundingRect);
                    this.updateHighlight(
                      highlight.id,
                      { boundingRect: viewportToScaled(boundingRect) },
                      { image: screenshot(boundingRect) }
                    );
                  }}
                  // context={highlight.context}
                />
              );

              return (
                <Popup
                  popupContent={<HighlightPopup {...highlight} />}
                  onMouseOver={(popupContent) =>
                    setTip(highlight, (highlight) => popupContent)
                  }
                  onMouseOut={hideTip}
                  key={index}
                  children={component}
                />
              );
            }}
            highlights={this.props.annotations}
          />
        </div>
        <AnnotationView
          docId={this.props.docId}
          deleteHighlight={this.deleteHighlight}
          themeColors={this.props.themeColors}
          themeKey={this.props.themeKey}
          visitHighlight={this.scrollViewerTo}
        />
      </div>
    );
  }
}

// export default PdfView;
const mapStateToProps = (state: any) => ({
  store: store,
});

const mapDispatchToProps = {
  getAllAnnotations,
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
  getDocNotes,
  saveDocNotes,
  setDocWiseAnnotations,
  setPageWiseAnnotations,
  incrementPageWiseAnnotation,
  decrementPageWiseAnnotation,
  removeAnnotations,
  updateAnnotationCount,
  updateDoc,
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfView);
