import { IconButton, useTheme } from "@material-ui/core";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Cancel";
import SquareIcon from "@mui/icons-material/SquareRounded";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import React, { useState } from "react";

const ContextColorPicker = ({ context, handleColorChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const globalTheme = useTheme();
  const contextColors = globalTheme.kg_ui.context_theme.colors;
  const foundColor = contextColors.find((c) => c.id === context.colorId);

  var contextColor = foundColor.colorMain || "#223399";

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleColorSelection = (event) => {
    const colorObj = contextColors.find((c) => c.id === event.currentTarget.id);
    if (colorObj) {
      contextColor = colorObj.colorMain;
      handleColorChange({ contextId: context.contextId, colorId: colorObj.id });
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick} >
        {open ? <CloseIcon id="square-icon" style={{ color: contextColor, }} /> : <SquareIcon id="square-icon" style={{ color: contextColor }} />}
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"right"}
        style={{ zIndex: "1400" }}
      >
        <Box
          sx={{
            // border: 1,
            borderRadius: 3,
            boxShadow: "2px 2px 10px #cccccc",
            p: 2,
            bgcolor: "background.paper",
            width: "295px",
          }}
        >
          {contextColors.map((c, i) => (
            <IconButton key={i} id={c.id} onClick={handleColorSelection}>
              <CircleIcon
                key={`${i}-main`}
                style={{
                  color: c.colorMain,
                  cursor: "pointer",
                  fontSize: "1em",
                  "&:hover": { border: "2px solid gray" },
                }}
              />
            </IconButton>
          ))}
        </Box>
      </Popper>
    </div>
  );
};

export default ContextColorPicker;
