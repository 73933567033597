import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // border: "1px solid white",
    // borderRadius: "8px",
    // boxShadow: "0px 0px 15px #aaa",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "top",
    gap: "20px",
  },
  image: {
    // border: "1px solid white",
    border: `1px solid ${theme.palette.primary.lighter}`,
    borderRadius: "5px",
    boxShadow: "0px 0px 15px #ccc",
    // display: "flex",
    // justifyContent: "space-around",
    // alignItems: "top",
    width: "100%",
  },
}));
const SectionImageGrid = ({ images }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {images.map((image, i) => (
        <a href={image} key={i}>
          <img src={image || ""} className={classes.image} />
        </a>
      ))}
    </div>
  );
};
export default SectionImageGrid;
