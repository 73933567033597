import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import NavPanel from "../Views/AppNav/NavPanel";
import MainViewLayout from "./MainViewLayout";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    width: "100%",
    overflowX: "hidden",
  },
  nav_panel: {
    zIndex: 1,
  },
  main: {
    flex: "1 1 auto",
    alignItems: "stretch",
    justifyContent: "stretch",
  },
  bg: {
    borderRadius: 0,
  },
}));
const TabsAndPanelsLayout = ({ callbackLogout, openDashboard }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.nav_panel}>
        {/* <Paper className={classes.bg} elevation={6}> */}
          <NavPanel
            openDashboard={openDashboard}
            callbackLogout={callbackLogout}
          />
        {/* </Paper> */}
      </div>
      <div className={classes.main}>
        <MainViewLayout />
      </div>
    </div>
  );
};

export default TabsAndPanelsLayout;
