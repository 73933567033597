import { mutate, query } from "./api";

const annotationsApiGetForAllDocs = () => {
  return query("/annotation/all_docs");
};

const annotationsApiGetAllForADoc = (id) => {
  return query(`/annotation/all/${id}`);
};

const annotationsApiGetAllFavorite = () => {
  return query("/annotation/all_favorite");
};

const annotationsApiGetAllTodo = () => {
  return query("/annotation/all_todo");
};

const annotationsApiGetContextCountsForAllDocs = () => {
  return query("/annotation/get_context_counts/all");
}

const annotationsApiGetByContext = (data) => {
  return mutate("/annotation/all_by_context", data)
}

const annotationsApiGetAnnotationsForSearchQuery = (data) => {
  return mutate("/annotations/get_by_search_query", data)
}

const annotationsApiToggleReferenceBookmark = (data) => {
  return mutate("/annotation/toggle_reference_bookmark", data)
}

const removeAnnotations = (annotationIds) => {
  return mutate("/annotation/remove", annotationIds);
};

const saveAnnotation = (annotation) => {
  return mutate("/annotation/add", annotation);
};

const updateAnnotations = (data) => {
  return mutate("/annotation/update", data);
};

const annotationsApiUpdateTheme = (data) => {
  return mutate("/annotation/update_theme", data);
}

const annotationsApiTest = (data) => {
  return query("/annotation/test");
}

const AnnotationsAPI = {
  annotationsApiGetForAllDocs,
  annotationsApiGetAllForADoc,
  annotationsApiGetByContext,
  annotationsApiGetAllFavorite,
  annotationsApiGetAllTodo,
  annotationsApiGetContextCountsForAllDocs,
  annotationsApiGetAnnotationsForSearchQuery,
  annotationsApiToggleReferenceBookmark,
  annotationsApiUpdateTheme,
  removeAnnotations,
  saveAnnotation,
  updateAnnotations,
  annotationsApiTest,
};

export default AnnotationsAPI;
