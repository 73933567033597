import { makeStyles } from "@material-ui/core";
import Emoji from "./Emoji";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: "top",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  subTitle: {
    display: "flex",
    fontSize: "large",
    fontWeight: "600",
    justifyContent: "space-around",
    textAlign: "center",
    marginTop: "5px",
  },
  subTitleWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  titleWrapper: {
    color: theme.palette.primary.darker,
    display: "flex",
    flexDirection: "row",
    fontWeight: "500",
    justifyContent: "space-around",
    marginTop: "15px",
    textAlign: "center",
    width: "100%",
  },
}));

const PageSubHeader = ({ subTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <h2>
          <Emoji symbol="👋" label="waving hand" />
          &nbsp;I am Knowledge Garden
        </h2>
      </div>
      <div className={classes.subTitleWrapper}>
        <div className={classes.subTitle}>
          <span
            role="img"
            aria-label={""}
            aria-hidden={"false"}
          >
             {subTitle}&nbsp;❤️️
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSubHeader;
