import {
  makeStyles,
  TextField
} from "@material-ui/core";
import ContextColorPicker from "./ContextColorPicker";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
}));
const ContextEntry = ({
  context,
  handleThemeContextNameChange,
  handleThemeContextColorChange,
}) => {
  const classes = useStyles();

  const handleContextColorSelection = (data) => {
    handleThemeContextColorChange(data);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <ContextColorPicker
          context={context}
          handleColorChange={handleContextColorSelection}
        />
        <TextField
          fullWidth
          label={context.label}
          id="outlined-basic"
          inputProps={{ maxLength: 30 }}
          size="small"
          variant="standard"
          onChange={(event) =>
            handleThemeContextNameChange({
              contextId: context.contextId,
              name: event.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export default ContextEntry;
