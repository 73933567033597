// Images
const kgDashboardImage1 = require("./images/kg-dashboard.png");
const kgDocViewGuide1 = require("./images/kg-doc-view-guide.png");
const kgAnnotationView1 = require("./images/kg-annotation-view.png");
const kgKeywordChart1 = require("./images/kg-keyword-chart.png");
const kgReferences1 = require("./images/kg-references.png");
const kgReport1 = require("./images/kg-report.png");
const kgTextExtract1 = require("./images/kg-text-extract.png");
// Videos
const kgOverviewVideo = require("./videos/KG Overview.mp4");
const assets = {
  kgAnnotationView1,
  kgDocViewGuide1,
  kgDashboardImage1,
  kgKeywordChart1,
  kgOverviewVideo,
  kgReferences1,
  kgReport1,
  kgTextExtract1,
};

export default assets;
