import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  quick_view_content_wrapper: {
    display: "flex",
    alignContent: "top",
    justifyContent: "space-between",
  },
  quick_view_content: {
    boxShadow: "3px 2px 10px #e0e0e0",
    padding: "30px",
    width: "50%",
  },
  quickview_title: {
    display: "flex",
    alignItems: "top",
    justifyContent: "space-between",
  },
  dialog_actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const DocumentQuickView = ({
  doc,
  cancelButtonText,
  callbackCancel,
  openDialog,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.quickview_title}>
            <Typography style={{ fontSize: "2em" }}>
              Quick Scan: {doc.name.length > 42 ? doc.name.substr(0, 42) + ".." : doc.name}
            </Typography>
            <div className={classes.dialog_actions}>
              <IconButton onClick={callbackCancel}>
                <CloseIcon style={{ fontSize: "2em" }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.quick_view_content_wrapper}>
            <div className={classes.quick_view_content}>
              <div className={classes.quick_view_content_header}>
                <Typography variant="h4"> Abstract</Typography>
              </div>
              <div style={{ textAlign: "justify" }}>
                {doc.metadata &&
                doc.metadata.docData &&
                doc.metadata.docData.excerpts
                  ? doc.metadata.docData.excerpts.abstract === ""
                    ? "Unable to extract abstract."
                    : doc.metadata.docData.excerpts.abstract
                  : "Abstract data not available."}
              </div>
            </div>
            <div className={classes.quick_view_content}>
              <div className={classes.quick_view_content_header}>
                <Typography variant="h4"> Conclusion</Typography>
              </div>
              <div style={{ textAlign: "justify" }}>
                {doc.metadata &&
                doc.metadata.docData &&
                doc.metadata.docData.excerpts
                  ? doc.metadata.docData.excerpts.conclusion === ""
                    ? "Unable to extract conclusion."
                    : doc.metadata.docData.excerpts.conclusion
                  : "Conclusion data is not available."}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentQuickView;
