import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllUsers, removeUser } from "../../../store/SliceUsers";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../UIWidgets/AlertDialog";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    fontSize: "0.85em",
  },
  user: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    marginBottom: "8px",
  },
  user_name: {
    borderRight: "3px solid white",
    fontWeight: "bold",
    padding: "10px",
    width: "150px",
  },
  user_email: {
    borderRight: "3px solid white",
    overflowWrap: "anywhere",
    padding: "10px",
    width: "150px",
  },
  user_docs: {
    borderRight: "3px solid white",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "250px",
  },
  user_login: {
    borderRight: "3px solid white",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100px",
  },
}));

const AdminContent = ({ users }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});

  const callbackCancel = () => {
    setShowDeleteDialog(false);
  };
  const callbackProceed = (data) => {
    setShowDeleteDialog(false);
    console.log("delete > ", data)
    handleDeleteUser(data.id);
  };
  const prepareToDeleteUser = (userId) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };
  const handleDeleteUser = (userId) => {
    dispatch(removeUser({ id: userId }));
  };
  console.log(users)
  // const [users, setUsers] = useState([]);
  // useEffect(() => {
  //   dispatch(getAllUsers()).then((resp) => {
  //     if (resp.payload && resp.payload.data) {
  //       console.log(resp.payload.data);
  //       const userData = [...resp.payload.data];
  //       userData.sort((a, b) => {
  //         if (a.name > b.name) return 1;
  //         else if (a.name < b.name) return -1;
  //         return 0;
  //       });
  //       userData.forEach((user) => {
  //         user.docs.sort((a, b) => {
  //           if (a.name > b.name) return 1;
  //           else if (a.name < b.name) return -1;
  //           return 0;
  //         });
  //       });
  //       setUsers([...userData]);
  //     }
  //   });
  // }, []);
  return (
    <div className={classes.wrapper}>
      <div>
        <Typography variant="h5">Users</Typography>
        <div>
          {users.map((user, i) => (
            <div className={classes.user} key={i}>
              <span className={""}>
                <IconButton
                  onClick={() => prepareToDeleteUser(user)}
                  style={{ color: "salmon" }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
              <span className={classes.user_name}>{user.name}</span>
              <span className={classes.user_email}>{user.email}</span>
              <span className={classes.user_login}>{user.created}</span>
              <span className={classes.user_login}>
                {user.logins ? user.logins[user.logins.length - 1] : "-"}
              </span>
              <span className={classes.user_docs}>
                <div>{user.docs.length} Documents</div>
                <ul>
                  {user.docs.map((doc, di) => (
                    <li key={di}>{doc.name}</li>
                  ))}
                </ul>
              </span>
            </div>
          ))}
        </div>
      </div>
      <AlertDialog
        callbackCancel={callbackCancel}
        callbackProceed={() => callbackProceed(userToDelete)}
        contentText={`The user "${userToDelete.name}" and their data will be deleted permanently. Proceed with caution!`}
        openDialog={showDeleteDialog}
        proceedButtonText={"Remove User"}
        title={"Remove User"}
      />
    </div>
  );
};

export default AdminContent;
