import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import DocTermVis from "../Visualizations/DocTermVis";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "75.5vh",
    marginBottom: "10px",
    overflowY: "auto",
  },
  doc_terms_container: {
    // height: "77vh",
    // overflowY: "auto",
  },
  default_empty_message: {
    color: "#bbbbbb",
    display: "flex",
    flexDirection: "column",
    fontStyle: "italic",
    justifyContent: "space-around",
    textAlign: "center",
  },
  term_pills_header: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));
const DocumentTermPillsVis = () => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const openedDocs = useSelector((state) => state.docs.docsOpened);
  const docs = useSelector((state) => state.docs.docs).filter((d) =>
    d.metadata && d.metadata.docData && d.metadata.docData.word_freq ? d : null
  );
  var docsToVisualize = [];

  openedDocs.forEach((od) => {
    const foundDoc = docs.find((d) => d._id === od.fileId);
    if (foundDoc) {
      docsToVisualize.push(foundDoc);
    }
  });

  var maxTermCountOverAllDocs = -1;
  const numTerms = globalTheme.kg_ui.doc_data.maxTagPillTerms;
  docsToVisualize.forEach((d) => {
    if (d.metadata && d.metadata.docData && d.metadata.docData.word_freq)
      if (d.metadata.docData.word_freq.length > 0) {
        const firstTerm = d.metadata.docData.word_freq[0];
        maxTermCountOverAllDocs =
          firstTerm.n > maxTermCountOverAllDocs
            ? firstTerm.n
            : maxTermCountOverAllDocs;
      }
  });

  return (
    <div className={classes.wrapper}>
      {docsToVisualize.length > 0 ? (
        <div className={classes.term_pills_header}>
          Top terms (in opened documents)
        </div>
      ) : (
        <div className={classes.default_empty_message}>
          <div>Nothing to show.</div>
          <div>Open documents to see their top terms.</div>
        </div>
      )}
      <div className={classes.doc_terms_container}>
        {docsToVisualize.map((d, i) => (
          <div key={i}>
            <DocTermVis
              key={i}
              data={d.metadata.docData.word_freq}
              docName={d.name}
              max={maxTermCountOverAllDocs}
              showFileName={true}
            />
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentTermPillsVis;
