import {
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { nanoid } from "nanoid";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetDocTheme } from "../../../store/SliceDocuments";
import {
  addNewUserContextTheme,
  deleteUserContextTheme,
  updateUserContextTheme,
} from "../../../store/SliceUsers";
import ContextEditor from "./ContextEditor";
import {
  getAllFavoriteAnnotations,
  getAllTodoAnnotations,
} from "../../../store/SliceAnnotations";
import { Alert } from "@mui/lab";

const kgLogo = require("../../../assets/logo/logo-kg-1.png");

const useStyles = makeStyles((theme) => ({
  svg_icons: {
    color: "gray",
  },
}));

const ContextEditorDialog = ({
  callbackCancel,
  contextKey,
  cancelButtonText,
  openDialog,
  proceedButtonText,
  title,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const themeRef = React.useRef(null);
  var flagAddNewTheme = React.useRef(false);
  var userContexts = useSelector((state) => state.users.contexts);
  const [selectedThemeKey, setSelectedTheme] = useState(contextKey);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
  var contextList = [];
  var contextKeys = useRef();

  contextKeys.current = Object.keys(userContexts);
  contextKeys.current.sort((a, b) => {
    if (userContexts[a].name > userContexts[b].name) return 1;
    else if (userContexts[a].name < userContexts[b].name) return -1;
    return 0;
  });

  contextKeys.current.forEach((k) => {
    contextList.push(userContexts[k]);
  });

  const handleSelectTheme = (event) => {
    setSelectedTheme(event.target.value);
  };

  const updateTheme = (data) => {
    themeRef.current = { ...data.theme };
    flagAddNewTheme.current = data.addNew;
    if (themeRef.current.name.trim() === "") setSaveButtonEnabled(false);
    else setSaveButtonEnabled(true);
  };

  const deleteTheme = (themeId) => {
    if (themeId)
      dispatch(deleteUserContextTheme({ themeId: themeId })).then(() => {
        dispatch(resetDocTheme({ themeId: themeId })).then(() => {
          setSelectedTheme("KG_ANNOTATION_THEME_DEFAULT");
          dispatch(getAllFavoriteAnnotations());
          dispatch(getAllTodoAnnotations());
        });
      });
  };

  const saveTheme = () => {
    if (themeRef && themeRef.current) {
      if (flagAddNewTheme.current) {
        var newContexts = JSON.parse(JSON.stringify(userContexts));
        const newId = `KG_ANNOTATION_THEME_${nanoid(16)}`;
        newContexts[newId] = {
          ...themeRef.current,
        };
        newContexts[newId].themeId = newId;
        dispatch(addNewUserContextTheme(newContexts));
      } else {
        var updatedContexts = JSON.parse(JSON.stringify(userContexts));
        updatedContexts[themeRef.current.themeId].contexts = [
          ...themeRef.current.contexts,
        ];
        updatedContexts[themeRef.current.themeId].name = themeRef.current.name;

        dispatch(
          updateUserContextTheme({
            contexts: updatedContexts,
            themeId: themeRef.current.themeId,
            theme: themeRef.current,
          })
        );
      }
      callbackCancel();
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={openDialog}
        onClose={callbackCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus={true}
      >
        <div style={{ padding: "10px" }}>
          <Typography variant="h6">
            <img
              src={kgLogo}
              style={{ height: 45, width: 45, marginRight: "10px" }}
              alt=""
            />
            &nbsp;
            {title}
          </Typography>
        </div>

        <DialogContent dividers>
          <div style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="select-label">
                Select or Create New Theme
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={
                  selectedThemeKey === "KG_ANNOTATION_THEME_NEW"
                    ? "KG_ANNOTATION_THEME_NEW"
                    : userContexts[selectedThemeKey]
                    ? selectedThemeKey
                    : "KG_ANNOTATION_THEME_DEFAULT"
                }
                label="Annotation Theme"
                onChange={handleSelectTheme}
                fullWidth
                variant="filled"
              >
                {contextKeys.current &&
                  contextKeys.current.map((k, i) => (
                    <MenuItem key={i} value={k}>
                      <EditIcon
                        className={classes.svg_icons}
                        fontSize="small"
                      />{" "}
                      &nbsp;
                      {userContexts[k].name}
                    </MenuItem>
                  ))}
                <MenuItem
                  value={"KG_ANNOTATION_THEME_NEW"}
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bolder",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <StarIcon
                      className={classes.svg_icons}
                      style={{ color: theme.palette.primary.main }}
                    />
                    &nbsp; Create New
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <ContextEditor
            themeKey={selectedThemeKey}
            callbackDeleteTheme={deleteTheme}
            callbackUpdateTheme={updateTheme}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={callbackCancel}
            variant="outlined"
            autoFocus
            color="secondary"
          >
            {cancelButtonText || "Cancel"}
          </Button>
          <Button
            onClick={saveTheme}
            variant="contained"
            color="secondary"
            disabled={!saveButtonEnabled}
            autoFocus
          >
            {"Save and Close"}
            {/* {selectedThemeKey === "KG_ANNOTATION_THEME_NEW" ? "Create Theme" : "Save and Close"} */}
            {/* {proceedButtonText || "Save"} */}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContextEditorDialog;
