import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  wrapper: {
    // border: "1px solid white",
    // borderRadius: "8px",
    // boxShadow: "0px 0px 15px #aaa",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "top",
  },
  image: {
    // border: "1px solid blue",
    // borderRadius: "8px",
    // boxShadow: "0px 0px 15px #aaa",
    // display: "flex",
    // justifyContent: "space-around",
    // alignItems: "top",
    width: "100%",
  },
}));
const SectionImage = ({ altText, path }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <a href={path}>
        <img
          src={path || ""}
          alt={altText}
          className={classes.image}
        />
      </a>
    </div>
  );
};
export default SectionImage;
