import { IconButton, Link, Typography } from "@material-ui/core";
import "./styles/GuideContentFullPage.css";
import UpArrow from "@mui/icons-material/ArrowUpward";
import REACT_APP_CONFIG from "../../../AppConfig";
import { useState } from "react";
const imgLandingScreen = require("../../../assets/design/ui/landing-screen.png");
const imgDBOverview = require("../../../assets/design/ui/db-v3.png");
const imgDBFileListCloseup = require("../../../assets/design/ui/db-file-list-closeup-1.png");
const imgDBFilesOpened = require("../../../assets/design/ui/db-files-opened.png");
const imgDocOpened = require("../../../assets/design/ui/doc-view-v2.png");
const imgDocAnnotatingText = require("../../../assets/design/ui/doc-view-annotate-1.png");
const imgDocAnnotatingText2 = require("../../../assets/design/ui/doc-view-annotate-2.png");
const imgDocBookmarkRefs = require("../../../assets/design/ui/doc-view-bookmark-refs.png");
const imgDocToolsPanel = require("../../../assets/design/ui/doc-view-tools-panel.png");
const imgDocToolsQuickView = require("../../../assets/design/ui/doc-view-tools-quick-scan.png");
const imgDocToolsKeywordVis = require("../../../assets/design/ui/doc-view-tools-keywords.png");
const imgDocAnnotationThemeLegend = require("../../../assets/design/ui/doc-view-annotation-theme-legend.png");
const imgDocAnnotationThemeEditorLauncher = require("../../../assets/design/ui/doc-view-annotation-theme-launch-button.png");
const imgDocAnnotationThemeEditor = require("../../../assets/design/ui/doc-view-annotation-theme-editor.png");

const imgDocAnnotatingArea = require("../../../assets/design/ui/doc-view-annotate-image-1.png");
const imgDocContextualNotesSection = require("../../../assets/design/ui/doc-view-annotated-page-3-notes-section.png");
const imgContextSelectionMenu = require("../../../assets/design/ui/doc-view-context-theme-2-theme-menu.png");
const imgContextEditing = require("../../../assets/design/ui/doc-view-context-theme-5-modify-theme.png");
const imgContextCreationMenu = require("../../../assets/design/ui/doc-view-context-theme-4-customize-theme.png");

export default function GuideContentFullPage() {
  const [activeNavItem, setActiveNavItem] = useState("");
  return (
    <div className="app-user-guide-wrapper">
      <div className="app-user-guide-nav">
        <div style={{ position: "fixed" }}>
          <div
            className="app-user-guide-section-text"
            style={{ marginTop: "30px", marginBottom: "15px" }}
          >
            <Typography variant="h5">Topics</Typography>
          </div>
          <div className="app-user-guide-nav-items">
            <ul>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_OVERVIEW"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
                  href="#ug-nav-overview"
                >
                  Overview
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_DB" ? "active-nav-item" : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_DB")}
                  href="#ug-nav-db"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_ADD_DOCS"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_ADD_DOCS")}
                  href="#ug-nav-adding-docs"
                >
                  Adding documents
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_OPEN_DOCS"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_OPEN_DOCS")}
                  href="#ug-nav-opening-docs"
                >
                  Opening documents
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_READ_DOCS"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_READ_DOCS")}
                  href="#ug-nav-reading-docs"
                >
                  Reading documents
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_ANNOTATE_DOCS"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_ANNOTATE_DOCS")}
                  href="#ug-nav-annotating-docs"
                >
                  Annotating documents
                </Link>
              </li>
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_ANNOTATION_THEME"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_ANNOTATION_THEME")}
                  href="#ug-nav-annotation-categories"
                >
                  Annotation themes
                </Link>
              </li>
              <hr />
              <li>
                <Link
                  className={`app-user-guide-toc-link ${
                    activeNavItem === "KG_UG_NAV_FOOTER"
                      ? "active-nav-item"
                      : ""
                  }`}
                  onClick={() => setActiveNavItem("KG_UG_NAV_FOOTER")}
                  href="#ug-nav-version"
                >
                  Version
                </Link>{" "}
                - {REACT_APP_CONFIG.versions.released.number}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="app-user-guide" id="#tour-db-user-guide">
        <a id="ug-nav-toc"></a>
        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-overview">Overview</a>
          </div>
          <div className="app-user-guide-section-body">
            <h5>What is Knowledge Garden?</h5>
            <p>
              <strong>
                Knowledge Garden (KG) is a document-reading web application that
                provides tools for capturing, visualizing, and curating insights
                obtained while reading research material such as journal
                articles, books, reports, and proposals.
              </strong>
            </p>
            <h5>Who is it for?</h5>
            <p>
              Knowledge Garden is for knowledge seekers - students,
              researchers, and professionals, who need to read and understand
              research material, documents, and technical material.
            </p>
            <h5>What problems does it address?</h5>
            <p>
              Researchers, professionals, and students in many domains continue
              to be overwhelmed by the large amounts of research material they
              need to read and understand. It is challenging to read and make
              sense of a large number of documents, capture and maintain
              personal insights gained from the documents, and contextually
              recall all the information. Yet, the tools for reviewing
              literature and acquiring new knowledge, for example, the
              electronic document reading application, are still rudimentary.
              Knowledge Garden aims to address the challenges above.
            </p>

            <h5>What is the approach?</h5>
            <p>
              The KG app combines two strategies to make reading and knowledge
              acquisition from research material more effective.
            </p>
            <p>
              <strong>
                First, the app provides a novel contextual tagging approach for
                defining personalized information contexts and for capturing
                insights while reading documents.
              </strong>
            </p>
            <p>
              Contextual annotations are customizable, contextual tags that are
              used to mark up information in documents. Context themes can be
              added by the user for different types of reading tasks. For
              example, to aid recall, the following set of tags can provide
              additional context for highlighted information: 'problem
              statements', 'current knowledge', 'insights', and 'method or
              detail'.
            </p>
            <p>
              <strong>
                Second, the KG app uses text analytics to surface insights about
                the content in documents and to analyze a collection of
                documents.
              </strong>
            </p>
            <p>
              The KG app provides insights about documents via analysis of text
              extracted from the user's documents. For example, top or frequent
              words in a document, URLs, and other document metadata are
              automatically extracted and available when documents are made
              available.
            </p>
            <p>
              Visualizations of the user's contextual annotations and the text
              extracted and parsed from documents are used throughout the KG app
              to provide insights to the user.
            </p>
            <h5>What document formats are supported?</h5>
            <p>
              The KG app currently supports PDF documents. Research papers,
              proposals, and even books in PDF format can be added to the app.
              Other standard document formats, e.g., EPUB and HTML pages, may be
              supported in the future.
            </p>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-db"> Dashboard</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              {/* <h4>Dashboard</h4> */}
              <p>
                <span className="app-user-guide-bold-text">
                  The Dashboard is the central place to add, view, and manage
                  documents in one's library. The Dashboard also shows your
                  recent notes and insights from documents.
                </span>
              </p>
              <p>
                After logging in, click the 'Start' button on the landing page
                to reach the Dashboard. Initially, the Dashboard will be empty -
                begin by adding some documents to the Library.
              </p>
              <p>
                The image on the right shows a view of the Dashboard after a few
                document folders have been added, a couple of documents have
                been opened, and a few contextual annotations (highlights) have
                been created.
              </p>
              {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <img src={imgLandingScreen} width="400px" border="1" />
              <span className="app-user-guide-image-caption">Landing page</span>
            </div> */}
            </div>

            <div>
              <div className="app-user-guide-section-body-img-wide">
                <img
                  src={imgDBOverview}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Dashboard view showing the Library, Notes, and Analytics
                panels. Opened documents appear in tabs at the top of the app.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-adding-docs">Adding Documents</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              <p>
                <span className="app-user-guide-bold-text">
                  Folders containing multiple documents or individual documents
                  can be added to your Library from the Dashboard.
                </span>
              </p>
              <p>
                To <strong>add a folder</strong> click on the folder icon with a
                plus sign and select a folder containing PDF files from your
                computer. Folders are imported one at a time and only the
                first-level documents in the source folder are imported. You can
                change the name of the imported folder by clicking on the folder
                name and providing a new name.
              </p>
              <p>
                To <strong>add documents</strong> to the Library, click the
                document icon with a plus sign and select PDF files from your
                computer. By default, individual files are added to a folder
                named "Unnamed folder".
              </p>
              <p>
                Files can also be added to existing folders by clicking the Add
                File in the folder name section, next to where it shows the
                number of files in the folder.
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDBFileListCloseup}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: View showing buttons to add folder and documents and an
                expanded folder showing multiple documents.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-opening-docs"> Opening Documents</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              {/* <h4>Opening Documents</h4> */}
              <p>
                <span className="app-user-guide-bold-text">
                  Multiple documents can be opened in the Knowledge Garden app.
                </span>
              </p>
              <p>
                To open documents for reading, go to the Library panel on the
                Dashboard and expand the folder that contains the documents you
                want to read. Then click the "Open" button to load the document.
              </p>
              <p>
                Documents open in tabs located at the top of the application,
                next to the tab labeled "Dashboard".
              </p>
              <p>
                The document tab shows the name of the document's folder, the
                name of the document, and a small chart showing contextual
                annotations in the document (if there are any).
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDBFilesOpened}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Files opened and displayed in tabs at the top of the
                application.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-reading-docs"> Reading Documents</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              <p>
                <span className="app-user-guide-bold-text">
                  Knowledge Garden is an enhanced document reading application.
                  Document keywords and excerpts, such as abstract and
                  conclusion, are readily available in the document viewer.
                </span>
              </p>
              <p>
                Once documents are opened from the Library, click the
                corresponding tab at the top of the Dashboard to open the
                document viewer.
              </p>
              <p>
                The document view consists of a tools panel at the top, the
                document contents in the center, and notes and metadata panel on
                the right.
              </p>
              <h5>References</h5>
              <p>
                The Knowledge Garden app uses text parsing to extract
                bibliography or references from scholarly articles. The
                references are shown in a side-by-side view, making it easy to
                look them up without interruption while reading a document (see
                image on the right).
              </p>
              <h5>Tools Panel</h5>
              <p>
                The tools panel, located at the top of the document view,
                provides access to key information parsed and extracted from the
                document text. The information includes frequent significant
                words in the document, abstract and conclusion (if available),
                and a report view that shows the previously-mentioned
                information and user's personal notes.
              </p>
              <h5>Annotation Theme Selector and Legend</h5>
              <p>
                Next to the tools panel is the contextual annotation theme
                selector and contexts in the theme. See the next sections for
                details.
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocOpened}
                  width="700px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Document view showing the PDF file and tools.
              </div>
              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocBookmarkRefs}
                  width="700px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: The bibliography or references from the document are
                shown in a panel for easy access. Bookmark references to save to
                your report.
              </div>

              {/* <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocToolsPanel}
                  width="700px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Tools panel in the document viewer.
              </div> */}
              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocToolsQuickView}
                  width="500px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Abstract and conclusion extracted from the document.
              </div>
              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocToolsKeywordVis}
                  width="500px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Frequent keywords extracted from the document and
                visualized in a chart.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-annotating-docs"> Annotating Documents</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              <p>
                <span className="app-user-guide-bold-text">
                  The Knowledge Garden app has a novel contextual annotation
                  system, which allows the user to tag information using
                  personalized, contextual tags. Visualizations of the contexts
                  throughout the application provide insights to the user about
                  their personal knowledge.
                </span>
              </p>
              <h5>Text annotations</h5>
              <p>
                Click and drag with the mouse to select text in a document
                opened in the KG app. After selecting text and releasing the
                mouse button a context menu will appear.
              </p>
              <p>
                Context can be set in two ways. First, by selecting a context
                and pressing the "Save" button in the context menu. You can add
                comments or set follow-up and favorite flags by clicking on the
                icons in the context menu.
              </p>
              <p>
                Second, contexts can be applied quickly by clicking the "Bolt"
                icon next to context labels. This option is useful for quickly
                saving an annotation without needing to add comments or set the
                follow-up or favorite flags.
              </p>
              <p>
                After the context is applied, the annotation appears in the
                "Notes" panel on the right of the document. The annotations are
                grouped by pages where they are located. Page headers show a bar
                chart that indicates the type and number of each type of context
                associated with the text annotations on that page.
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotatingText}
                  width="650px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Context menu appearing after text selection.
              </div>
              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotatingText2}
                  width="650px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Document and notes panel showing the saved
                contextualized highlight.
              </div>
            </div>
          </div>
          <br />
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              <h5>Image annotations</h5>
              <p>
                To create an area highlight, press down the 'Alt' key and click
                and drag with the mouse to select an area. Select the
                appropriate context from the pop-up menu that appears after the
                area selection is complete. An area highlight is created and it
                is stored as an image.
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotatingArea}
                  width="650px"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Creating image annotation.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-annotation-categories"> Annotation Themes</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-section-body-two-col">
            <div className="app-user-guide-section-text">
              {/* <h4>Annotating Documents</h4> */}
              <p>
                <span className="app-user-guide-bold-text">
                  Annotation themes can be customized and new themes can be
                  created by the user.
                </span>
              </p>
              <h5>Annotation Theme Editor</h5>
              <p>
                Annotation themes can be customized and new themes can be
                created for different types of reading tasks. A button on the
                navigation panel launches the theme editor.
              </p>
              <p>
                Custom annotation themes serve different types of reading. For
                example, the following reading tasks may require different types
                of contextual annotations: literature review and survey,
                reviewing manuscripts and documents (e.g., proposals), or
                reading a book.
              </p>
            </div>
            <div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotationThemeLegend}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Theme selector and context legend in the document
                viewer.
              </div>
              <hr />
              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotationThemeEditorLauncher}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Button on the main navigation panel to view annotation
                theme editor dialog.
              </div>

              <div>&nbsp;</div>
              <div className="app-user-guide-section-body-img">
                <img
                  src={imgDocAnnotationThemeEditor}
                  width="100%"
                  className="app-guide-image"
                />
              </div>
              <div className="app-user-guide-image-caption">
                Figure: Annotation theme editor.
              </div>
            </div>
          </div>
        </div>

        <div className="app-user-guide-section">
          <div className="app-user-guide-section-title">
            <a id="ug-nav-version">Version</a>
            <Link href="#ug-nav-toc">
              <IconButton
                onClick={() => setActiveNavItem("KG_UG_NAV_OVERVIEW")}
              >
                <UpArrow />
              </IconButton>
            </Link>
          </div>
          <div className="app-user-guide-version">
            <span>Release: {REACT_APP_CONFIG.versions.released.number}</span>
            <br />
            <span>
              License: {REACT_APP_CONFIG.versions.released.license}&nbsp;
              {REACT_APP_CONFIG.versions.released.copyrightText}
            </span>
            <br />
            <span>
              Contact:
              {/* {REACT_APP_CONFIG.versions.released.contact.name} */}
              &nbsp;
              <span className="app-contact-email">
                {REACT_APP_CONFIG.versions.released.contact.email}
              </span>
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
