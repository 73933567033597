import { Button, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import assets from "../../assets/assets";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "top",
    marginBottom: "25px",
  },
  ctaButton: { display: "flex", justifyContent: "space-around" },
  ctaSubTitle: {
    color: "#999",
    display: "flex",
    fontSize: "1em",
    justifyContent: "space-around",
  },
}));

const PageCTA = ({ subTitle, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleGettingStarted = () => {
    navigate("/login");
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.ctaButton}>
        <Button
          className="btn btn-primary btn-lg"
          color="primary"
          role="button"
          onClick={handleGettingStarted}
          variant="contained"
          size="large"
        >
          {title}
        </Button>
      </div>
      {subTitle && subTitle !== "" ? (
        <div className={classes.ctaSubTitle}>
          <small>{subTitle}</small>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PageCTA;
