import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageAnnotations from "./PageAnnotations";

const useStyles = makeStyles(() => ({
  wrapper: {
    // border: "1px solid blue",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "left",
    // overflow: "scroll",
    width: "27vw"
  },
  annotation_info: {
    color: "gray",
    padding: "10px",
    // paddingLeft: "25px",
    // paddingRight: "25px",
  },
}));
const DocAnnotationsView = ({
  docId,
  deleteHighlight,
  themeColors,
  themeKey,
  visitHighlight,
}) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const allAnnotations = useSelector((state) => state.notes.annotations);
  const pageWiseAnnotations = useSelector((state) => state.notes.pageStats);
  const userContexts = useSelector((state) => state.users.contexts);
  const [annotations, setAnnotations] = useState([]);
  var maxYVal = -1;
  const thisPWA = pageWiseAnnotations.find((d) => d.docId === docId);

  if (thisPWA) {
    thisPWA.pages.forEach((p) => {
      p.ctxCounts.forEach((c) => {
        if (c.val > maxYVal) maxYVal = c.val;
      });
    });
  }

  var contextColors = {};

  const contextTheme = userContexts.hasOwnProperty(themeKey)
    ? userContexts[themeKey]
    : userContexts["KG_ANNOTATION_THEME_DEFAULT"];

  useEffect(() => {
    var docAnnotations = [];

    docAnnotations = allAnnotations.filter((a) => a._doc === docId);

    var tempAnnotations = [];
    docAnnotations.forEach((a) => {
      const pageNumber = a.position.pageNumber;

      const foundItem = tempAnnotations.find(
        (item) => item.pageNumber === pageNumber
      );

      if (!foundItem) {
        const entry = {
          pageNumber: pageNumber,
          annotations: [],
        };
        entry.annotations.push(a);
        tempAnnotations.push(entry);
      } else {
        foundItem.annotations.push(a);
      }
    });

    tempAnnotations.sort((a, b) => {
      if (a.pageNumber > b.pageNumber) return 1;
      else if (a.pageNumber < b.pageNumber) return -1;
      else return 0;
    });
    setAnnotations(tempAnnotations);
  }, [allAnnotations]);

  if (!contextTheme || !contextTheme.contexts) return <></>;
  contextTheme.contexts.forEach((ctx) => {
    contextColors[ctx.contextId] = themeColors[ctx.contextId].colorMain;
  });

  return (
    <div className={classes.wrapper}>
      {annotations.length > 0 ? (
        annotations.map((p, i) => (
          <PageAnnotations
            key={i}
            // contextTheme={contextTheme}
            contextColors={contextColors}
            docId={docId}
            data={p}
            deleteHighlight={deleteHighlight}
            maxYVal={maxYVal}
            pageWiseAnnotations={pageWiseAnnotations.find(
              (d) => d.docId === docId
            )}
            pageNumberIndex={p.pageNumber - 1}
            themeColors={themeColors}
            themeKey={themeKey}
            visitHighlight={visitHighlight}
          />
        ))
      ) : (
        <div className={classes.annotation_info}>
          {/* <InfoIcon fontSize="small" style={{ color: "gray" }} /> */}
          {globalTheme.kg_ui.tooltips.doc_empty_notes}
        </div>
      )}
    </div>
  );
};

export default DocAnnotationsView;
