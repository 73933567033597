import { IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import DocumentKeywordVisIcon from "@mui/icons-material/BarChart";
import DocumentScanIcon from "@mui/icons-material/DocumentScanner";
import DocumentSummaryIcon from "@mui/icons-material/Summarize";
import { useState } from "react";
import DocumentKeywordVis from "../../Document/DocumentKeywordVis";
import DocumentQuickView from "../../Document/DocumentQuickView";
import DocumentReportView from "../../Document/DocumentReportView";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "5px",
    marginRight: "5px",
  },
  svg_icon: {
    color: theme.palette.primary.main,
  },
}));
const DocumentInfo = ({ doc }) => {
  const classes = useStyles();
  const [openKeywordVisDialog, setOpenKeywordVisDialog] = useState(false);
  const [openQuickViewDialog, setOpenQuickViewDialog] = useState(false);
  const [openDocumentReportDialog, setOpenDocumentReportDialog] =
    useState(false);
  const handleCloseKeywordVisDialog = () => {
    setOpenKeywordVisDialog(false);
  };
  const handleCloseQuickViewDialog = () => {
    setOpenQuickViewDialog(false);
  };
  const handleCloseDocumentReportDialog = () => {
    setOpenDocumentReportDialog(false);
  };

  return (
    <div className={classes.wrapper}>
      <div>
        <Tooltip
          arrow
          enterDelay={1000}
          enterNextDelay={200}
          leaveDelay={200}
          placement="bottom"
          title={
            <Typography fontSize={16}>
              Quick scan abstract and conclusion
            </Typography>
          }
        >
          <IconButton onClick={() => setOpenQuickViewDialog(true)}>
            <DocumentScanIcon className={classes.svg_icon} />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          enterDelay={1000}
          enterNextDelay={200}
          leaveDelay={200}
          placement="bottom"
          title={
            <Typography fontSize={16}>
              View document terms and their occurrence counts
            </Typography>
          }
        >
          <IconButton onClick={() => setOpenKeywordVisDialog(true)}>
            <DocumentKeywordVisIcon className={classes.svg_icon} />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          enterDelay={1000}
          enterNextDelay={200}
          leaveDelay={200}
          placement="bottom"
          title={
            <Typography fontSize={16}>View a report of your notes</Typography>
          }
        >
          <IconButton onClick={() => setOpenDocumentReportDialog(true)}>
            <DocumentSummaryIcon className={classes.svg_icon} />
          </IconButton>
        </Tooltip>
      </div>
      <DocumentQuickView
        doc={doc}
        openDialog={openQuickViewDialog}
        callbackCancel={handleCloseQuickViewDialog}
      />
      <DocumentKeywordVis
        doc={doc}
        openDialog={openKeywordVisDialog}
        callbackCancel={handleCloseKeywordVisDialog}
      />
      <DocumentReportView
        doc={doc}
        openDialog={openDocumentReportDialog}
        callbackCancel={handleCloseDocumentReportDialog}
      />
    </div>
  );
};

export default DocumentInfo;
