import React, { useEffect, useRef, useState } from "react";
import { scaleLinear } from "d3";
import { makeStyles, useTheme } from "@material-ui/core";
import { Chip, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "5px",
  },
  tag_pills_container: {
    border: "1px solid white",
    display: "flex",
    alignItems: "top",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  tag_pill: {
    backgroundColor: "#fff",
    color: "black",
    border: "1px solid #cccccc",
    borderRadius: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-between",
    margin: "3px",
    padding: "2px",
    paddingLeft: "13px",
    paddingRight: "13px",
  },
}));

const DocTags = ({ tags }) => {
  const classes = useStyles();
  const globalTheme = useTheme();
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    const sortedTagList = Object.assign([], tags);
    sortedTagList.sort((a, b) => {
      if (a > b) return 1;
      else if (a < b) return -1;
      return 0;
    });
    setTagList(sortedTagList);
  }, [tags]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.tag_pills_container}>
        <Stack direction="row" spacing={1}>
          {tagList.map((tag, i) => {
            return (
              <Chip key={i} label={tag} size="large" variant="outlined" />
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

export default DocTags;
