import React, { Component } from "react";
import * as d3 from "d3";
import "./style/HorizontalBarChart.css";
require("./utils/assign");

const merge = function(one, two) {
  return Object.assign({}, one, two);
};

class HorizontalBarChart extends Component {
  static defaultProps = {
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
  };

  handleBarClick(element, id) {
    if (this.props.onBarClick) {
      this.props.onBarClick(element, id);
    }
  }

  chart(props, data) {
    if (!data || data.length <= 0) return;
    const margin = props.margin;
    const width = props.width;
    const height = props.height;

    let svg = d3
      .select(this.svgRef)
      .attr("width", width + 10)
      .attr("height", height);

    svg = svg
      .attr("class", "vis-horizontal-barchart")
      .select(".graph")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    svg.selectAll("rect").remove();
    svg.selectAll("text").remove();
    svg.selectAll("vis-horizontal-barchart-label").remove();
    svg.selectAll("vis-horizontal-barchart-values").remove();
    svg.selectAll("vis-horizontal-barchart-title").remove();

    svg
      .selectAll(".bar")
      .data(data || [])
      .enter()
      .append("rect")
      // .on("click", this.handleBarClick)
      .attr("class", "bar")
      .attr("fill", (d) => this.barColor(d.n))
      .attr("x", this.props.margin.labelLength)
      .attr("y", (d, i) => this.y(i))
      .attr("width", (d) => this.x(d.n))
      .attr("height", this.y.bandwidth());

    svg
      .selectAll("text")
      .data(data || [])
      .enter()
      .append("text")
      .attr("class", "vis-horizontal-barchart-values")
      .attr("x", (d) => this.props.margin.labelLength + this.x(d.n))
      .attr("y", (d, i) => this.y(i))
      .attr("dx", 5)
      .attr("dy", 0.75 * this.y.bandwidth())
      .text((d) => (d.n > 0 ? d.n : ""));

    svg
      .selectAll(".vis-horizontal-barchart-label")
      .data(data || [])
      .enter()
      .append("text")
      .attr("class", "vis-horizontal-barchart-label")
      .attr("x", (d) => props.margin.left)
      .attr("y", (d, i) => this.y(i))
      .attr("dx", 10)
      .attr("dy", 0.75 * this.y.bandwidth())
      .text((d) => d.t);

    svg
      .selectAll(".vis-horizontal-barchart-title")
      .data([0])
      .enter()
      .append("text")
      .attr("class", "vis-horizontal-barchart-title")
      .attr("x", this.props.margin.left + 5)
      .attr("y", 5)
      .text(this.props.title);
  }

  defineScales(props, data) {
    if (!data || data.length <= 0) return;
    // props.width = props.width - props.margin.left - props.margin.right;
    // props.height = props.height - props.margin.top - props.margin.bottom;

    this.y = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([
        props.margin.top,
        props.height - props.margin.top - props.margin.bottom,
      ])
      .padding(0.1);

    const max =
      props.maxVal > -1
        ? props.maxVal
        : Math.max.apply(
            Math,
            data.map(function(o) {
              return o.n;
            })
          );
    this.x = d3
      .scaleLinear()
      .domain([0, max])
      .range([0, props.width - props.margin.labelLength - props.margin.right]);

    this.barColor = d3
      .scaleLinear()
      .domain([props.minVal, props.maxVal])
      .range(["#eeeeee", "#555"]);
  }

  defineAxes(props) {
    this.xAxis = d3.svg
      .axis()
      .scale(this.x)
      .orient("bottom");
    this.yAxis = d3.svg
      .axis()
      .scale(this.y)
      .orient("left");
  }

  componentDidMount() {
    const data = Object.assign([], this.props.data);
    const props = merge(this.props);
    this.defineScales(props, data);
    this.chart(props, data);
  }

  shouldComponentUpdate(nextProps) {
    const data = Object.assign([], nextProps.data);
    const props = merge(nextProps);
    this.defineScales(props, data);
    this.chart(props, data);
    return true;
  }

  render() {
    // console.log("TinyBarChart updated...", this.props.data);
    return (
      <svg ref={(ref) => (this.svgRef = ref)}>
        <g className="graph"></g>
      </svg>
    );
  }
}

export default HorizontalBarChart;
