import {
  Checkbox,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import ExpandIcon from "@mui/icons-material/KeyboardArrowDown";
import AddFilesIcon from "@mui/icons-material/NoteAddOutlined";
import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocsToAFolder,
  changeFolderName,
  removeSelectedFolder,
  setFileOrFolderOperationsActive,
  setSelectedFolder,
} from "../../../store/SliceFolders";
import FileContainer from "../Files/FileContainer";
import FolderName from "./FolderName";
import InMemoryJWTManager from "../../../api/JWTManager";
import AlertDialog from "../../UIWidgets/AlertDialog";

const useStyles = makeStyles((theme) => ({
  accordion_root: {
    marginBottom: 15,
    marginRight: 5,
  },
  accordion_expand_container: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  accordion_header_container: {
    backgroundColor: theme.palette.type === "dark" ? "#444" : "#e9e9e9",
    borderRadius: 6,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
  },
  accordion_folder_details: {
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "1 1 auto",
  },
  accordion_header_caption: {
    borderRadius: 4,
    padding: 4,
    width: "210px",
    marginRight: 4,
    "&:hover": {
      border: "1px solid gray",
      fontStyle: "normal",
      transitionDuration: "750ms",
      transitionProperty: "border",
    },
  },
  accordion_action_button: {
    cursor: "pointer",
    color: `${theme.palette.primary.main}`,
  },
  folder_details_part1: {
    alignItems: "center",
    color: "#999",
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "space-between",
    fontSize: "smaller",
  },
  folder_details_num_files: {
    color: "#333",
    marginRight: "20px",
  },
  folder_details_part2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  accordion_panel: {
    backgroundColor: theme.palette.type === "dark" ? "#484848" : "#f9f9f9",
    marginBottom: 5,
  },
  empty_files_message: {
    color: "gray",
    display: "flex",
    justifyContent: "flex-start",
    fontStyle: "italic",
    marginLeft: 50,
    padding: 10,
  },
  svg_icons: {
    "& svg": {
      fill: "#999",
      fontSize: 25,
    },
    "& svg:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

const FolderContainer = ({ data, folderTabId, forcedChecked }) => {
  const classes = useStyles(data);
  const globalTheme = useTheme();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileLimitDialog, setShowFileLimitDialog] = useState(false);
  const inputFilesRef = useRef();
  const userDocs = useSelector((state) => state.docs.docs);
  var totalAnnotations = 0;

  data.docs.forEach((d) => {
    totalAnnotations += d.annotations.total;
  });

  const callbackCancel = () => {
    setShowFileLimitDialog(false);
  };

  const handleExpansionCollapse = (items) => {
    setIsExpanded(!isExpanded);
  };

  const handleFolderNameChange = (folderName) => {
    dispatch(changeFolderName({ id: data._id, name: folderName }));
  };

  const handleFolderSelectionChange = (event) => {
    if (event.target.checked) {
      dispatch(setSelectedFolder({ folderId: data._id }));
    } else {
      dispatch(removeSelectedFolder({ folderId: data._id }));
    }
  };

  const handleAddFiles = () => {
    if (
      (InMemoryJWTManager.getUserIsGuest() &&
        userDocs.length >= globalTheme.kg_ui.user_settings.file_limit.trial) ||
      (!InMemoryJWTManager.getUserIsGuest() &&
        userDocs.length >=
          globalTheme.kg_ui.user_settings.file_limit.registered)
    ) {
      setShowFileLimitDialog(true);
      return;
    }
    inputFilesRef.current.click();
  };

  const handleFileSelection = (event) => {
    const filesData = event.target.files;
    if (filesData) {
      dispatch(setFileOrFolderOperationsActive());
      dispatch(
        addDocsToAFolder({
          folder: data,
          files: filesData,
          newFolder: false,
        })
      );
    }
  };

  return (
    <>
      <Accordion className={classes.accordion_root} allowZeroExpanded={true}>
        <AccordionItem dangerouslySetExpanded={isExpanded}>
          <div
            className={classes.accordion_header_container}
            style={{
              backgroundColor: isExpanded
                ? globalTheme.palette.primary.lightest
                : "",
            }}
          >
            <div className={classes.accordion_folder_details}>
              <div className={classes.folder_details_part1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    id={data._id}
                    onChange={handleFolderSelectionChange}
                    checked={checked || forcedChecked}
                  />
                  <div className={classes.accordion_header_caption}>
                    <FolderName
                      name={data.name}
                      cbSetFolderName={handleFolderNameChange}
                    />
                  </div>
                </div>
                <div className={classes.folder_details_num_files}>
                  {data.docs.length} File{data.docs.length > 1 ? "s" : ""}
                </div>
              </div>

              <div className={classes.folder_details_part2}>
                <input
                  type="file"
                  accept=".pdf, application/pdf"
                  id={data.folderId}
                  ref={inputFilesRef}
                  style={{ display: "none" }}
                  multiple
                  onChange={handleFileSelection}
                />
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography fontSize={16}>
                      Add documents (PDF format) to this folder
                    </Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={1000}
                >
                  <IconButton
                    aria-label="add folder"
                    className={classes.svg_icons}
                    onClick={handleAddFiles}
                    style={{ marginRight: "8px" }}
                  >
                    <AddFilesIcon className={classes.svg_icons} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.accordion_expand_container}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <IconButton
                      sx={{ color: `${globalTheme.palette.primary.main}` }}
                      onClick={handleExpansionCollapse}
                    >
                      {isExpanded ? (
                        <CollapseIcon
                          className={classes.accordion_action_button}
                        />
                      ) : (
                        <ExpandIcon
                          className={classes.accordion_action_button}
                        />
                      )}
                    </IconButton>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </div>
            </div>
          </div>
          <AccordionItemPanel>
            {data.docs.length <= 0 ? (
              <div className={classes.empty_files_message}>
                Empty. Add some files to the folder.
              </div>
            ) : (
              <div className={classes.accordion_panel}>
                {data.docs.map((d, i) => (
                  <FileContainer key={i} data={d} />
                ))}
              </div>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <AlertDialog
        callbackCancel={callbackCancel}
        contentText={
          "This is a trial version of Knowledge Garden and the number of files that can be uploaded is limited." +
          " Please sign up or request a limit increase at knowledge.garden.app@gmail.com"
        }
        openDialog={showFileLimitDialog}
        cancelButtonText={"Close"}
        title={"File Limit Reached!"}
      />
    </>
  );
};

export default FolderContainer;
