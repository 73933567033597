import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import * as React from "react";
import { convert } from "html-to-text";
import CloseIcon from "@mui/icons-material/Close";
import BookmarkAdded from "@mui/icons-material/BookmarkAddedOutlined";
import DocumentIcon from "@mui/icons-material/ArticleOutlined";
import HyperlinkIcon from "@mui/icons-material/InsertLinkOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DocTermVis from "../Visualizations/DocTermVis";
import DocTags from "../Visualizations/DocTags";
import { useSelector } from "react-redux";
import AnnotationCard from "../Views/Annotations/AnnotationCard";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BookmarkAddedOutlined from "@mui/icons-material/BookmarkAddedOutlined";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  quick_view_content_wrapper: {
    display: "flex",
    flexDirection: "column",
    alignContent: "top",
    justifyContent: "space-between",
  },
  quick_view_content: {
    paddingBottom: "30px",
  },
  dialog_title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link_icons: {
    color: "gray",
    paddingRight: "5px",
  },
  reference_entry: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
  },
  report_context_section: {
    marginBottom: "15px",
  },
  report_view_actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "10px",
  },
}));

const DocumentReportView = ({
  doc,
  cancelButtonText,
  callbackCancel,
  openDialog,
}) => {
  const classes = useStyles();
  const contentRef = React.createRef();
  const allAnnotations = useSelector((state) => state.notes.annotations);
  const userContexts = useSelector((state) => state.users.contexts);
  const globalTheme = useTheme();
  const docContext = userContexts[doc.annotations.contexts.themeId];
  const [contextWiseAnnotations, setContextWiseAnnotations] = React.useState(
    []
  );
  const [processing, setProcessing] = useState(false);

  React.useEffect(() => {
    const docAnnotations = allAnnotations.filter((a) => a._doc === doc._id);
    var tempArray = [];
    if (docContext) {
      docContext.contexts.forEach((ctx) => {
        const filtered = docAnnotations.filter(
          (docAnn) => docAnn.context.contextId === ctx.contextId
        );
        const data = {
          colorId: ctx.colorId,
          contextId: ctx.contextId,
          contextName: ctx.label,
          annotations: [...filtered],
        };
        tempArray.push(data);
      });
      setContextWiseAnnotations(tempArray);
    }
  }, [allAnnotations]);

  var maxTermFrequency = -1;

  if (doc.metadata && doc.metadata.docData && doc.metadata.docData.word_freq) {
    maxTermFrequency = Math.max.apply(
      Math,
      doc.metadata.docData.word_freq.map((a) => a.n)
    );
  }

  const formatPageDataForPDF = (docData, sortedAnnotations) => {
    // console.log("> ", docData, sortedAnnotations);

    var pageWidth = 8.5,
      lineHeight = 1.2,
      margin = 0.75,
      maxLineWidth = pageWidth - margin * 2,
      fontSize = 18,
      ptsPerInch = 72,
      oneLineHeight = (fontSize * lineHeight) / ptsPerInch;

    var pdf = new jsPDF({ unit: "in", lineHeight: lineHeight });
    pdf.setFont("helvetica");
    pdf.setFontSize(fontSize);

    var str = "";
    var xPos = 20;
    var yPos = 0;
    var yIncr = 0.25;

    pdf.setLineWidth(0.5);
    pdf.setDrawColor(240);
    yPos += yIncr;
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight + 0.13,
      margin + 7,
      yPos + margin + oneLineHeight + 0.175
    );

    // Title
    pdf.setTextColor(171, 71, 188);
    yPos += yIncr;
    str = "REPORT: Annotations and Notes";
    // pdf.text(str, margin, yPos + margin + oneLineHeight);
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);

    // Doc name
    pdf.setFontSize(14);
    pdf.setTextColor(171, 71, 188);
    yPos += yIncr * 2;
    str = "Document: " + docData.name;
    // pdf.text(str, margin, yPos + margin + oneLineHeight);
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);
    yPos += yIncr * textLines.length;

    // KG date time
    pdf.setFontSize(12);
    pdf.setTextColor(100);
    yPos += yIncr * 0.5;
    str = "Created by Knowledge Garden: " + new Date();
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);

    // Section: TOP 20 TERMS
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Top 20 Terms";
    yPos += 0.33;
    yPos += yIncr;
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Terms sorted by descending order of occurrence counts)";
    yPos += yIncr;
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(100);
    if (docData.metadata.docData.word_freq.length > 0) {
      var listLen =
        docData.metadata.docData.word_freq.length >= 20
          ? 20
          : docData.metadata.docData.word_freq.length;
      for (var i = 0; i < listLen; i++) {
        str =
          docData.metadata.docData.word_freq[i]["t"] +
          " (" +
          docData.metadata.docData.word_freq[i]["n"] +
          ")";
        yPos += yIncr;
        // pdf.text(str, xPos, yPos);
        pdf.text(str, margin, yPos + margin + oneLineHeight);
      }
    } else {
      yPos += yIncr;
      if (yPos > 10) {
        pdf.addPage();
        yPos = 0.25;
      }
      pdf.text("No terms available.", margin, yPos + margin + oneLineHeight);
    }

    // Section: TAGS
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Tags";
    yPos += 0.33;
    yPos += yIncr;
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Hash tags, e.g., #information, found in the document and comments)";
    yPos += yIncr;
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setTextColor(100);
    if (docData.tags.length > 0) {
      var listLen = docData.tags.length;
      str = "";
      for (var i = 0; i < listLen; i++) {
        str = "#" + docData.tags[i] + "\n";
        yPos += yIncr;
        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(str, margin, yPos + margin + oneLineHeight);
      }
    } else {
      yPos += yIncr;
      if (yPos > 10) {
        pdf.addPage();
        yPos = 0.25;
      }
      pdf.text("No tags found.", margin, yPos + margin + oneLineHeight);
    }

    // Section: URLs
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Links";
    yPos += 0.33;
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Links found in the document)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    if (docData.metadata.docData.urls.length > 0) {
      var listLen = docData.metadata.docData.urls.length;
      str = "";
      pdf.setTextColor(80, 80, 250);
      pdf.setFontSize(10);
      for (var i = 0; i < listLen; i++) {
        str = docData.metadata.docData.urls[i] + "\n";
        yPos += yIncr;
        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(str, margin, yPos + margin + oneLineHeight);
      }
    } else {
      yPos += yIncr;
      if (yPos > 10) {
        pdf.addPage();
        yPos = 0.25;
      }
      pdf.text("No links found.", margin, yPos + margin + oneLineHeight);
    }

    // Section: BOOKMARKED REFERENCES
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "References";
    yPos += 0.33;
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Bookmarked references)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setTextColor(100);
    var tem = 0;
    var numBookmarked = 0;
    if (docData.metadata.docData.bibliography) {
      numBookmarked = docData.metadata.docData.bibliography.filter(
        (ref) => ref.fav
      ).length;
    }
    if (
      docData.metadata.docData.bibliography &&
      docData.metadata.docData.bibliography.length > 0
    ) {
      var listLen = docData.metadata.docData.bibliography.length;
      str = "";
      tem = yPos;
      for (var i = 0; i < listLen; i++) {
        if (docData.metadata.docData.bibliography[i]["fav"]) {
          str += "> " + docData.metadata.docData.bibliography[i]["text"] + "\n";
          tem += yIncr;
        }
      }

      if (str !== "") {
        var textLines = pdf.splitTextToSize(str, maxLineWidth);
        yPos += yIncr;
        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(textLines, margin, yPos + margin + oneLineHeight);
        yPos += yIncr * (textLines.length - 1);
      }
    }
    if (numBookmarked === 0) {
      yPos += yIncr;
      if (yPos > 10) {
        pdf.addPage();
        yPos = 0.25;
      }

      pdf.text(
        "No bookmarked references.",
        margin,
        yPos + margin + oneLineHeight
      );
      yPos += yIncr;
    }

    // Section: CONTEXTUAL ANNOTATIONS
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Annotations";
    yPos += 0.33;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Annotations organized by contexts)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    if (sortedAnnotations.length > 0) {
      var listOfContextsLen = sortedAnnotations.length;
      yPos += yIncr;
      str = "";

      for (var i = 0; i < listOfContextsLen; i++) {
        pdf.setTextColor("#2e7d32");
        pdf.setFontSize(13);
        str = "Context: " + sortedAnnotations[i]["contextName"] + "\n";

        if (yPos > 10) {
          pdf.addPage();
          yPos = 0.25;
        }
        pdf.text(str, margin, yPos + margin + oneLineHeight);
        yPos += yIncr;
        pdf.setTextColor(100);
        pdf.setFontSize(12);

        str = "";
        if (sortedAnnotations[i]["annotations"].length > 0) {
          for (
            var annIndex = 0;
            annIndex < sortedAnnotations[i]["annotations"].length;
            annIndex++
          ) {
            const ann = sortedAnnotations[i]["annotations"][annIndex];
            str = "";
            if (ann["content"]["text"]) {
              str =
                "(" +
                (annIndex + 1) +
                ")" +
                `${ann["comment"]["star"] ? " [favorite]" : ""}` +
                `${ann["comment"]["todo"] ? " [todo]" : ""}` +
                "\n";
              str += ann["content"]["text"] + "\n";
              if (ann["comment"]["text"]) {
                str += "My comment: " + ann["comment"]["text"] + "\n";
              }
              var textLines = pdf.splitTextToSize(str, maxLineWidth);
              if (yPos + yIncr * (textLines.length - 2) > 10) {
                pdf.addPage();
                yPos = 0.25;
              } else {
                yPos += yIncr;
              }
              if (yPos > 10) {
                pdf.addPage();
                yPos = 0.25;
              }
              pdf.text(textLines, margin, yPos + margin + oneLineHeight);
              yPos += yIncr * (textLines.length - 2);
            } else if (ann["content"]["image"]) {
              yPos += yIncr;
              str = "(" + (annIndex + 1) + ") Image highlight >";
              pdf.text(str, margin, yPos + margin + oneLineHeight);
              yPos += yIncr;

              // Get image width and height from the bounding rect object
              const rect = ann.position.boundingRect;
              const imageHeight = Math.abs(rect.y2 - rect.y1);
              const imageWidth = Math.abs(rect.x2 - rect.x1);
              var scaledHeight = imageHeight / ptsPerInch;
              var scaledWidth = imageWidth / ptsPerInch;
              const maxDimension = 6; // inches
              if (scaledWidth > maxDimension && scaledWidth > scaledHeight) {
                scaledWidth = maxDimension;
                scaledHeight = (scaledWidth * imageHeight) / imageWidth;
              }
              if (scaledHeight > maxDimension && scaledHeight > scaledWidth) {
                scaledHeight = maxDimension;
                scaledWidth = (scaledHeight * imageWidth) / imageHeight;
              }
              if (yPos + scaledHeight > 10) {
                pdf.addPage();
                yPos = 0.25;
              }
              pdf.addImage(
                ann["content"]["image"],
                "PNG",
                margin,
                yPos + margin + oneLineHeight,
                scaledWidth,
                scaledHeight
              );

              pdf.setLineWidth(0.01);
              pdf.setDrawColor(150);
              pdf.rect(
                margin,
                yPos + margin + oneLineHeight,
                scaledWidth,
                scaledHeight
              );
              yPos += scaledHeight;
              yPos += yIncr;
              if (yPos + scaledHeight > 10) {
                pdf.addPage();
                yPos = 0.25;
              }

              if (ann["comment"]["text"] !== "") {
                str = "My comment: " + ann["comment"]["text"] + "\n";
                var textLines = pdf.splitTextToSize(str, maxLineWidth);
                if (yPos + yIncr * (textLines.length - 2) > 10) {
                  pdf.addPage();
                  yPos = 0.25;
                } else {
                  yPos += yIncr;
                }
                if (yPos > 10) {
                  pdf.addPage();
                  yPos = 0.25;
                }
                pdf.text(textLines, margin, yPos + margin + oneLineHeight);
                yPos += yIncr * (textLines.length - 2);
              }
            }
          }
        } else {
          str = "No annotations.";
          pdf.text(str, margin, yPos + margin + oneLineHeight);
          // pdf.text(textLines, margin, yPos + margin + oneLineHeight);
          // yPos += yIncr * (textLines.length - 2);
        }
        // Done
        yPos += yIncr * 2;
      }
    } else {
      yPos += yIncr;
      if (yPos > 10) {
        pdf.addPage();
        yPos = 0.25;
      }
      pdf.text("---", margin, yPos + margin + oneLineHeight);
    }

    // Section: NOTES
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Text Notes";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Notes from the notes panel - plain text format)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(100);
    str = docData["annotations"]["userNotes"];

    if (!str || str === "") str = "No notes added.";
    else str = convert(str);
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);
    yPos += yIncr * (textLines.length - 3);

    // Section: ABSTRACT
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Excerpt: ABSTRACT";
    yPos += 0.66;
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Abstract from the document)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(100);
    str = docData.metadata.docData.excerpts["abstract"];
    if (str === "") {
      str = "No abstract found.";
      yPos += yIncr;
    }

    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    if (yPos + yIncr * (textLines.length - 2) > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);
    yPos += yIncr * (textLines.length - 2);

    // Section: CONCLUSION
    pdf.setFontSize(15);
    pdf.setTextColor(171, 71, 188);
    str = "Excerpt: CONCLUSION";
    yPos += 0.33;
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    // Section background
    pdf.setLineWidth(0.325);
    pdf.setDrawColor("#f3e5f5");
    pdf.line(
      margin - 0.1,
      yPos + margin + oneLineHeight - 0.07,
      margin + 7,
      yPos + margin + oneLineHeight - 0.07
    );
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(150);
    str = "(Conclusion from the document)";
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(str, margin, yPos + margin + oneLineHeight);

    pdf.setFontSize(12);
    pdf.setTextColor(100);
    str = docData.metadata.docData.excerpts["conclusion"];
    if (str === "") {
      str = "No conclusion found.";
    }

    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    var textLines = pdf.splitTextToSize(str, maxLineWidth);
    yPos += yIncr;
    if (yPos + yIncr * (textLines.length - 2) > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    pdf.text(textLines, margin, yPos + margin + oneLineHeight);
    yPos += yIncr * (textLines.length - 3);

    yPos += 0.33;
    yPos += yIncr;
    if (yPos > 10) {
      pdf.addPage();
      yPos = 0.25;
    }
    yPos += yIncr;
    pdf.text("--- End of report ---", margin, yPos + margin + oneLineHeight);
    // yPos += yIncr;
    // if (yPos > 10) {
    //   pdf.addPage();
    //   yPos = 0.25;
    // }
    // pdf.setTextColor(171, 71, 188);
    // pdf.text(
    //   "Thanks for using Knowledge Garden :)",
    //   margin,
    //   yPos + margin + oneLineHeight
    // );

    // Save the report
    pdf.save("KG_report_" + docData.name + ".pdf");
  };

  const generatePDF = () => {
    // const report = new jsPDF("portrait", "pt", "letter");
    // report.html(document.querySelector("#report")).then(() => {
    //   report.save("report.pdf");
    // });
    const input = document.getElementById("report");

    setProcessing(true);
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 180;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var pdf = new jsPDF("p", "mm");
      var position = 20;

      pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight + 15);
      heightLeft -= pageHeight;

      while (parseInt(heightLeft) > 0) {
        position = heightLeft - imgHeight + 10;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 15, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
      }
      setProcessing(false);
      pdf.save("KG_report_" + doc.name + "_" + new Date().getTime() + ".pdf");
    });
  };

  const getContextColor = (annotation) => {
    const globalColorSet = globalTheme.kg_ui.context_theme.colors;
    const themeId = annotation.context.themeId;
    var contextColors = null;
    if (userContexts[themeId]) {
      contextColors = {};
      userContexts[themeId].contexts.forEach((c) => {
        const color = globalColorSet.find((clr) => clr.id === c.colorId);
        if (color) {
          contextColors[c.contextId] = { ...color };
        }
      });
    }
    return contextColors;
  };

  const generationDate = `${new Date()}`;

  const BookmarkedRef = ({ data }) => {
    return (
      <div className={classes.reference_entry}>
        <BookmarkAddedOutlined />
        &nbsp;
        {data.text}
      </div>
    );
  };

  if (!doc || !doc.metadata || !doc.metadata.docData) {
    return <>Nothing to show.</>;
  }

  return (
    <div className={classes.wrapper}>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={callbackCancel}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.dialog_title}>
            <Typography style={{ fontSize: "2em" }}>
              Report:{" "}
              {doc.name.length > 60 ? doc.name.substr(0, 60) + ".." : doc.name}
            </Typography>
            <div className={classes.report_view_actions}>
              {processing ? (
                <CircularProgress size={32} />
              ) : (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    <Typography fontSize={16}>
                      Download the report as a PDF file
                    </Typography>
                  }
                  enterDelay={1000}
                  leaveDelay={200}
                  enterNextDelay={500}
                >
                  <IconButton
                    onClick={
                      () => formatPageDataForPDF(doc, contextWiseAnnotations)
                      // generatePDF()
                    }
                  >
                    <DownloadIcon style={{ fontSize: "2em" }} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton onClick={() => callbackCancel()}>
                <CloseIcon style={{ fontSize: "2em" }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div ref={contentRef} id="report" style={{ width: "60%" }}>
            <div id="kg-report-doc-name">Document: {doc.name}</div>
            <div id="kg-report-doc-datetime">
              Report generated by Knowledge Garden on: {generationDate}
            </div>
            <hr />

            <div className={classes.quick_view_content}>
              {doc.metadata.docData && doc.metadata.docData.word_freq ? (
                <div>
                  <div id="kg-report-doc-top20-terms">
                    <Typography variant="h5" color="primary">
                      Top 20 terms
                    </Typography>
                    <p>Significant terms from the document</p>
                  </div>
                  <DocTermVis
                    data={doc.metadata.docData.word_freq}
                    docName={doc.name}
                    max={maxTermFrequency}
                    showFileName={false}
                    numTermsToShow={20}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />

            <div className={classes.quick_view_content}>
              {doc.metadata.docData && doc.metadata.docData.word_freq ? (
                <div>
                  <div>
                    <Typography variant="h5" color="primary">
                      Tags
                    </Typography>
                    <p>
                      Hash tags (e.g., #information) found in the document and
                      your comments
                    </p>
                  </div>
                  <DocTags tags={doc.tags} />
                </div>
              ) : (
                ""
              )}
            </div>
            <hr />

            <div className={classes.quick_view_content}>
              <div>
                <Typography color="primary" variant="h5">
                  URLs
                </Typography>
                <p>URLs extracted from the document</p>
              </div>
              <div>
                {doc.metadata.docData.urls.map((url, i) => {
                  if (url.length > 0) {
                    const formattedUrl =
                      url.indexOf("http") === -1 ? "http://" + url : url;
                    return (
                      <div key={i}>
                        {url.indexOf("arxiv") > 0 ? (
                          <DocumentIcon className={classes.link_icons} />
                        ) : (
                          <HyperlinkIcon className={classes.link_icons} />
                        )}{" "}
                        <a href={formattedUrl} target="_blank">
                          {url}
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <hr />

            <div className={classes.quick_view_content}>
              <div>
                <Typography color="primary" variant="h5">
                  Bookmarked References
                </Typography>
                <p>Your favorite references from the document</p>
              </div>
              <div>
                {doc.metadata.docData.bibliography &&
                  doc.metadata.docData.bibliography.map((ref, i) => {
                    return ref.fav ? <BookmarkedRef data={ref} key={i} /> : "";
                  })}
              </div>
            </div>
            <hr />

            <div className={classes.quick_view_content}>
              <div>
                <Typography color="primary" variant="h5">
                  Contextual Annotations
                </Typography>
              </div>
              <div>
                {contextWiseAnnotations.map((c, ci) => (
                  <div key={ci} className={classes.report_context_section}>
                    <div
                      style={{
                        fontSize: "larger",
                        fontWeight: "600",
                        paddingBottom: "15px",
                      }}
                    >
                      {c.contextName}
                    </div>
                    <div>
                      {c.annotations.map((a, ai) => (
                        <AnnotationCard
                          key={ai}
                          annotation={a}
                          context={a.context}
                          docId={a._doc}
                          deleteHighlight={null}
                          disableActions={true}
                          themeColors={getContextColor(a)}
                          truncateText={false}
                          viewOutsideDocument={true}
                          visitHighlight={null}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />

            <div className={classes.quick_view_content_wrapper}>
              <div className={classes.quick_view_content}>
                <div className={classes.quick_view_content_header}>
                  <Typography color="primary" variant="h5">
                    Your Notes
                  </Typography>
                </div>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    padding: "10px",
                    textAlign: "justify",
                  }}
                >
                  {doc.annotations.userNotes &&
                  doc.annotations.userNotes !== "" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: doc.annotations.userNotes,
                      }}
                    />
                  ) : (
                    "No notes yet."
                  )}
                </div>
              </div>
            </div>
            <hr />

            <div className={classes.quick_view_content_wrapper}>
              <div className={classes.quick_view_content}>
                <div className={classes.quick_view_content_header}>
                  <Typography color="primary" variant="h5">
                    {" "}
                    Abstract
                  </Typography>
                </div>
                <div style={{ textAlign: "justify" }}>
                  {doc.metadata &&
                  doc.metadata.docData &&
                  doc.metadata.docData.excerpts
                    ? doc.metadata.docData.excerpts.abstract === ""
                      ? "Unable to extract abstract."
                      : doc.metadata.docData.excerpts.abstract
                    : "Abstract data not available."}
                </div>
              </div>
              <hr />

              <div className={classes.quick_view_content}>
                <div className={classes.quick_view_content_header}>
                  <Typography color="primary" variant="h5">
                    {" "}
                    Conclusion
                  </Typography>
                </div>
                <div style={{ textAlign: "justify" }}>
                  {doc.metadata &&
                  doc.metadata.docData &&
                  doc.metadata.docData.excerpts
                    ? doc.metadata.docData.excerpts.conclusion === ""
                      ? "Unable to extract conclusion."
                      : doc.metadata.docData.excerpts.conclusion
                    : "Conclusion data is not available."}
                </div>
              </div>
              <hr />

              <div>End of report</div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentReportView;
