import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  wrapper: {
    color: "#666",
    display: "flex",
    alignItems: "top",
    marginBottom: "20px",
    width: "100%",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  emphasize: {
    fontWeight: "600",
  },
}));
const SectionHeader = ({ alignment, textPart1, textPart2 }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div
        className={
          alignment === "left" ? classes.alignLeft : classes.alignRight
        }
      >
        <h4>
          <span className={classes.emphasize}>{textPart1}</span>&nbsp;
          {textPart2}
        </h4>
      </div>
    </div>
  );
};
export default SectionHeader;
