import { makeStyles } from "@material-ui/core";
import SiteContent from "../SiteContent";
import HeroSection from "./HeroSection";
import PageCTA from "./PageCTA";
import PageFooter from "./PageFooter";
import PageSubHeader from "./PageSubHeader";
import SectionView from "./SectionView";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "top",
    paddingLeft: "25px",
    paddingRight: "25px",
    width: "75%",
  },
}));
const StaticPageSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PageSubHeader subTitle={SiteContent.subTitle} />
      <HeroSection subTitle={""} />
      <PageCTA
        title={SiteContent.cta1Title}
        subTitle={SiteContent.cta1SubTitle}
      />
      {SiteContent.data.map((d, i) => (
        <SectionView key={i} body={d} />
      ))}
      <PageCTA
        title={SiteContent.cta2Title}
        subTitle={SiteContent.cta2SubTitle}
      />
      <PageFooter
        title={SiteContent.footerTitle}
        subTitle={SiteContent.footerSubTitle}
        privacyPolicyURL={SiteContent.privacyPolicyURL}
        termsAndConditionsURL={SiteContent.termsAndConditionsURL}
      />
    </div>
  );
};
export default StaticPageSection;
