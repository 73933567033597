import axios from "axios";
import REACT_APP_CONFIG from "../AppConfig";
import { sessionExpired } from "../store/SliceUsers";
import InMemoryJWTManger from "./JWTManager";
import { store } from "../store/store";

const hasSessionExpired = () => {
  const expiryTime = new Date(InMemoryJWTManger.getExpiry()).getTime();
  const now = new Date().getTime();
  if (expiryTime < now) {
    return true;
  }
  return false;
};

export const query = (url) => {

  if (hasSessionExpired()) {

    store.dispatch(sessionExpired(true));
    return;
  }
  return axios({
    method: "get",
    url: REACT_APP_CONFIG.url.API_URL + url,
    headers: {
      "Content-type": "application/json",
      mode: "cors",
      "x-access-token": InMemoryJWTManger.getToken(),
    },
  });
};

export const mutate = (url, data) => {

  if (hasSessionExpired()) {
    store.dispatch(sessionExpired(true));
    return;
  }
  return axios({
    method: "post",
    url: REACT_APP_CONFIG.url.API_URL + url,
    headers: {
      "Content-type": "application/json",
      mode: "cors",
      withCredentials: false,
      "x-access-token": InMemoryJWTManger.getToken(),
    },
    data: data,
  });
};

export async function makeUploadPost(url, data) {
  if (hasSessionExpired()) {
    store.dispatch(sessionExpired(true));
    return;
  }
  // Default options are marked with *
  const response = await fetch(REACT_APP_CONFIG.url.API_URL + url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": InMemoryJWTManger.getToken(),
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function makePost(url, data = {}) {
  // Default options are marked with *
  const response = await fetch(REACT_APP_CONFIG.url.API_URL + url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": InMemoryJWTManger.getToken(),
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function makeGetRequest(url) {
  if (hasSessionExpired()) {
    store.dispatch(sessionExpired(true));
    return;
  }
  const response = await fetch(REACT_APP_CONFIG.url.API_URL + url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": InMemoryJWTManger.getToken(),
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json();
}
