import { makeStyles, Typography } from "@material-ui/core";
import DocumentIcon from "@mui/icons-material/DocumentScanner";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxShadow: "2px 2px 10px #dfefef",
    display: "flex",
    margin: "15px",
    padding: "15px",
    width: "250px",
  },
  doc_names: {
    marginLeft: 10,
  },
}));
const DocumentCard = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <DocumentIcon />
      </div>
      <div className={classes.doc_names}>
        <Typography variant="h6">
          {data.name.length > 28
            ? data.name.substring(0, 28) + "..pdf"
            : data.name}
        </Typography>
      </div>
    </div>
  );
};

export default DocumentCard;
