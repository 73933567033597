import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserDataAPI from "../api/UserDataAPI";

const initialState = {
  value: 0,
  user_preferences: {
    app_ui: {
      dark_theme: false,
    },
  },
  ui_state: {
    tab_selected: "TAB_DASHBOARD",
  },
  app_data: {
    docs_list: [],
    docs_opened: [],
  },
  contextColors: [
    {
      id: "KG_CONTEXT_COLOR_GRAY_1",
      name: "gray 1",
      colorMain: "#c8c8c8",
      colorLighter1: "#d8d8d8",
      colorLighter2: "#e8e8e8",
    },

    {
      id: "KG_KG_CONTEXT_COLOR_PURPLE_1",
      name: "purple1",
      colorMain: "#dd3497ee",
      colorLighter1: "#8c6bb188",
      colorLighter2: "#8c6bb122",
    },
    {
      id: "KG_KG_CONTEXT_COLOR_RED_1",
      name: "red1",
      colorMain: "#fc8d62",
      colorLighter1: "#fc8d6288",
      colorLighter2: "#fc8d6222",
    },
    {
      id: "KG_KG_CONTEXT_COLOR_YELLOW_1",
      name: "yellow1",
      colorMain: "#ffd92f",
      colorLighter1: "#ffd92f88",
      colorLighter2: "#ffd92f22",
    },
    {
      id: "KG_KG_CONTEXT_COLOR_GREEN_1",
      name: "green1",
      colorMain: "#66c2a5",
      colorLighter1: "#66c2a588",
      colorLighter2: "#66c2a522",
    },
    {
      id: "KG_KG_CONTEXT_COLOR_BLUE_1",
      name: "blue1",
      colorMain: "#8da0cb",
      colorLighter1: "#8da0cb88",
      colorLighter2: "#8da0cb22",
    },
    {
      id: "KG_KG_CONTEXT_COLOR_GRAY_2",
      name: "gray 2",
      colorMain: "#666",
      colorLighter1: "#66666688",
      colorLighter2: "#66666622",
    },
    {
      id: "KG_CONTEXT_COLOR_PURPLE_2",
      name: "purple 2",
      colorMain: "#ae017e",
      colorLighter1: "#ae017e88",
      colorLighter2: "#ae017e22",
    },
    {
      id: "KG_CONTEXT_COLOR_RED_2",
      name: "red 2",
      colorMain: "#e31a1c",
      colorLighter1: "#e31a1c88",
      colorLighter2: "#e31a1c22",
    },
    {
      id: "KG_CONTEXT_COLOR_YELLOW_2",
      name: "yellow 2",
      colorMain: "#bf812d",
      colorLighter1: "#bf812d88",
      colorLighter2: "#bf812d22",
    },
    {
      id: "KG_CONTEXT_COLOR_GREEN_2",
      name: "green 2",
      colorMain: "#238443",
      colorLighter1: "#23844388",
      colorLighter2: "#23844322",
    },
    {
      id: "KG_CONTEXT_COLOR_BLUE_2",
      name: "blue 2",
      colorMain: "#2171b5",
      colorLighter1: "#2171b588",
      colorLighter2: "#2171b522",
    },
  ],
  notification: {
    show: false,
    message: "",
    type: "KG_EVENT_SUCCESS",
    updated: "",
  },
};

export const globalStateSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.user_preferences.app_ui.dark_theme =
        !state.user_preferences.app_ui.dark_theme;
    },
    toggleContextMenuTrigger: (state) => {
      state.settings.docOptions.rightClickContextMenu =
        !state.settings.docOptions.rightClickContextMenu;
    },
    setSelectedTab: (state, action) => {
      state.ui_state.tab_selected = action.payload;
    },
    setNotification: (state, action) => {
      state.notification.show = true;
      state.notification.type = action.payload.type || "KG_EVENT_SUCCESS";
      state.notification.message = action.payload.message || "Hello world";
      state.notification.updated = new Date();
    },
  },
  extraReducers: {},
});

export const {
  setSelectedTab,
  setNotification,
  toggleContextMenuTrigger,
  toggleDarkMode,
} = globalStateSlice.actions;
export default globalStateSlice.reducer;
