import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import AddFolderIcon from "@mui/icons-material/CreateNewFolder";
import Delete from "@mui/icons-material/Delete";
import AddFilesIcon from "@mui/icons-material/NoteAdd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InMemoryJWT from "../../api/JWTManager";
import appEvents from "../../libs/utils/appEvents";
import { setNotification } from "../../store/GlobalStateSlice";
import { closeDoc, getAllDocs } from "../../store/SliceDocuments";
import {
  addDocsToAFolder,
  addDocsToNewDefaultFolder,
  createFolderAndUploadFiles,
  getAllFolders,
  removeFoldersByIds,
  resetSelectedFolders,
  setFileOrFolderOperationsActive,
  setFileOrFolderOperationsInactive,
} from "../../store/SliceFolders";
import AlertDialog from "../UIWidgets/AlertDialog";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    // padding: 10,
  },
  svg_icons: {
    "& svg": {
      // fill: "#aaa",
      fill: theme.palette.primary.main,
      fontSize: 30,
    },
    "& svg:hover": {
      fill: theme.palette.primary.main,
    },
  },
  svg_icon_disabled: {
    color: theme.palette.greyscale.light,
    fontSize: 30,
  },
  delete_icon_enabled: {
    fill: "salmon",
  },
  delete_icon_disabled: {
    fill: "green",
  },
  status: {
    backgroundColor: "#fff",
    border: "1px solid #eeeeee",
    borderRadius: "4px",
    padding: "3px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const DocumentListMenu = ({ docs, folders }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalTheme = useTheme();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showFileLimitDialog, setShowFileLimitDialog] = useState(false);
  const foldersSelected = useSelector((state) => state.folders.selectedFolders);
  const uploadTaskActive = useSelector(
    (state) => state.folders.isFileOrFolderOperationActive
  );
  const inputFilesRef = useRef();
  const inputFolderRef = useRef();
  const isFileOrFolderOperationActive = useSelector(
    (state) => state.folders.isFileOrFolderOperationActive
  );

  const openedDocs = useSelector((state) => state.docs.docsOpened);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!uploadTaskActive) setStatus("");
  }, [uploadTaskActive]);

  const handleAddFiles = () => {
    if (
      (InMemoryJWT.getUserIsGuest() &&
        docs.length >= globalTheme.kg_ui.user_settings.file_limit.trial) ||
      (!InMemoryJWT.getUserIsGuest() &&
        docs.length >= globalTheme.kg_ui.user_settings.file_limit.registered)
    ) {
      setShowFileLimitDialog(true);
      return;
    }

    inputFilesRef.current.click();
  };

  const handleAddFolder = () => {
    if (
      (InMemoryJWT.getUserIsGuest() &&
        docs.length >= globalTheme.kg_ui.user_settings.file_limit.trial) ||
      (!InMemoryJWT.getUserIsGuest() &&
        docs.length >= globalTheme.kg_ui.user_settings.file_limit.registered)
    ) {
      setShowFileLimitDialog(true);
      return;
    }
    inputFolderRef.current.click();
  };

  const selectFolder = (event) => {
    var files = event.target.files;
    if (files.length === 0) {
      // appEvents.debugLog(
      //   "DocumentListMenu",
      //   "No files found when selecting folder."
      // );
      return;
    }
    var relativePath = files[0].webkitRelativePath;
    var folder = relativePath.split("/");

    return folder[0];
  };

  const handleFolderUpload = (event) => {
    const files = event.target.files;
    const folderName = selectFolder(event);

    if (
      (InMemoryJWT.getUserIsGuest() &&
        docs.length + files.length >=
          globalTheme.kg_ui.user_settings.file_limit.trial) ||
      (!InMemoryJWT.getUserIsGuest() &&
        docs.length + files.length >=
          globalTheme.kg_ui.user_settings.file_limit.registered)
    ) {
      setShowFileLimitDialog(true);
      return;
    }

    var filesFound = [];
    for (var i = 0; i < files.length; i++) {
      if (files[i].webkitRelativePath.match(/\//g).length === 1) {
        // The file is not a subfolder and file type is "application/pdf"
        if (files[i].type && files[i].type === "application/pdf") {
          filesFound.push(files[i]);
        }
      }
    }
    if (filesFound.length !== files.length) {
      dispatch(
        setNotification({
          type: "KG_EVENT_WARNING",
          message: "Only PDF documents will be added.",
        })
      );
    }
    if (filesFound.length === 0) {
      dispatch(
        setNotification({
          type: "KG_EVENT_ERROR",
          message: "Folder was not added because it is empty.",
        })
      );
      return;
    }
    const folderData = {
      folder: {
        name: folderName,
      },
    };
    setStatus(
      `Uploading folder (${filesFound.length} ${
        filesFound.length > 1 ? "docs" : "doc"
      }) ...`
    );
    dispatch(setFileOrFolderOperationsActive());
    dispatch(
      createFolderAndUploadFiles({
        folder: folderData,
        files: filesFound,
      })
    );
  };

  const uploadFiles = (filesData) => {
    // Check if a folder with name "Unnamed Folder" exists
    const foundDefaultFolder = folders.find((f) => f.name === "Unnamed Folder");
    if (!foundDefaultFolder) {
      // appEvents.debugLog("DocumentListMenu", "Default folder not found.");
      dispatch(addDocsToNewDefaultFolder(filesData));
    } else {
      dispatch(
        addDocsToAFolder({
          folder: foundDefaultFolder,
          files: filesData,
          newFolder: false,
        })
      );
    }
  };

  const handleFilesUpload = (event) => {
    event.preventDefault();
    const filesFound = event.target.files;
    if (!filesFound)
      throw new Error(
        "[Loading files] file selection returned empty list of files"
      );
    try {
      setStatus(
        `Uploading files (${filesFound.length} ${
          filesFound.length > 1 ? "docs" : "doc"
        }) ... `
      );
      dispatch(setFileOrFolderOperationsActive());
      uploadFiles(filesFound);
    } catch (error) {
      appEvents.handleError(error);
    }
  };

  const handleDeleteFolders = (e) => {
    if (foldersSelected.length > 0) setShowDeleteDialog(true);
  };
  const callbackCancel = () => {
    setShowDeleteDialog(false);
    setShowFileLimitDialog(false);
  };
  const callbackProceed = () => {
    setShowDeleteDialog(false);
    deleteFolders();
  };

  const deleteFolders = () => {
    dispatch(setFileOrFolderOperationsActive());
    setStatus("Deleting folder... ");

    // Find if any open documents in the folder to close
    const docsToClose = [];
    foldersSelected.forEach((fId) => {
      const docs = openedDocs.filter((d) => d.folderId === fId);
      docs.forEach((d) => docsToClose.push(d));
    });

    // Close any open documents found
    docsToClose.forEach((d) => {
      dispatch(closeDoc({ docId: d.fileId }));
    });

    dispatch(removeFoldersByIds(foldersSelected)).then((data1) => {
      dispatch(getAllFolders()).then((data) => {
        dispatch(getAllDocs()).then(() => {
          // dispatch(getAllFavoriteAnnotations())
          // dispatch(getAllTodoAnnotations())
          dispatch(setFileOrFolderOperationsInactive());
          dispatch(resetSelectedFolders());
          setStatus("");
          dispatch(
            setNotification({
              type: "KG_EVENT_SUCCESS",
              message:
                foldersSelected.length > 1
                  ? `${foldersSelected.length} Folders deleted`
                  : "1 Folder deleted",
            })
          );
        });
      });
    });

    // DO NOT DELETE >>>>
    //// Delete All
    // dispatch(removeAllFolders()).then(() => {
    //   dispatch(getAllFolders()).then((data) => {
    //     dispatch(getAllDocs()).then(() => {
    //       dispatch(setFileOrFolderOperationsInactive());
    //       setStatus("");
    //     });
    //   });
    // });
    // <<< DO NOT DELETE
  };

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <form
            action=""
            method="post"
            name="folder_upload"
            encType="multipart/form-data"
            style={{ display: "none" }}
          >
            <input
              type="file"
              accept=".pdf, application/pdf"
              ref={inputFolderRef}
              style={{ display: "none" }}
              multiple
              onChange={handleFolderUpload}
              directory=""
              webkitdirectory=""
            />
          </form>
          <form>
            <input
              type="file"
              accept=".pdf, application/pdf"
              ref={inputFilesRef}
              style={{ display: "none" }}
              multiple
              onChange={handleFilesUpload}
            />
          </form>
          <span>
            {InMemoryJWT.getUserIsGuest() ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>
                    Import folders with documents - sign up required
                  </Typography>
                }
                enterDelay={1000}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <IconButton
                  aria-label="add folder"
                  className={classes.svg_icon_disabled}
                  // onClick={handleAddFolder}
                >
                  <AddFolderIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                placement="top"
                title={
                  <Typography fontSize={16}>
                    Add a folder (only the PDF files in the folder will be
                    uploaded)
                  </Typography>
                }
                enterDelay={1000}
                leaveDelay={200}
                enterNextDelay={500}
              >
                <IconButton
                  aria-label="add folder"
                  className={classes.svg_icons}
                  onClick={handleAddFolder}
                >
                  <AddFolderIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </span>
          <Tooltip
            arrow
            placement="top"
            title={
              <Typography fontSize={16}>
                Upload documents (PDF format) to a new folder. Click folder
                name to rename.
              </Typography>
            }
            enterDelay={1000}
            leaveDelay={200}
            enterNextDelay={500}
          >
            <IconButton
              aria-label="add files"
              className={classes.svg_icons}
              onClick={handleAddFiles}
            >
              <AddFilesIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
        <span disabled={foldersSelected.length > 0 ? false : true}>
          <Tooltip
            arrow
            placement="top"
            title={
              <Typography fontSize={16}>Delete selected folders</Typography>
            }
            enterDelay={1000}
            leaveDelay={200}
            enterNextDelay={500}
          >
            <IconButton
              aria-label="delete"
              className={classes.svg_icons}
              onClick={handleDeleteFolders}
              //
            >
              <Delete
                fontSize="2em"
                style={{
                  fill: foldersSelected.length > 0 ? "salmon" : "lightgray",
                }}
              />
            </IconButton>
          </Tooltip>
        </span>
        {isFileOrFolderOperationActive ? (
          <div className={classes.status}>
            <span style={{ fontSize: "smaller" }}>
              {status === "" ? "Uploading files.. " : status}
            </span>
            &nbsp;
            <CircularProgress size={25} />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <div>
        {isFileOrFolderOperationActive ? (
          <div>
            <span style={{ fontSize: "smaller" }}>
              {status === "" ? "Uploading files.. " : status}
            </span>
            &nbsp;
            <CircularProgress size={25} />
          </div>
        ) : (
          ""
        )}
      </div> */}
      <AlertDialog
        callbackCancel={callbackCancel}
        callbackProceed={callbackProceed}
        contentText={
          "Documents and all your notes in the selected folders will be deleted permanently. Proceed with caution!"
        }
        openDialog={showDeleteDialog}
        proceedButtonText={"Delete Permanently"}
        title={"Delete Selected Folders"}
      />
      <AlertDialog
        callbackCancel={callbackCancel}
        contentText={
          "This is a trial version of Knowledge Garden and the number of files that can be uploaded is limited." +
          " Please sign up or write to knowledge.garden.app@gmail.com"
        }
        openDialog={showFileLimitDialog}
        cancelButtonText={"Close"}
        title={"File Limit Reached!"}
      />
    </div>
  );
};

export default DocumentListMenu;
