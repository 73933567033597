import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import TermsOfService from "./TermsOfService";

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#e9e9e9",
    display: "flex",
    flexDirection: "column",
    fontSize: "small",
    justifyContent: "space-around",
    alignItems: "top",
    textAlign: "center",
    marginBottom: "25px",
    width: "100%",
  },
  legalese: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  subTitle: {
    color: "#666",
    display: "flex",
    fontSize: "1.25em",
    justifyContent: "space-around",
    textAlign: "center",
    marginLeft: "300px",
    marginRight: "300px",
    width: "75%",
  },
  subTitleWrapper: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
    width: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
    width: "100%",
  },
}));

const PageFooter = ({ privacyPolicyURL, subTitle, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handlePP = () => {
    navigate("/privacy");
  };
  const handleToS = () => {
    navigate("/tos");
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div className={classes.legalese}>
        <div>
          <a href={""} onClick={handleToS}>
            Terms of Service
          </a>
        </div>
        <div>&nbsp;|&nbsp;</div>
        <div>
          <a href={""} onClick={handlePP}>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
